<template>
  <div>
    <div :class="loading ? 'message-box-wrap disabled' : 'message-box-wrap'">
      <!-- <section class="message-box-wrap disabled"> -->
      <div class="text-wrap-features d-flex" v-if="editor">
        <div class="text-format">
          <ul>
            <!-- <li>
                <div class="dropdown paragraph-dropdown">
                  <button
                    class="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Paragraph
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a
                        class="dropdown-item h1"
                        href="javascript:void(0)"
                        @click="
                          editor.chain().focus().toggleHeading({ level: 1 }).run()
                        "
                        :class="{
                          'is-active': editor.isActive('heading', { level: 1 }),
                        }"
                        >Heading 1</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item h2"
                        href="javascript:void(0)"
                        @click="
                          editor.chain().focus().toggleHeading({ level: 2 }).run()
                        "
                        :class="{
                          'is-active': editor.isActive('heading', { level: 2 }),
                        }"
                        >Heading 2</a
                      >
                    </li>
                  </ul>
                </div>
              </li> -->

            <li>
              <div class="shortcut-key">
                <a href="javascript:void(0)" @click="editor.chain().focus().toggleBold().run()"
                  :disabled="editor.isActive('bold')" :class="{ 'is-active': editor.isActive('bold') }">B</a>
                <div class="shortcut-tooltip">
                  <p>Ctrl + B</p>
                </div>
              </div>
            </li>
            <li>
              <div class="shortcut-key">
                <a href="javascript:void(0)" @click="editor.chain().focus().toggleItalic().run()"
                  :class="{ 'is-active': editor.isActive('italic') }"><em>I</em></a>
                <div class="shortcut-tooltip">
                  <p>Ctrl + I</p>
                </div>
              </div>
            </li>
            <li>
              <a href="javascript:void(0)" @click="editor.chain().focus().toggleStrike().run()"
                :class="{ 'is-active': editor.isActive('strike') }"><strike>S</strike></a>
            </li>
            <li>
              <a href="javascript:void(0)" class="underlined" @click="editor.chain().focus().toggleUnderline().run()"
                :class="{ 'is-active': editor.isActive('underline') }">Aa</a>
            </li>
            <!-- <li>
              <a href="javascript:void(0)" class="underlined" @click="
                editor
                  .chain()
                  .focus()
                  .toggleHighlight({ color: '#fde13b' })
                  .run()
                                " :class="{
                    'is-active': editor.isActive('highlight', {
                      color: '#fde13b',
                    }),
                  }"><i class="fa fa-solid fa-highlighter"></i></a>

              
            </li> -->
            <li><a href="javascript:void(0)" class="text-color mr-1"> <i class="fa fa-solid fa-highlighter"></i> <span
                  class="below-line" v-bind:style="{ backgroundColor: editor.getAttributes('highlight').color }"></span>
                <input type="color"
                  @input="editor.chain().focus().toggleHighlight({ color: $event.target.value }).run()"
                  :value="editor.getAttributes('highlight').color">
              </a>

            </li>
            <li><a href="javascript:void(0)" class="text-color"> A <span class="below-line"
                  v-bind:style="{ backgroundColor: editor.getAttributes('textStyle').color }"></span> <input
                  type="color" @input="editor.chain().focus().setColor($event.target.value).run()"
                  :value="editor.getAttributes('textStyle').color">
              </a>

            </li>
            <li v-if="usedEditor == 'tutorial'"><button @click="uploadImge">
                <i class="fa fa-image" aria-hidden="true"></i>
              </button></li>
          </ul>
        </div>
        <div class="text-format">
          <ul>
            <li>
              <a href="javascript:void(0)" @click="
                editor.chain().focus().toggleHeading({ level: 1 }).run()
                " :class="{
                'is-active': editor.isActive('heading', { level: 1 }),
              }">h1</a>
            </li>
            <li>
              <a href="javascript:void(0)" @click="
                editor.chain().focus().toggleHeading({ level: 2 }).run()
                " :class="{
                'is-active': editor.isActive('heading', { level: 2 }),
              }">h2</a>
            </li>
          </ul>
        </div>
        <div class="text-format">
          <ul>
            <li>
              <a href="javascript:void(0)" @click="editor.chain().focus().toggleOrderedList().run()"
                :class="{ 'is-active': editor.isActive('orderedList') }"><i class="fa fa-solid fa-list-ol"></i></a>
            </li>
            <li>
              <a href="javascript:void(0)" @click="editor.chain().focus().toggleBulletList().run()"
                :class="{ 'is-active': editor.isActive('bulletList') }"><i class="fa fa-solid fa-list-ul"></i></a>
            </li>
          </ul>
        </div>
        <div class="text-format">
          <ul>
            <li>
              <a href="javascript:void(0)" @click="setLink" :class="{ 'is-active': editor.isActive('link') }"><i
                  class="fa fa-solid fa-link"></i></a>
            </li>
            <!-- <li>
                <a href="javascript:void(0)"><i class="fa-solid fa-code"></i></a>
              </li> -->
          </ul>
        </div>
        <div class="text-format">
          <ul>
            <li>
              <a href="javascript:void(0)" @click="editor.chain().focus().undo().run()"
                :disabled="!editor.can().undo()"><i class="fas fa-undo-alt"></i></a>
            </li>
            <li>
              <a href="javascript:void(0)" @click="editor.chain().focus().redo().run()"
                :disabled="!editor.can().redo()"><i class="fas fa-redo-alt"></i></a>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="height" class="enter-message-wrap fixed-height-area">
        <editor-content :editor="editor" @keydown.enter="onEnter" />
      </div>
      <div v-else class="enter-message-wrap">
        <editor-content :editor="editor" @keydown.enter="onEnter" />
      </div>

      <div class="additional-features-wrap inline-items">
        <ul>
          <!-- <li>
              <div class="send-schedule-arrow" v-if="showEnterSetting">
                <a
                  title="Input Options"
                  href="javascript:void(0)"
                  @click="toogleEnterFeature"
                  ><i class="fas fa-cog"></i
                ></a>
                <div class="arrow-tooltip" v-if="toogleEnterKey">
                  <p>
                    When writing a message, press
                    <span class="enter-btn">
                      Enter <i class="fas fa-level-down-alt fa-rotate-90"></i
                    ></span>
                    to ...
                  </p>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      v-model="enterSetting"
                      value="message"
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Send the message
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      v-model="enterSetting"
                      value="line"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Start a new line (use
                      <span class="enter-btn"> Ctrl </span> +
                      <span class="enter-btn">
                        Enter <i class="fas fa-level-down-alt fa-rotate-90"></i
                      ></span>
                      to send)
                    </label>
                  </div>
                </div>
              </div>
            </li> -->
          <li>
            <div class="send-schedule-container">
              <div class="send-schedule-arrow" v-if="showEnterSetting">
                <a title="Input Options" href="javascript:void(0)" @click="toogleEnterFeature"><i
                    class="fas fa-cog"></i></a>
              </div>
              <EditorEnterSetting v-if="toogleEnterKey" :toogleEnterKey="toogleEnterKey"
                @editorEnterSetting="editorEnterSetting"></EditorEnterSetting>
              <!-- <div :class="
          toogleEnterKey ? 'arrow-tooltip card' : 'arrow-tooltip card disappear-block'">
                  <p>
                    When writing a message, press
                    <span class="enter-btn">
                      Enter <i class="fas fa-level-down-alt fa-rotate-90"></i
                    ></span>
                    to ...
                  </p>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      v-model="enterSetting"
                      value="message"
                      @change="onChange($event)"
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Send the message
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      v-model="enterSetting"
                      value="line"
                      @change="onChange($event)"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Start a new line (use
                      <span class="enter-btn"> Ctrl </span> +
                      <span class="enter-btn">
                        Enter <i class="fas fa-level-down-alt fa-rotate-90"></i
                      ></span>
                      to send)
                    </label>
                  </div>
                </div> -->
            </div>
          </li>
          <!-- <li>
                <div class="small-texts-side" @click="toogleEnterFeature">
            <p>
              <a href="javascript:void(0)" v-if="!shiftEnter"
                ><strong>Return</strong>&nbsp;to add a new line</a
              >
              <a href="javascript:void(0)" v-else
                ><strong>Shift</strong> +
                <i class="fas fa-level-down-alt fa-rotate-90 mx-1"></i> to add a
                new line</a
              >
            </p>
          </div></li> -->
        </ul>
        <!-- <ul>
             <li>
              <a href="javascript:void(0)" class="plus-wrap"
                ><i class="fa-solid fa-plus"></i
              ></a>
            </li> -->
        <!-- <li>
              <a href="javascript:void(0)"><i class="fa-solid fa-video"></i></a>
            </li> -->
        <!-- <li>
              <a href="javascript:void(0)"
                ><i class="fa-solid fa-microphone"></i
              ></a>
            </li> -->

        <!-- <li><a href="javascript:void(0)" title="Mention someone">@</a></li> -->
        <!-- <li>
              <a href="javascript:void(0)"
                ><i class="fa-solid fa-face-smile"></i
              ></a>
            </li>
          </ul> -->
        <!--  <div class="send-return-wrap">
           <ul>
              <li><a href="javascript:void(0)"><i class="fa-solid fa-paper-plane"></i></a></li>
              <li><a href="javascript:void(0)" class="send-schedule-arrow"><i class="fa-solid fa-angle-down"></i></a></li>
            </ul>

            <div class="arrow-tooltip">
              <ul>
                <li>Send tomorrow at 9:000pm</li>
                <li>Send tomorrow at 5:000pm</li>
              </ul>
            </div>
            <div class="send-wrap">
              <a href="javascript:void(0)" @click="sendChatCommunication"
                ><i class="fa fa-solid fa-paper-plane"></i
              ></a>
            </div> -->
        <!-- <div class="send-schedule-arrow">
              <a href="javascript:void(0)" @click="toogleEnterFeature"
                ><i class="fa fa-solid fa-angle-down"></i
              ></a>
              <div class="arrow-tooltip" v-if="toogleEnterKey">
                <p>
                  When writing a message, press
                  <span class="enter-btn">
                    Enter <i class="fas fa-level-down-alt fa-rotate-90"></i
                  ></span>
                  to ...
                </p>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    v-model="enterSetting"
                    value="message"
                  />
                  <label class="form-check-label" for="flexRadioDefault1">
                    Send the message
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    v-model="enterSetting"
                    value="line"
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    Start a new line (use
                    <span class="enter-btn">
                      Enter <i class="fas fa-level-down-alt fa-rotate-90"></i
                    ></span>
                    to send)
                  </label>
                </div>
              </div>
            </div>  -->
        <!-- </div> -->
      </div>
      <!-- <div class="loading-overlay" v-if="loading">
        <img :src="imageBaseUrl
            ? `${imageBaseUrl}img/loading.gif`
            : 'img/loading.gif'
          " alt="" />
      </div> -->
    </div>
    <!-- Upload Image -->
    <div class="modal-wrap default-modal">
      <div class="modal fade" data-backdrop="static" data-keyboard="false" id="uploadImage" :tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <div class="d-block">
                <h5 class="modal-title" id="exampleModalLabel">
                  Upload Image
                </h5>
                <p class="text-muted">
                  Upload Image
                </p>
              </div>

              <button type="button" class="close" @click="uploadImgeClose" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p class="text-danger mb-3" v-if="checkImageUpload"><i class="fa fa-info-circle mr-2"></i>{{
                checkImageUpload }}</p>
              <div class="form-wrap pt-0">


                <div class="upload-btn-wrapper">
                  <button for="formFileSm" class="upload-btn btn default-btn"><i class="fa fa-arrow-up"></i>
                    Choose Image</button>
                  <input type="file" accept="image/*" id="profileUpdate" @change="onDocumentChange($event)" />
                </div>
                <div class="shadowed-card bg-white mb-2 mt-2" v-if="imagesToUpload.base64">
                  <div class="imaged-col">
                    <div class="small-img-wrap">
                      <img :src="imagesToUpload.base64" alt="" />
                    </div>
                    <a href="javascript:void(0)" title="click to rename">
                      <h5>{{ imagesToUpload.fileName }}</h5>
                    </a>

                    <p class="size">{{ imagesToUpload.size }}</p>
                  </div>

                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn default-btn" @click="uploadImage">
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Heading from "@tiptap/extension-heading";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Highlight from "@tiptap/extension-highlight";
import Underline from "@tiptap/extension-underline";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import Link from "@tiptap/extension-link";
import HardBreak from "@tiptap/extension-hard-break";

import Mention from "@tiptap/extension-mention";
import suggestion from "@/components/mention/suggestion.js";
import History from "@tiptap/extension-history";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";
import EditorEnterSetting from "@/components/mention/EditorEnterSetting.vue";
import { config } from "@/Config";
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import Image from '@tiptap/extension-image'
import Dropcursor from '@tiptap/extension-dropcursor'
import axios from 'axios'

export default {
  components: {
    EditorContent,
    EditorEnterSetting,
  },

  props: {
    usedEditor: {
      type: String,
      default: "",
    },
    height: {
      type: Boolean,
      required: false,
    },
    showEnterSetting: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      imageBaseUrl: "",
      enterSetting: "line",
      toogleEnterKey: false,
      enableEnterKey: false,
      shiftEnter: false,
      // eslint-disable-next-line no-undef
      editor: null,
      imagesToUpload: {},
      checkImageUpload: "",
      uplodaUrl: "",
    };
  },
  methods: {
    uploadImge() {
      this.imagesToUpload = {};
      $("#uploadImage").modal("show");
    },
    uploadImgeClose() {
      $("#profileUpdate").val('');
      $("#uploadImage").modal("hide");
      this.imagesToUpload = {};
    },

    readUploadImage(file) {
      const data = {};
      const reader = new FileReader();
      reader.onload = (e) => {
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        data.base64 = e.target.result;
        data.duplicateFile = false; // data.fileName = file.name;
        data.fileName = file.name.replaceAll(`.${extension}`, "");
        data.extension = extensionLower;
        data.isuploded = false;
        data.tag = [];
        data.size = common.formatBytes(parseInt(file.size));
        console.log("UPLOAD READY FILE >>>", data);
        this.imagesToUpload = data;

      };
      reader.readAsDataURL(file);
    },

    onDocumentChange(event) {
      this.checkImageUpload = "";
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        this.readUploadImage(file);
      }
    },

    async uploadImage() {
      if (!this.imagesToUpload.fileName) {
        this.checkImageUpload = "image required"
        return false
      }
      const payLoad = {
        filename: this.imagesToUpload.fileName,
        size: this.imagesToUpload.size,
        extension: this.imagesToUpload.extension,
        file: this.imagesToUpload.base64,
      };
      try {
        const response = await axios.post(this.uplodaUrl, payLoad, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('current_user_token')}`
          }
        })
        this.uploadImgeClose();
        this.editor.chain().focus().setImage({ src: response.data.data.file }).run()
      } catch (error) {
        console.log(error)
      }
    },
    onEnter(event) {
      if (
        JSON.parse(localStorage.getItem("current_user_info")).enter_key === 1
      ) {

        // if (localStorage.getItem("enterKeySetting") === "true") {
        console.log("Message", JSON.parse(localStorage.getItem("current_user_info")).enter_key);
        this.enterSetting = "message";
      } else {
        console.log("Line", JSON.parse(localStorage.getItem("current_user_info")).enter_key);
        this.enterSetting = "line";
      }
      console.log("KEY CHECK  ALL>>>", JSON.parse(localStorage.getItem("current_user_info")))
      console.log("KEY CHECK >>>", JSON.parse(localStorage.getItem("current_user_info")).enter_key)
      // console.log("Editor ON");
      // if (event.key === "Enter") {
      if (event.shiftKey) return;
      if (this.enterSetting === "message") {
        if (event.ctrlKey) return;
        // alert("ENTERED without SHIFT")
        this.$emit("enterKeyPressed", this.shiftEnter);
      } else {
        if (!event.ctrlKey) return;
        // alert("CTRL + ENTER")
        this.$emit("enterKeyPressed", this.shiftEnter);
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    toogleEnterSettingModal() {
      this.toogleEnterKey = false;
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    editorEnterSetting(value) {
      this.$emit("enterSetting", value);
      console.log("CHECK >>>> AAAA", value)
      this.toogleEnterKey = !this.toogleEnterKey;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    toogleEnterFeature() {
      // console.log("CHECK VALUE >>> ", JSON.parse(localStorage.getItem("current_user_info")).enter_key)
      // alert("check")
      this.toogleEnterKey = !this.toogleEnterKey;
      if (
        JSON.parse(localStorage.getItem("current_user_info")).enter_key === 1
      ) {
        // if (localStorage.getItem("enterKeySetting") === "true") {
        console.log("Message", JSON.parse(localStorage.getItem("current_user_info")).enter_key);
        this.enterSetting = "message";
      } else {
        console.log("Line", JSON.parse(localStorage.getItem("current_user_info")).enter_key);
        this.enterSetting = "line";
      }
      if (this.toogleEnterKey) {
        setTimeout(this.toogleEnterSettingModal, 3000);
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);
      // console.log("check the Link >>> ", common.getValidUrl(url))

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: common.getValidUrl(url) })
        .run();
    },
    removeImageTags(value) {
      return value.replace(/<img[^>]*>/gi, '');
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    enterKey: function () {
      if (
        JSON.parse(localStorage.getItem("current_user_info")).enter_key === 1
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.enterSetting = "message";
        return this.enterSetting;
        // return JSON.parse(localStorage.getItem("current_user_info")).enter_key;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.enterSetting = "line";
        return this.enterSetting;
      }
    },
  },

  watch: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    // editor(value) {
    //   console.log("CHECK THE VALUE >>> ", value);
    // },
    modelValue(value) {
      console.log("Check the value >>> ", value);
      // HTML
      // value = this.removeImageTags(value);
      console.log("Check the After >>> ", value);
      const isSame = this.editor.getHTML() === value;
      // console.log("CHECK THE VALUE >>> ", isSame);
      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
    toogleEnterKey(value) {
      // if (value) {
      //   setTimeout(this.toogleEnterKey = false, 60000);
      // }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    enterSetting(value) {
      // console.log("CHECK THE VALUE >>> ", value);
      if (value === "message") {
        this.shiftEnter = !this.shiftEnter;
        this.$emit("enterSetting", true);
      } else {
        this.shiftEnter = !this.shiftEnter;
        this.$emit("enterSetting", false);
      }
    },
    // enableEnterKey(value) {
    //   // console.log("CHECK THE VALUE >>> ", value);
    //   if (value === "message") {
    //     this.shiftEnter = !this.shiftEnter;
    //   } else {
    //     this.shiftEnter = !this.shiftEnter;
    //   }
    // },
    // shiftEnter(value) {
    //   console.log("CHECK THE VALUE >>> ", value)
    //   if(value){
    //     this.enterSetting = 'line';
    //   } else {
    //     this.enterSetting = 'message';
    //   }
    // },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  // created() {
  //   if(localStorage.getItem("enterKeySetting") === true){
  //     // alert("IN")
  //     this.enterSetting = 'message';
  //   }
  // },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted() {
    this.imageBaseUrl = config.imageBaseUrl;
    this.uplodaUrl = `${config.apiBaseUrl}editor/upload`;
    if (JSON.parse(localStorage.getItem("current_user_info")).enter_key === 1) {
      this.$emit("enterSetting", true);
    } else {
      // console.log("Line");
      this.$emit("enterSetting", false);
    }
    this.toogleEnterFeature();
    this.toogleEnterKey = false;
    console.log("Suggestion: ", suggestion);
    // suggestion.updateList();
    this.editor = new Editor({
      extensions: [
        Color,
        StarterKit.configure,
        Placeholder.configure({
          placeholder: "Write something …",
        }),
        Document,
        Paragraph,
        Text,
        TextStyle,
        Image,
        Dropcursor,
        Heading.configure({
          levels: [1, 2],
        }),
        Bold.configure({
          HTMLAttributes: {
            class: "my-custom-class",
          },
        }),
        Italic.configure({
          HTMLAttributes: {
            class: "my-custom-class",
          },
        }),
        Strike.configure({
          HTMLAttributes: {
            class: "my-custom-class",
          },
        }),
        Underline.configure({
          HTMLAttributes: {
            class: "my-custom-class",
          },
        }),
        Highlight.configure({ multicolor: true }),
        BulletList.configure({
          HTMLAttributes: {
            class: "editor-bullet-list",
          },
        }),
        OrderedList.configure({
          HTMLAttributes: {
            class: "editor-ordered-list",
          },
        }),
        ListItem,
        Link.configure({
          HTMLAttributes: {
            class: "my-custom-class",
          },
          autolink: true,
          openOnClick: true,
          linkOnPaste: true,
        }),
        HardBreak.configure({
          keepMarks: false,
        }),
        Mention.configure({
          HTMLAttributes: {
            class: "mention",
          },
          suggestion,
        }),
        History.configure({
          depth: 10,
          newGroupDelay: 1000,
        }),
        Image.configure({
          inline: true,
        }),
        Image.configure({
          allowBase64: true,
        }),
        Image.configure({
          HTMLAttributes: {
            class: 'my-custom-class',
          },
        }),
      ],
      content: this.modelValue,
      autofocus: true,
      editable: true,
      injectCSS: false,
      onBlur: () => {
        this.editor.getHTML()
      },
      onUpdate: () => {
        this.$emit("update:modelValue", this.editor.getHTML());
      },
    });
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>
<style scoped></style>
