
import {
  defineComponent,
  reactive,
  onMounted,
  onUnmounted,
  ref,
  computed,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";

import { config } from "@/Config";

import { common } from "@/common/services/Common";
import { indexedDBService, Message } from "@/common/services/indexedDBService";
import { ethitransService } from "@/services/EthitransService";
import { validationService } from "@/common/ValidationService";

// import CommunicationCard from "@/components/cards/CommunicationCard.vue";
import MemberCard from "@/components/cards/MemberCard.vue";
import ErrorList from "@/components/commonDesign/ErrorList.vue";


import Multiselect from 'vue-multiselect';
import DigitalSignature from "@/components/formComponents/DigitalSignature.vue";
import TitleComponent from "@/components/formComponents/TitleComponent.vue";
import TextBoxComponent from "@/components/formComponents/TextBoxComponent.vue";
// import TextAreaComponent from "@/components/formComponents/TextAreaComponent.vue";
import RadioButtonComponent from "@/components/formComponents/RadioButtonComponent.vue";
import SelectOption from "@/components/formComponents/SelectOption.vue";
import CheckBox from "@/components/formComponents/CheckBox.vue";
import TextArea from "@/components/formComponents/TextArea.vue";
import SignaturePad from "signature_pad";
// import WebViewer from '@/components/commonDesign/WebViewer.vue'
import VPagination from "vue3-pagination";
import Pusher from "pusher-js";
// import VueChatScroll from 'vue-chat-scroll'
import "vue3-pagination/dist/vue3-pagination.css";
// import Multiselect from "@vueform/multiselect";
import MyDriveFilesUpload from "@/components/MyDriveFilesUpload.vue";

// import VueCoreVideoPlayer from 'vue-core-video-player';
// import VideoBackground from 'vue-responsive-video-background-player'
import introJs from "intro.js";
import { useStore } from "vuex";
// import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import Mention from '@ckeditor/ckeditor5-mention/src/mention';
// import Editor from '@/components/commonDesign/Editor.vue'
import Editor from "@/components/mention/Editor.vue";
import state from "pusher-js/types/src/core/http/state";
// import InfiniteLoading from "v3-infinite-loading";
// import "v3-infinite-loading/lib/style.css";
import VueEasyLightbox, { useEasyLightbox } from 'vue-easy-lightbox';

// import AppTour from "@/components/modal/AppTour.vue";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import DocusignDocumentListModel from "@/components/commonDesign/AppDocusignModel.vue";
import showShareDocusignDocumentModel from "@/components/modal/ShowDocusignShareDocumentModel.vue";
import SuccessfulModalCustom from "@/components/modal/SuccessfulModalCustom.vue";
import UpgradeSubscriptionModal from '@/common/UpgradeSubscriptionModal.vue'
import ChannelChatWelcomeMessage from "./ChannelChatWelcomeMessage.vue";
import ChatChannelAppMessageReceiver from "@/components/cards/ChatChannelAppMessageReceiver.vue";
import ChannelChatMembers from "@/components/channel/ChannelChatMembers.vue";
import ChatChannelAppInviteMember from "@/components/projectDetail/ChatChannelAppInviteMember.vue";
import Compressor from 'compressorjs';
import { initializeSocket, getSocket } from '@/socket';


export default defineComponent({
  name: "ChannelChatApp",
  components: {
    ChatChannelAppInviteMember,
    ChannelChatMembers,
    UpgradeSubscriptionModal,
    ChatChannelAppMessageReceiver,
    // VueCoreVideoPlayer,
    // CommunicationCard,
    // 'video-background': VideoBackground,
    SuccessfulModalCustom,
    MemberCard,
    ErrorList,
    TitleComponent,
    TextBoxComponent,
    Multiselect,
    RadioButtonComponent,
    SelectOption,
    CheckBox,
    TextArea,
    DigitalSignature,
    VPagination,
    MyDriveFilesUpload,
    Editor,
    DocusignDocumentListModel,
    VueEasyLightbox,
    showShareDocusignDocumentModel,
    ChannelChatWelcomeMessage,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    // VueperSlides,
    // VueperSlide
    // InfiniteLoading,
    // v-chat-scroll : VueChatScroll,
    // WebViewer,
    // Multiselect,
    // Editor,
  },

  setup(props, { emit }) {
    let socket: any = null;
    const readOnLoad = ref(false);
    const routeLeaveStatus = ref(false);
    const scrollTimeout = ref(null) as any;
    const scrollTimeoutThread = ref(null) as any;
    const messageDeliveryIds = [] as any;
    const chatBody = ref(null) as any;
    const chatReplyThreadBody = ref(null) as any;
    const visibleItems = ref([]);
    const chatScreenContent = ref(null);
    const showFullText = ref(false);
    const selectedValueForPending = ref(2) as any;
    const selectedDateForMember = ref('') as any;
    const selectedUsers = ref({}) as any;
    const allUsers = ref([]) as any;
    const pageData = ref({
      previous: null,
      current: null,
      next: null,
    }) as any;
    const reloadKey = ref(0);
    const {
      // methods
      show, onHide, changeIndex,
      // refs
      visibleRef = ref(false), indexRef = ref(0), imgsRef = ref([])
    } = useEasyLightbox({
      // src / src[]
      imgs: [],
      // initial index
      initIndex: 0
    });

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const moment = require("moment");
    const store = useStore();
    const {
      params: { channelId },
    } = useRoute();
    const paramsChannelId = useRoute();
    // console.log("Channel iDs >>> ", paramsChannelId);
    const router = useRouter();
    const routerQuery = useRoute();

    // console.log("Router Check >>>", router)
    const tempImageList = ref([]);
    const enableEnterKey = ref(false);
    const isRecording = ref(false);
    const isReplyRecording = ref(false);
    const transcription = ref('');
    const visiblePdf = ref(false);
    let recognition = '' as any;
    const state = reactive({

      newMessageStatus: false,
      firstLoad: true,
      valideFiles: ['xlsx', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'jpg', 'jpeg', 'png', 'webp', 'avif', 'gif', 'docx', 'heic', 'zip'],
      notAllowdedThreadSeen: false,
      currentActive: {
        msgSend: false,
        replySend: false,
      },
      allowScrollAction: false,
      scrollActive: false,
      scrollThreadActive: false,
      activeUserDetails: {} as any,
      isFirefox: false,
      inputError: false,
      duplicate: false,
      isMute: false,
      allSliderImage: [] as any,
      sendChannelId: [] as any,
      canvas: {} as any,
      signaturePad: {} as any,
      activeUserIndex: 0,
      selectedUser: {},
      active: false,
      currentSendUser: false,
      userFormData: [] as any,
      messages: [] as any,
      messageStatus: false,
      scrollerCheck: "" as any,
      firstScroll: false as boolean,
      messageCreated: false as boolean,
      fileLoadTime: 30,
      isReplyMessage: false,
      isPublicUser: false,
      isFile: false,
      isZoomed: false,
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      slides: [] as any,
      replyImage: '' as any,
      isNext: false,
      isPreV: false,
      currentIndex: 0,
      inviteAsPublic: [] as any,
      isManualScroll: false,
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: 'start',
        },
      },
      data: {
        selectedFile: null as any,
        selectedFileName: '',
        intervalId: 0,
        latestMessageId: 0,
        latestScrollHeight: "" as any,
        goToAttachmentRow: false,
        seenIds: [] as any,
        seenThreadIds: [] as any,
        deliveredIds: [] as any,
        members: [] as any,
        loadedPages: [] as any,
        isAllowToGoLatest: false,
        lastPageMessages: [] as any,
        injectChatMessages: false,
        loadedPage: 0,
        screenHeight: 0 as any,
        totalLiHeight: 0 as any,
        chatScroll: {
          scrollToTop: false,
          scrollToBottom: false,
          scrollPosition: {
            active: 0,
          }
        },
        threadScroll: {
          scrollToTop: false,
          scrollToBottom: false,
        },
        getCommunicationList: false,
        messageReceivers: [] as any,
        unreadMessage: 0,
        unreadActiveMessage: 0,
        channelDetails: {
          messages: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            per_page: 0,
          },
          channel: {} as any,
          owner: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            created_by: {} as any
          } as any,

        } as any,
        subscriptionDetails: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          appointment_numbers: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          archive_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_file_size: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_message_history: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          estimation_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          export_channel_pdf: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          files_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          folder_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          guests_per_project: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          import_option: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          invoice_payment: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          member_in_smart_contract: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          member_per_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          members_per_appointment: 0,
          name: "Silver",
          // eslint-disable-next-line @typescript-eslint/camelcase
          pdf_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          phase_per_smart_contract: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          private_channel: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sync_option: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          task_per_phase: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          template_number: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          total_channel_member: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          total_estimation: 0,
        } as any,
        channelUuid: channelId,
        successFulMessage: "",
        docuSignSignUrl: "",
        docuSignSignOpenUrl: "",
        inviteAsPublicUser: false,
        showSuccesCustomModal: false,
        showDocusignDocumentListModel: false,
        showShareDocusignDocumentModel: false,
        forwardMessage: {} as any,
        docusignShareDocumentDetail: {} as any,
        docusignShareDocumentData: {} as any,
        forwardWriteMessage: "" as any,
        clearBtn: false,
        userType: localStorage.getItem("current_user_role"),
        uploadMaxSize: (25 * 1024 * 1024) as any,
        search: "" as any,
        showReplyCommunication: false,
        approvalDiscussionInvitationId: "" as any,
        pageNumber: "" as any,
        isCommunicationFile: "" as any,
        isCommunicationReplyFile: "" as any,
        scrollTop: false,
        scrollBottom: false,
        currentPage: 1,
        page: 0,
        scrolledPages: [] as any,
        exportPdfs: [] as any,
        isExport: false,
        lastPage: 1,
        selectedChatIndex: 0,
        typeEditDisabled: false,
        typeDisabled: false,
        active: false,
        // editor: ClassicEditor,
        editorData: "<p>Content of the editor.</p>",
        editorConfig: {
          link: {
            addTargetToExternalLinks: true,
          },
          // The configuration of the editor.
          toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "blockQuote",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "link",
            "|",
            "undo",
            "redo",
          ],
        },
        disabledFileTagList: [] as any,
        showAddFileTag: false,
        tags: {
          mode: "tags",
          value: [] as any,
          options: [] as any,
          searchable: true,
          createTag: true,
        },
        projectMember: {} as any,
        inviteAsGuest: false,
        sharedFiles: {
          files: [] as any,
          attachments: [] as any,
        },
        selectedForRename: {} as any,
        selectedPendingMember: {} as any,
        userTypingStatus: false,
        userTyping: "",
        userUUID: "",
        userFullName: "",
        user: false,
        typing: false,
        invitationVerifyMsg: "",
        invitationVerify: {
          showStatus: false,
          newMemberList: [] as any,
          registeredMemberList: [] as any,
          pendingMemberList: [] as any,
        },
        title: "edoc",
        heading: "No digitized doc created.",
        selectedReplyUser: {} as any,
        msgByUser: {} as any,
        selectedFormData: {} as any,
        formData: {} as any,
        clientDocumentRetrieveWorkflowLink: {} as any,
        clientDocumentRetrieve: {} as any,
        convertedDate: "",
        replyMessage: "",
        additionalMessage: "",
        imageBaseUrl: "",
        replyClassStatus: false,
        startValidation: false,
        clientDocuments: [] as any,
        userList: [] as any,
        privacyList: [
          {
            name: "Private to this channel",
            code: "channel",
            value: "private to this channel",
          },
          {
            name: "Share within this project",
            code: "project",
            value: "share within this project",
          },
          { name: "Share to Public", code: "public", value: "share to public" },
        ],
        privacy: {
          name: "Private to this channel",
          value: "private to this channel",
          code: "channel",
        },
      },
      selectedResponseDelete: {} as any,
      selectedResponseAttachment: {} as any,
      selectedForSharedDelete: {} as any,
      selectedForDelete: {} as any,
      selectedForEdit: {} as any,
      selectedAttachment: {} as any,
      selectedPdf: "",
      email: "" as any,
      filter: {
        type: "all",
        projectId: 0,
        channelId: 0,
        sortBy: -1,
        title: 1,
        selected: { name: "Newest First", id: -1 } as any,
      },
      type: "",
      selected: "",
      typePosition: "bottom",
      errorList: [] as any,
      sharedFormList: {} as any,
      formList: {} as any,
      isErrorStatus: false,
      projectId: 0,
      pendingMembersCount: 0,
      approvedMembersCount: 0,
      isCreateProjectModalShow: false,
      isEditCreatenewFormFromChannel: false,
      postLoading: false,
      communicationList: [] as any,
      totalSearchResult: false,
      totalSearchResultCount: 0,
      channel: {} as any,
      userRole: {} as any,
      selectedElement: {
        element: {} as any,
        type: "",
        index: "",
        title: "",
        description: "",
      },
      memberList: [] as any,
      pendingList: [] as any,
      imageList: [] as any[],
      threadImageList: [] as any,
      searchBy: "",
      message: "",
      image: "",
      title: "communications",
      heading: "Get started by creating communication in your chat room.",
      titleMember: "members",
      headingMember: "Get started by adding members in your chat room.",
      existingMember: {} as any,
      inviteMember: "",
      invitationMessage: "",
      userList: [] as any,
      formData: {} as any,
      edocPdfPath: "",
      owner: {} as any,
      ownerId: "" as any,
      item: {
        selectedUserFormDetails: {} as any,
        searchUser: "",
        formId: "",
        paging: {
          getChannelFormList: {
            perPage: 10,
            totalDocs: 0,
            totalPages: 0,
            currentPage: 1,
          },
        },
        error: {
          hasError: false,
          videoError: false,
          fileError: false,
          erorList: [] as any,
          renameSelectedAttachment: [] as any,
          validationList: [] as any,
          erorListNewForm: [] as any,
          deleteCommunication: [] as any,
          forwardCommunication: [] as any,
          erorListSendForm: [] as any,
          erorListSaveCustomSelectedForm: [] as any,

        },
        hasError: false,
      },
      privacyList: [
        {
          name: "Private to this channel",
          code: "channel",
          value: "private to this channel",
        },
        {
          name: "Share within this project",
          code: "project",
          value: "share within this project",
        },
        { name: "Share to Public", code: "public", value: "share to public" },
      ],
      newFormData: {
        title: "",
        description: "",
        elements: [] as any,
        privacy: {
          name: "Private to this channel",
          value: "private to this channel",
          code: "channel",
        },
      },
      selectedFormForEdit: {
        id: "",
        title: "",
        description: "",
        elements: [] as any,
      } as any,
      invite: {
        users: [] as any,
        invites: [] as any[],
        filteredUsers: [] as any[],
        filteredInvites: [] as any[],
      },
      form: {
        textField: {
          title: "",
          description: "",
        },
        textArea: {
          title: "",
          text: "",
        },
        textbox: {
          title: "",
        },
        radioButton: {
          type: "radioButton",
          title: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          select_values: [] as any,
        },
        selectOption: {
          type: "single_select",
          title: "",
          selectedOption: {},
          // eslint-disable-next-line @typescript-eslint/camelcase
          select_values: [] as any,
        },
        checkBox: {
          type: "checkBox",
          title: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          select_values: [] as any,
        },
      },
      ui: {
        isActive: {
          chatChannelAppInviteMember: false,
          chatChannelMessageReceiver: false,
          latestMessageTriggered: false,
          loadLatestMessages: false,
          messageReceiver: false,
          upgradeSubscription: false,
        },
        errors: {
          hasError: false,
          errorList: {
            modifyHistoryDateForMember: [] as any,
            resendInvitation: [] as any,
          },
        },
        template: {
          msg: "",
          response: {} as any,
        },
        isLoading: {
          loaded: false,
          docuSignIframe: false,
          docuSignIframeOpen: false,
          activateScroll: false,
          communicationListAPICall: false,
          getSharedFiles: false,
          communicationList: false,
          clientDocumentRetrieveWorkflow: false,
          clientDocumentList: false,
          getAccountFormList: false,
          accountFormResponseByEmailAndId: false,
          getChannelFormList: false,
        },
        isPostLoading: {
          onScrollStopped: false,
          loadLatestMessages: false,
          messageSeenByMember: false,
          channelMessageReplySeenByMember: false,
          messageReplyThreadSeenByMember: false,
          modifyHistoryDateForMember: false,
          enableSelectedFileTag: false,
          selectedDocumentToDelete: false,
          renameSelectedAttachment: false,
          resendInvitation: false,
          deletePendingInvitation: false,
          deleteCommunication: false,
          forwardCommunication: false,
          channelCommunicationTyping: false,
          channelRecentlyVisited: false,
          verifyInvitation: false,
          closeChannel: false,
          downloadProjectFiles: false,
          getCommunicationList: false,
          loadInjectChatMessages: false,
          injectChatMessages: false,
          sendDigitizedForm: false,
          getUserList: false,
          clientDocumentRetrieve: false,
          removeSelectedCommunicationImage: false,
          sendEditedCommunication: false,
          createFormTemplate: false,
          getAccountFormRetrieveById: false,
          replyMessage: false,
          createNewForm: false,
          selectedUserAccountFormResponse: false,
          generateEdocStatement: false,
        },
        showLoader: false,
      },
      isLoading: {
        getUserListByChannel: false,
        getUserList: false,
        inviteAllUsers: false,
      }
    });

    // function saveUnsentMessage(message: any){
    //   const userId = "unsentMessages_"+state.activeUserDetails.id;
    //   // const unsentMessages: string[] = JSON.parse(localStorage.getItem("unsentMessages_"+state.activeUserDetails.id) || "[]");
    //   const unsentMessages: string[] = JSON.parse(await indexedDBService.getMessages(userId) || "[]");
    //   // unsentMessages.push(message);
    //   await indexedDBService.saveMessage(userId,message);
    //   // localStorage.setItem("unsentMessages_"+state.activeUserDetails.id, JSON.stringify(unsentMessages));
    // }
    function getCommunicationList(channelID: any) {
      //  if(state.data.lastPage<state.data.page) return false;
      state.item.error.erorList = [];
      state.item.hasError = false;
      if (state.postLoading) return false;
      if (state.ui.isPostLoading.getCommunicationList) return false;
      if (!state.data.search) {

        if (state.data.page >= state.data.lastPage) return false;

        if (state.data.page === 0 || !state.data.page) {
          state.data.page = 1;
        }
      }
      if (routerQuery.query.page) {
        const pageNum: any = routerQuery.query.page;
        const currentPage = parseInt(pageNum);
        state.data.scrolledPages = [];
        state.data.scrolledPages.push(currentPage);
      }

      //  state.data.page++;
      // if(routerQuery.query.page){
      // const pageNum: any= routerQuery.query.page;
      // state.data.page = parseInt(pageNum);
      // // payLoad.page = parseInt(pageNum);
      // }
      let page: any = 0;
      if (pageQuery.value != 0) {
        page = pageQuery.value;
      } else {
        page = state.data.page;
      }

      if (state.messageStatus) {
        page = 1;
        state.data.page = 1;
        state.data.scrolledPages = [];
        state.data.scrolledPages.push(page);
      }

      // state.ui.showLoader = true;

      const payLoad: any = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelID,
        search: state.data.search,
        user: localStorage.getItem("current_user_role"),
        // eslint-disable-next-line @typescript-eslint/camelcase
        // communication_id: routerQuery.query.communicationId
        //   ? routerQuery.query.communicationId
        //   : "",
      };
      if (state.data.getCommunicationList) {
        payLoad.page = page;
      }
      state.ui.isPostLoading.getCommunicationList = true;
      ethitransService
        // .createCommunicationList(payLoad)
        .createCommunicationListUpdated(payLoad)
        .then(async (res: any) => {
          // if(state.data.search.length > 0){
          //   if(res.data.data.messages.current_page == 1){
          //     state.communicationList = [];
          //   }
          // }
          console.log("Check the Response >>> ", res.data.data.messages)
          state.ui.isLoading.loaded = true;
          state.ui.isPostLoading.getCommunicationList = false;
          state.postLoading = false;
          state.data.getCommunicationList = true;
          // res.data.data.messages.data.forEach((message: any)=> {
          //   if(message.message_receivers && message.message_receivers.length > 0){
          //     message.message_receivers = filterMessageReceivers(message.message_receivers);
          //   }
          // })
          res.data.data.messages.data.forEach((message: any) => {
            console.log("CHECK THE MESAGE >>> ", message);
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (message.message_receivers && message.message_receivers.length > 0) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              message.message_receivers = filterMessageReceivers(message.message_receivers);
            }
            if (message.responses && message.responses.length > 0) {
              message.responses.forEach((reply: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                reply.message_receivers = filterMessageReceivers(reply.message_receivers);
              })


            }
          })
          state.data.unreadMessage = res.data.data.unread_count ? res.data.data.unread_count : 0;
          let arrayMessageDeliveredIds: any = [];

          // state.ui.isLoading.loaded = true;
          state.data.channelDetails = res.data.data;

          state.data.subscriptionDetails = res.data.data.package_features;
          state.data.currentPage = res.data.data.messages.current_page;
          state.data.page = res.data.data.messages.current_page;
          state.data.lastPage = res.data.data.messages.last_page;
          state.isPublicUser = res.data.data.is_public_user;

          if (state.data.currentPage === state.data.page) {
            state.data.lastPageMessages = res.data.data.messages.data;
          }


          const comID: any = routerQuery.query.communication_id;
          const communicationIdInQuery: any = routerQuery.query.communicationId;

          res.data.data.messages.data.forEach((item: any) => {
            if ((item.user.email != localStorage.getItem("current_user_email")) && item.message_status === 0) {
              arrayMessageDeliveredIds.push(item.id)
            }
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (item.message_receivers && item.message_receivers.length) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.message_receivers = moveSenderToEnd(item.message_receivers);
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.message_receivers.forEach((message: any) => {
                // console.log("Login User >>> ", localStorage.getItem("current_user_email"));
                // console.log("Members >>> ", message.email)
                if (message.email === localStorage.getItem("current_user_email")) {
                  message.sender = true;
                } else {
                  message.sender = false;
                }
              })
            }

            // eslint-disable-next-line @typescript-eslint/camelcase
            if (routerQuery.query.communicationId) {
              state.data.isCommunicationFile =
                routerQuery.query.communicationId;
              // state.data.scrolledPages.push(state.data.page);

              //  document.getElementById(state.data.isCommunicationFile).scrollIntoView();
              //  const element: any= document.getElementById(state.data.isCommunicationFile);
              //  element.scrollIntoView();
            }

            if (parseInt(comID) === item.id) {
              state.data.showReplyCommunication = true;
              replyCommunication(item);

              console.log("message in in communicationList", item);
            }

            if (
              parseInt(communicationIdInQuery) === item.id &&
              routerQuery.query.reply
            ) {
              state.data.showReplyCommunication = true;
              state.data.isCommunicationReplyFile = routerQuery.query.reply;
              replyCommunication(item);

              // console.log("message in in communicationList", item);
            }
            //      if(parseInt(comFIleID) === item.id){
            //     state.data.isCommunicationFile=true;
            //  }
            item.responses.forEach((response: any) => {
              response.createdLatest = common.localDateAndTime(
                response.created_at
              );
            });
            if (item.forward && item.forward.communication && item.forward.communication.responses) {
              item.forward.communication.responses.forEach((response: any) => {
                response.createdLatest = common.localDateAndTime(
                  response.created_at
                );
              });
            }

            item.editedUpdated = common.localDateAndTime(item.updated_at);
            if (item.forward && item.forward.communication) {
              item.forward.communication.editedUpdated = common.localDateAndTime(item.forward.communication.updated_at);
            }
            item.allowEdit = false;
            item.allowDelete = false;
            if (item.share_history && item.share_history.length) {
              item.share_history.forEach((sharedHistory: any) => {
                sharedHistory.documents.forEach((doc: any) => {
                  if (doc.filetype === "mov" || doc.filetype === "MOV") {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    doc.filetype = "mp4";
                  }
                  doc.file.convertedFileSize = common.formatBytes(
                    parseInt(doc.file.filesize)
                  );
                });
              });
            }
          });


          // console.log("<<< IDS COLLECTION >>>", arrayMessageDeliveredIds)
          res.data.data.messages.data.forEach((data: any) => {
            // console.log("DATE >>> ", data.sent_date);
            data.currentTime = common.localDateAndTime(data.sent_date);
            // eslint-disable-next-line @typescript-eslint/camelcase
            data.deletedLocalTime = data.deleted_at ? common.localDateAndTime(data.deleted_at) : null;
            if (data.attachments) {
              data.attachments.forEach((video: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                if (video.file_type === "mov" || video.file_type === "MOV") {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  video.file_type = "mp4";
                }
              });

              // console.log("Data Check >>> ", data)
            }
          });
          checkMessageStatus(res.data.data.messages.data);
          // console.log("messagecheckbyom >>> ", state.communicationList);
          // if (
          //   res.data.data.messages.data &&
          //   res.data.data.messages.data.length
          // ) {
          //   //  state.data.scrollTop = false;
          // }
          let admin: any = {};

          if (
            res.data.data.project_role.level === 1 &&
            res.data.data.project_role.code === "owner"
          ) {
            admin = res.data.data.project_role;
          } else {
            if (
              res.data.data.project_role.level === 2 &&
              res.data.data.project_role.code === "co-owner"
            ) {
              admin = res.data.data.project_role;
            }
          }
          const filteredItems = res.data.data.channel.members.filter((mem: any) => mem.user.email === state.email && mem.is_mute == 1);
          console.log('fimt0: ', filteredItems)
          state.isMute = filteredItems.length > 0 ? true : false;

          const channelMember: any = [];
          res.data.data.channel.members.forEach((member: any) => {
            channelMember.push(member.user.full_name);
            member.isChangeRole = false;
            if (admin && admin.level && admin.code) {
              if (member.user && member.user.email) {
                if (member.user.email !== state.email) {
                  member.isChangeRole = true;
                  // console.log("CHECK MEMBER >>> ", member.user);
                }
              }
            }
          });
          // console.log("FINAL MEMBERLIST CHECK", channelMember);
          setMentionMembers(channelMember);
          // setTimeout(store.dispatch("saveActiveMembers", channelMember), 100);

          // saveActiveMembers
          // console.log("Members", res.data.data.channel.members);
          // if (admin && admin.level && admin.code) {
          //   res.data.data.channel.members.forEach((member: any) => {
          //     console.log("Check Member >>> ", member)
          //     console.log("Check LOGIN USER >>> ", admin)
          //     if (admin.level != member.project_role.level) {
          //       if (member.project_role.level > admin.level) {
          //         // console.log("INDIDE ADMIN", admin.level);
          //         // console.log("INDIDE MEMBER", member.project_role.level);
          //         member.isChangeRole = true;
          //       }
          //     }
          //   });
          // }
          state.memberList = res.data.data.channel.members
            ? res.data.data.channel.members
            : [];
          // state.messages = res.data.data
          state.data.projectMember = res.data.data.project_member
            ? res.data.data.project_member
            : {};
          // console.log("Date Validity Check >>> ", state.data.projectMember.validity_date)
          state.channel = res.data.data.channel ? res.data.data.channel : {};
          state.pendingMembersCount = res.data.data.pending_members_count
            ? res.data.data.pending_members_count
            : 0;
          state.approvedMembersCount = res.data.data.approved_members_count
            ? res.data.data.approved_members_count
            : 0;
          state.pendingList = res.data.data.pending_members
            ? res.data.data.pending_members
            : [];
          state.userRole = res.data.data.project_role
            ? res.data.data.project_role
            : {};
          state.projectId = res.data.data.channel.project.uuid
            ? res.data.data.channel.project.uuid
            : "";
          // state.projectId = res.data.data.channel.project_id
          //   ? res.data.data.channel.project_id
          //   : 0;
          res.data.data.messages.data.forEach((item: any) => {
            if (item.form_data != null) {
              // console.log("check msg :>>", item.form_data);
              if (item.form_data && item.form_data.sent_to_users) {
                // console.log( "Type check", item.form_data.sent_to_users)
                if (typeof item.form_data.sent_to_users === "string") {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  item.form_data.sent_to_users = JSON.parse(
                    item.form_data.sent_to_users
                  );
                }
                // console.log(
                //   "Final :>>",
                //   JSON.parse(item.form_data.sent_to_users)
                // );
                // eslint-disable-next-line @typescript-eslint/camelcase
              }
            }
          });

          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((data: any) => {
              if (data.forward && data.forward.attachments.length) {
                data.forward.attachments.forEach((attachment: any) => {
                  attachment.convertedFileSize = common.formatBytes(
                    parseInt(
                      attachment.file_size
                    )
                  );
                });
              }
            });
          }

          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((data: any) => {
              data.allowEdit = false;
              if (data.attachments && data.attachments.length) {
                data.attachments.forEach((attachment: any) => {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  attachment.deleted_at = attachment.deleted_at ? common.localDateAndTime(attachment.deleted_at) : null;
                  attachment.convertedFileSize = common.formatBytes(
                    parseInt(
                      attachment.file_size
                      // parseInt(data.file_size.split(" ")[0])
                    )
                  );
                });
              }
              const result: any = [];
              const map = new Map();
              data.responses.forEach((item: any) => {
                if (!map.has(item.response_by_id)) {
                  map.set(item.response_by_id, true); // set any value to Map
                  result.push(item);
                }
              });
              data.editStatus = result;

              // console.log("FINAL CHECK", result)
              // for (const item of array) {

              // }
              //             data.uniqueResponse = data.responses.map((item: any) => {item.response_by_id})
              // .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
              // data.responses.forEach((item: any) => {
              //   item.uniqueUsers = [];
              //   item.response_by

              // })
            });
          }
          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((item: any) => {
              if (item.user && item.user.email) {
                if (
                  item.user.email === localStorage.getItem("current_user_email")
                ) {
                  // console.log("email", item.user.email);
                  // console.log("active email", state.data.activeUserEmail);
                  item.editStatus = true;
                } else {
                  item.editStatus = false;
                }
              }
            });
          }

          state.totalSearchResultCount = 0
          res.data.data.messages.data.forEach(async (item: any) => {
            const searchText = state.data.search;
            const regex = new RegExp(searchText, 'gi');
            if (state.data.search && item.attachments.length && item.message) {

              item.attachments.forEach((item2: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                const result0 = item2.file_name.match(regex);
                console.log("this is result 0", result0);
                if (result0) {
                  state.totalSearchResultCount = state.totalSearchResultCount + result0.length;

                }
                // eslint-disable-next-line @typescript-eslint/camelcase
                item2.file_name = item2.file_name.replace(regex, '<mark class="highlight">$&</mark>');
                if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                  item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
                }
              })
              const result1 = item.message.match(regex);
              if (result1) {
                state.totalSearchResultCount = state.totalSearchResultCount + result1.length;
              }
              item.message = item.message.replace(regex, '<mark class="highlight">$&</mark>');
              if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
              }
              console.log("fow", item.forward.communication.message)
              await replyCounter(item.responses, regex);
              // eslint-disable-next-line @typescript-eslint/camelcase
              await shareFileCounter(item.share_history, regex);

            }

            if (state.data.search && item.attachments.length) {
              item.attachments.forEach((item2: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                const result2 = item2.file_name.match(regex);
                if (result2) {
                  state.totalSearchResultCount = state.totalSearchResultCount + result2.length;
                }
                // eslint-disable-next-line @typescript-eslint/camelcase
                item2.file_name = item2.file_name.replace(regex, '<mark class="highlight">$&</mark>');
                if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                  item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
                }
              })

              await replyCounter(item.responses, regex);
              // eslint-disable-next-line @typescript-eslint/camelcase
              await shareFileCounter(item.share_history, regex);

            }

            if (state.data.search && !item.attachments.length) {
              const result3 = item.message && item.message.match(regex) || item.forward.communication.message.match(regex);
              if (result3) {
                state.totalSearchResultCount = state.totalSearchResultCount + result3.length;
                // item.message = item.message.replace(/(<mark class="highlight">|<\/mark>)/gim, '');
                item.message = item.message.replace(regex, '<mark class="highlight">$&</mark>');
                if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                  item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
                }

              }

              await replyCounter(item.responses, regex);
              // eslint-disable-next-line @typescript-eslint/camelcase
              await shareFileCounter(item.share_history, regex);

            }

          });
          console.log("Message match count >>>", state.totalSearchResultCount);

          state.ownerId = res.data.data.owner.user_id;
          state.owner = res.data.data.channel;
          console.log("FILERED IDS>>> ", getIdsForActiveUser(res.data.data.messages.data))



          // <<< checking for the position Check >>>>
          if (state.data.chatScroll.scrollToTop) {
            // alert("I AM ON TOP")
            res.data.data.messages.data =
              res.data.data.messages.data.reverse();
            res.data.data.messages.data.forEach((data: any) => {
              state.communicationList.push(data);
            });



            console.log("I AM ON TOP")
            state.data.chatScroll.scrollToTop = false;
          } else if (state.data.chatScroll.scrollToBottom) {
            // alert("I AM ON BOTTOM")
            res.data.data.messages.data = res.data.data.messages.data.reverse();

            res.data.data.messages.data.forEach((data: any) => {
              state.communicationList.unshift(data);
            });

            // return the scroll normal
            state.data.chatScroll.scrollToBottom = false;
          } else {
            res.data.data.messages.data.forEach((data: any) => {
              state.communicationList.push(data);
            });
            setTimeout(() => {
              // Triggered when scroll has stopped
              getVisibleLiId();
            }, 500);
            console.log("I AM ON NORMAL MSG")
          }

          // }

          updateCommunicationList(state.communicationList.filter(
            (value: any, index: any, self: any) =>
              self.findIndex((v: any) => v.id === value.id) === index
          ));
          console.log("IDs for Delivered >>>", arrayMessageDeliveredIds)
          // messageReceived(res.data.data.messages.data);
          if (arrayMessageDeliveredIds && arrayMessageDeliveredIds.length != 0) {
            // messageDeliveryIds.value = arrayMessageDeliveredIds;
            messageReceived(arrayMessageDeliveredIds);
            arrayMessageDeliveredIds = [];
          }

          state.data.approvalDiscussionInvitationId = res.data.data.payer_request_id ? res.data.data.payer_request_id : '';
          getUserList();
          state.ui.isLoading.communicationListAPICall = true;
          // setTimeout(toggleTourGuide, 1000);


          state.data.chatScroll.scrollToTop = false;
          state.data.chatScroll.scrollToBottom = false;



          state.data.scrollTop = false;
          state.data.scrollBottom = false;
          // console.log("FINAL CHECK COLLECTION", arrayUniqueByKey.length)
          // const liElements = document.querySelectorAll('li');
          //  console.log("Check the ITEMS >>> ", liElements)

          state.allowScrollAction = true;
          // setTimeout(() => {
          //   // Triggered when scroll has stopped
          //   // console.log("<<< Triggered >>>  INSIDE")
          //   getVisibleLiId();
          // }, 500);

        })
        .catch((error: any) => {
          state.data.scrollTop = false;
          // console.log("message", error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
      // .finally(() => {
      //   const visibleMessages = Array.from(document.querySelectorAll('.message')).filter((element: any) => {
      //     // console.log("ELEMENTS CHECL >>> ", element)
      //     // const itemElement = document.getElementById(`item-${element.id}`);
      //     // console.log("CHECK THE ITEMS >>> ")
      //     // Check if the element is visible
      //     const style = window.getComputedStyle(element);
      //     return style.display !== 'none' && style.visibility !== 'hidden' && element.offsetHeight > 0 && element.offsetWidth > 0;
      //   });

      //   // Select the container you want to track (e.g., the window or a specific scrollable element)
      //   const scrollContainer = document.documentElement || document.body;

      //   // Function to get the scroll position as a percentage of total scrollable height
      //   const getScrollPosition = () => {
      //     const scrollTop = scrollContainer.scrollTop;
      //     const scrollHeight = scrollContainer.scrollHeight;
      //     const clientHeight = scrollContainer.clientHeight;

      //     // Calculate scroll position as a percentage
      //     const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

      //     // console.log(`Scroll Position: ${scrollPercentage.toFixed(2)}%`);
      //   };

      //   // Add a scroll event listener
      //   scrollContainer.addEventListener('scroll', getScrollPosition);

      //   // state.data.scrollTop = false;
      //   // alert("LAST")
      //   state.ui.isPostLoading.getCommunicationList = false;
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   //  if(state.isReplyMessage) return ;
      //   // console.log("state.messageStatus", state.messageStatus);
      //   // console.log("messge.reply ", state.communicationList);
      //   if (!state.firstScroll) {
      //     scrollToLast();
      //   } else {
      //     // alert("RESET")
      //     state.data.scrollTop = false;
      //     if (!state.messageStatus) {
      //       scrollToTop();
      //     } else {
      //       scrollToLast();
      //     }
      //   }
      // });
    }
    function openRenameModal(image: any) {
      state.data.selectedFileName = image.fileName;
      state.data.selectedFile = image;
      console.log('The File Name is..>>>', image.fileName);
      // alert('hello rename model')
        $("#namerename").modal("show");

    }

    function saveFileName() {
     if (state.data.selectedFile && state.data.selectedFileName.trim() !== "") {
     state.data.selectedFile.fileName = state.data.selectedFileName; 
     }
    $("#namerename").modal("hide");
   }

    async function saveUnsentMessage(message: any) {
      await indexedDBService.saveMessage({
        messageId: message.messageId,
        content: JSON.stringify(message)
      });
    }

    async function saveUnsentMessageReply(message: any) {
      await indexedDBService.saveMessageReply({
        messageId: message.responseId,
        content: JSON.stringify(message)
      });
    }

    async function removeUnsentMessageReply(messageId: number) {
      await indexedDBService.deleteMessageReply(messageId);
    }

    // function removeUnsentMessage(messageId: number){
    //   const unsentMessages: any [] = JSON.parse(localStorage.getItem("unsentMessages_"+state.activeUserDetails.id) || "[]");
    //    // Filter out the message with the given ID
    //   const updatedMessages = unsentMessages.filter(msg => msg.messageId !== messageId);

    //   // Save the updated messages back to localStorage
    //   localStorage.setItem("unsentMessages_"+state.activeUserDetails.id, JSON.stringify(updatedMessages));
    // }


    async function removeUnsentMessage(messageId: number) {
      await indexedDBService.deleteMessage(messageId);
    }
    function mergeWithCommunicationList(existingArray: any, newArray: any) {
      newArray.forEach((newItem: any) => {
        const existingIndex = existingArray.findIndex((item: any) => item.id === newItem.id);
        if (existingIndex !== -1) {
          // Update existing item
          existingArray[existingIndex] = { ...existingArray[existingIndex], ...newItem };
        } else {
          // Add new item to the existing array
          existingArray.push(newItem);
        }
      });
    }
    function alignArrayWithOrder(
      originalArray: any,
      newArray: any
    ): any {
      // Check if originalArray is sorted in ascending order
      const isAscending = originalArray.every(
        (item: any, index: any, arr: any) => index === 0 || arr[index - 1].id <= item.id
      );

      if (originalArray.length < state.communicationList.length) {
        originalArray = state.communicationList;
      }

      // Create a map of IDs for quick lookup
      const newArrayMap: Map<number, any> = new Map(newArray.map((item: any) => [item.id, item]));

      // Merge and replace items based on originalArray's order
      const result = [];
      for (const originalItem of originalArray) {
        if (newArrayMap.has(originalItem.id)) {
          // Replace the original item with the one from newArray
          result.push(newArrayMap.get(originalItem.id)!);
          // Remove the processed item from newArrayMap
          newArrayMap.delete(originalItem.id);
        } else {
          // Retain the original item
          result.push(originalItem);
        }
      }

      // Add remaining items from newArray to the result array
      const remainingNewItems = Array.from(newArrayMap.values());

      // Sort remaining items if necessary to preserve ascending order
      if (isAscending) {
        remainingNewItems.sort((a, b) => a.id - b.id);
      }
      // Merge the remaining items into the result
      // return isAscending
      //   ? [...result, ...remainingNewItems]
      //   : [...remainingNewItems, ...result];
      return isAscending
        ? [...remainingNewItems, ...result]
        : [...result, ...remainingNewItems];
    }
    const closeChannelChatMembersModal = () => {
      state.ui.isActive.chatChannelMessageReceiver = false;
    }




    const showChannelMembers = (members: any) => {
      console.log(" Members >>> ", members);
      state.data.members = members;
      state.ui.isActive.chatChannelMessageReceiver = true;
    }
    // const uniqueChatMessages = computed(() => {
    //   const seen = new Set();
    //   return state.communicationList.filter((item: any) => {
    //     if (seen.has(item.id)) {
    //       return false;
    //     }
    //     seen.add(item.id);
    //     return true;
    //   });
    // });

    const uniqueChatMessages = computed(() => {
      const seen = new Set<number>();
      return state.communicationList
        .filter((item: any) => {
          if (seen.has(item.id)) {
            return false;
          }
          seen.add(item.id);
          return true;
        })
        .sort((a: any, b: any) => a.id - b.id); // Sort by `id` in ascending order
    });

    function replaceObjectInCommunication(objects: any, newData: any) {
      const index = objects.findIndex((obj: any) => obj.id === newData.id);

      // If a matching object is found, replace it with newData
      if (index !== -1) {
        objects[index] = { ...newData };
      } else {
        // If no matching object is found, push newData into the array
        objects.push(newData);
      }

      return objects;
    }
    const myMessageList = computed(() => {
      const ids: any = [];
      if (state.communicationList && state.communicationList.length === 0) return false;
      state.communicationList.forEach((message: any) => {
        if ((message.user.email != localStorage.getItem("current_user_email")) && message.message_status === 1) {
          ids.push(message.id)
        }

      })
      return ids ? ids : [];

    });
    function getIdsofNotMyMessage(collection: any, idsArray: any) {
      // return collection.filter((id: any) => !idsArray.includes(id));
      return collection.filter((item: any) =>
        !idsArray.includes(item.id) &&
        item.message_status === 1 &&
        item.user.email !== state.activeUserDetails.email
      ).map((item: any) => item.id);
    }
    function filterMessageReceivers(messageReceivers: any) {
      return messageReceivers.filter((receiver: any) => receiver.email !== state.activeUserDetails.email);
    }
    function filterSenderForReplyThread(messageReceivers: any, sender: any) {
      return messageReceivers.filter((receiver: any) => receiver.email !== sender);
    }

    // const getIdsForActiveUser = (data: any) => {
    //   return data.filter((message: any) => message.user.email != state.activeUserDetails.email).map((message: any) => message.id);
    // }
    // const getIdsForActiveUser = (data: any) => {
    //   return data
    //     .filter((message: any) => {
    //       if (message.message_status === 0) {
    //         // Execute the existing filter logic for active user
    //         return message.user.email !== state.activeUserDetails.email;
    //       } else if (message.message_status === 1) {
    //         // Log "hello" and handle additional logic here if needed
    //         console.log('hello');
    //         // Space for other code
    //         return false; // Exclude this message from further processing
    //       }
    //       // Handle other statuses if necessary
    //       return false;
    //     })
    //     .map((message: any) => message.id);
    // };
    const getIdsForActiveUser = (data: any) => {
      return data
        .filter((message: any) => {
          if (message.message_status === 1) {
            // Check if message_receivers is an array and has elements
            if (Array.isArray(message.message_receivers) && message.message_receivers.length > 0) {
              // Loop through message_receivers
              for (const receiver of message.message_receivers) {
                if (receiver.email === state.activeUserDetails.email) {
                  if (receiver.status === 1) {
                    // Execute code for status === 1
                    console.log('Executing code for status === 1');
                    // Additional logic can go here
                    return true; // Include this message
                  }
                }
              }
            }
            return false; // Exclude if no match is found
          }
          return false; // Exclude messages with other statuses
        })
        .map((message: any) => message.id);
    };
    const setUsersInMessageRow = (row: any, email: string, id: number) => {

      // #cad7ef

      // res.data.data.messages
      // check in the particular row for the sender of the message and hide
      // check in the particular row for the loggedIn user and hide that user
      // first to in the message_receivers

      // second in the responses's message_receivers

      // 1. working for the sender in msg_receiver first

      // if (row.user.email === email && row.user.id === id) {
      //   // Modify the object
      //   return {
      //     ...row,
      //     message_status: 1, // Update message status to "read"
      //     message_receivers: row.message_receivers.map((receiver: any) => ({
      //       ...receiver,
      //       status: 1 // Update all receiver statuses to "read"
      //     })),
      //   };
      // }

      // // Return the original message if no match
      // return row;
    }
    const latestCommunication = computed(() => {
      return state.communicationList;
    });

    // eslint-disable-next-line vue/return-in-computed-property
    const isLatestMessageButtonActive = computed(() => {
      if (state.newMessageStatus === true) return false;
      if (latestCommunication.value.length === 0) return false;
      if (state.data.lastPage === 1) return false;
      if (state.data.currentPage === state.data.lastPage) return false;

      return !state.data.isAllowToGoLatest;
    });
    const findIndexById = (targetObject: any, array: any) => {
      if (!targetObject || !array) return -1; // Handle invalid inputs
      return array.findIndex((item: any) => item.id === targetObject.id);
    };

    const replaceObjectByIdInArray = (targetObject: any, newObject: any, array: any) => {
      const index = findIndexById(targetObject, array);
      if (index !== -1) {
        array[index] = newObject;
      }
    };

    const loadData = () => {
      //calling the api
    };
    const toggleActive = () => {
      state.data.active = !state.data.active;
    };
    if (localStorage.getItem("current_user_info")) {
      const userData: any = localStorage.getItem("current_user_info");
      state.data.userUUID = JSON.parse(userData).uuid;
      state.data.userFullName = JSON.parse(userData).full_name;

      // console.log(JSON.parse(userData).uuid)
    }
    function resetActive() {
      state.communicationList.forEach((item: any) => {
        item.active = false;
      });
    }
    function enterSetting(data: boolean) {
      // console.log("enter settings");
      if (data === true) {
        enableEnterKey.value = true;
      } else {
        enableEnterKey.value = false;
      }
      console.log("enter settings", enableEnterKey.value);
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const pageQuery = computed(() => {
      if (routerQuery.query.page) {
        const pageNum: any = routerQuery.query.page;
        const currentNumber = parseInt(pageNum);
        // state.data.page = currentNumber;
        return currentNumber;
      } else return 0;
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const enableSendMsg = computed(() => {
      return enableEnterKey.value;
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const clearEditor = computed(() => {
      if (state.message.startsWith("<p>&nbsp;</p>")) {
        // console.log("<<Removed >>");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.message = "";
      }
      if (state.message.startsWith("<p></p>")) {
        // console.log("<<Removed >>");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.message = "";
      }
      if (state.message.startsWith("<p><br></p>")) {
        // console.log("<<Removed >>");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.message = "";
      }
      if (state.message) {
        return true;
      } else if (state.imageList && state.imageList.length) {
        return true;
      } else {
        return false;
      }
    });
    const itemsToShow = state.imageList.length;
    // eslint-disable-next-line vue/return-in-computed-property
    const relySelectedMessage = computed(() => {
      if (state.data.replyMessage.startsWith("<p>&nbsp;</p>")) {
        // console.log("<<Removed >>");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.data.replyMessage = "";
      }
      if (state.data.replyMessage.startsWith("<p></p>")) {
        // console.log("<<Removed >>");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.data.replyMessage = "";
      }
      if (state.data.replyMessage.startsWith("<p><br></p>")) {
        // console.log("<<Removed >>");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.data.replyMessage = "";
      }

      return state.data.replyMessage;
    });

    function toggleTourGuide() {
      if (store.state.showTourGuide) {
        introJs()
          .setOptions({
            steps: [
              {
                title: "Welcome",
                intro:
                  "Welcome to Chat Channel! 👋 <iframe width='426' height='240' src='https://www.youtube.com/embed/G8vkPCn2bLs' title='Slice Text in Photoshop' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>",
              },
              {
                title: "Project Title",
                element: document.getElementById(
                  "projectTitle"
                ) as HTMLInputElement,
                intro: "This channel related to it's project.",
                position: "right",
              },
              {
                title: "Channel Name",
                element: document.getElementById(
                  "channelToProject"
                ) as HTMLInputElement,
                intro: "Channel Title and its created data.",
                position: "right",
              },
              {
                title: "Channel Members",
                element: document.getElementById(
                  "channelMemberList"
                ) as HTMLInputElement,
                intro: "Members in this Channel with status",
                position: "left",
              },
              {
                title: "More...",
                element: document.getElementById(
                  "menu-toggle"
                ) as HTMLInputElement,
                intro:
                  "Add Members to channel, see shared attachments, documents, forms etc, close channel.",
                position: "left",
              },
              {
                title: "Chat Communication",
                element: document.getElementById(
                  "chat-body-content"
                ) as HTMLInputElement,
                intro: "Chat Conversations, attachments, forms shared.",
                position: "left",
              },
              {
                title: "Mesasge",
                element: document.getElementById("sendMsg") as HTMLInputElement,
                intro: "Type Message here...",
                position: "bottom",
              },
              {
                title: "Digitized Templates",
                element: document.getElementById(
                  "showDigitizedTemplate"
                ) as HTMLInputElement,
                intro: "See the Digitized Documents",
                position: "bottom",
              },
              {
                title: "Send Templates",
                element: document.getElementById(
                  "showSendTemplate"
                ) as HTMLInputElement,
                intro: "See the templates and send.",
                position: "bottom",
              },
              {
                title: "Attachment",
                element: document.getElementById(
                  "sendAttachment"
                ) as HTMLInputElement,
                intro: "Upload Files...",
                position: "bottom",
              },
              {
                title: "Send",
                element: document.getElementById(
                  "sendChatMessage"
                ) as HTMLInputElement,
                intro: "Send Message and Attachments...",
                position: "bottom",
              },
            ],
          })
          .start();
      }
    }
    watch(
      () => state.data.inviteAsGuest,
      () => {
        if (!state.data.inviteAsGuest) return false;
        console.log("Guest Invitation Selected");
        state.existingMember = "";
        state.invite.users = [];
      }
    );

    watch(
      // () => watchTour,
      () => store.state.takAtour,
      (newvalue: any, oldvalue: any) => {
        if (newvalue === true) {
          // console.log("check >>>", newvalue)
          toggleTourGuide();
        }
      }
    );

    function resetMessageStatusIncrementally(arr: any,messageId: number) {
      
      for (let index = 0; index < arr.length; index++) {
        if (arr[index].messageId == messageId) {
          arr[index].messageSendStatus = false;
          console.log(`Reset messageSendStatus at index ${index}:`, arr[index]);
        }
      }

    }
    async function uploadAttachmentByChannel(
      // isuplodedIndex: number,
      // imageName: any,
      message: any,
      // extension: string,
      selectedChannelId: any,
      // attachment: any,
      allFile: any,
      messageId: any
    ) {
      const fileList = [] as any;
      allFile.forEach((item: any) => {
        console.log("CHECK IMAGE LIST", item.tag);
        const tagIds = [] as any;
        const fileData = {} as any;
        if (item.isvalidToUpload) {
          if (item.tag && item.tag.length) {
            state.data.tags.options.forEach((tagItem: any) => {
              item.tag.forEach((dataItem: any) => {
                if (tagItem.name.toLowerCase() === dataItem.toLowerCase()) {
                  tagIds.push(tagItem.id);
                }
              });
            });
          }
          // isUploadedFile = true;
          console.log("im form if")
          fileData["fileName"] = item.fileName;
          fileData["base64"] = item.base64;
          fileData["extension"] = item.extension;
          fileData["item"] = item.item;
          fileData["master_file_tag_id"] = tagIds;
          fileList.push(fileData);
        } else {
          console.log("im form else")
          state.postLoading = false;
          state.imageList = state.imageList.filter((e: any) => e != item);
        }
      });

      const payLoad = {
        message: message,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: selectedChannelId,
        // filename: imageName,
        // extension: extension,
        // attachment: imageLoaded,
        // eslint-disable-next-line @typescript-eslint/camelcase
        files: fileList,
        messageId: messageId
      };
      // console.log("PAYLOAD CHECK >>", state.imageList[isuplodedIndex]);
      state.postLoading = true;
      // state.imageList[isuplodedIndex].isuploded = true;
      return await new Promise((resolve, reject) => {
        ethitransService
          .uploadAttachment(payLoad)
          .then((res: any) => {

            resolve(res);
          })
          .catch((error: any) => {
            console.log(error);
            reject(false);
          })
          .finally(() => {
            if (state.imageList && !state.imageList.length) {
              state.postLoading = false;
            }
            // state.postLoading = false;
            //  state.imageList[isuplodedIndex].isuploded = false;
            state.imageList = [];
          });
      });
    }

    async function sendPendingMessage(){
        const messages = await indexedDBService.getAllMessages();
        if(messages.length > 0){
          messages.forEach((messageData: any)=>{
            const message = JSON.parse(messageData['content']);
            if(message.is_file){
              uploadAttachmentByChannel(
                message.message,
                // common.findIndex(state.imageList, item),
                // item.fileName,
                // item.base64,
                // item.extension,
                message.channel.uuid,
                // item,
                message.imageList,
                message.messageId
              )
                .then((result: any) => {
                  if(result.data.data.messageId > 0){
                    clearInterval(state.data.intervalId);
                    removeUnsentMessage(result.data.data.messageId);
                    state.ui.isPostLoading.getCommunicationList = false;
                    getCommunicationList(channelId);
                    // got final result
                    resetMessageStatusIncrementally(state.communicationList,result.data.data.messageId);
                  }
                  
                })
                .catch((err) => {
                  // got error
                })
            .finally(() => {
              state.imageList = [];
              state.postLoading = false;
              state.message = "";
              state.isFile = false;
            });
            }else{
              const payLoad = {
                // eslint-disable-next-line @typescript-eslint/camelcase
                //chnage using communication reply
                // channel_id: routerQuery.params.channelId,
                // eslint-disable-next-line @typescript-eslint/camelcase
                channel_id: message.channel.uuid,
                message: message.message,
                file: message.is_file,
                type: "text",
                messageId: message.messageId
              };
              getSendMessage(payLoad);
            }
          });
        }
        const messageReplies = await indexedDBService.getAllMessageReplies();
        if(messageReplies.length > 0){
          messages.forEach((messageData: any)=>{
            const message = JSON.parse(messageData['content']);
            const payLoad = {
              // eslint-disable-next-line @typescript-eslint/camelcase
              communication_id: message.communication_id,
              // eslint-disable-next-line @typescript-eslint/camelcase
              response_type: message.response_type,
              message: message.message,
              // eslint-disable-next-line @typescript-eslint/camelcase
              files: message.attachments,
              responseId: message.responseId

            };
            ethitransService
              .createResponseChannelCommunication(payLoad)
              .then((res: any) => {
                if(res.data.data.responseId > 0){
                  removeUnsentMessageReply(res.data.data.responseId);
                }
              })
              .catch((error: any) => {
                console.log(error);
              })
              .finally(() => {
                state.threadImageList = [];
                state.data.replyMessage = ""
                state.ui.isPostLoading.replyMessage = false;
                scrollToLastThread();
                state.isReplyMessage = true;
              });
          });
        }
      }
    watch(
      // () => watchTour,
      () => store.state.online,
      (newvalue: any, oldvalue: any) => {
        if ((newvalue === 1) && (oldvalue === 0)) {
          sendPendingMessage();
        } else if ((newvalue === 0) && (oldvalue === 1)) {
        }
      }
    );
    function closeMessageReceiverModal() {
      state.ui.isActive.messageReceiver = !state.ui.isActive.messageReceiver;
    }
    function allMessageReceivers(members: any) {
      state.ui.isActive.messageReceiver = true;
      state.data.messageReceivers = members;

    }
    function masterTagListForValidation(data: boolean) {
      state.item.error.erorList = [];
      state.item.hasError = false;
      const payLoad = {
        type: data ? "enabled" : "disabled",
      };
      ethitransService
        .masterFileTagList(payLoad)
        .then((res: any) => {
          console.log("masterTagList Check:>>> ", res.data.data);
          // res.data.data.forEach((item: any) => {
          //   item.label = item.name;
          //   item.value = item.code;
          // })
          // state.data.tagListForValidation = res.data.data ? res.data.data : [];
          let disabledTagList: any = [];
          state.imageList.forEach((item: any) => {
            if (item.tag && item.tag.length) {
              item.tag.forEach((tag: any) => {
                // console.log("CHECK Tag >>",tag);
                res.data.data.forEach((data: any) => {
                  // console.log("CHECK THE API TAG >>>", data);

                  if (tag.toLowerCase() === data.name.toLowerCase()) {
                    data.inProgress = false;
                    // console.log("tag values >>> ",item )
                    // console.log("tagListForValidation  values >>> ", data.name)
                    disabledTagList.push(data);
                  }
                });
              });
            } else {
              disabledTagList = [];
            }

            //
          });
          const uniqueSet = Array.from(new Set(disabledTagList));
          // const tag: any = []
          state.data.disabledFileTagList = uniqueSet ? uniqueSet : [];

          if (
            state.data.disabledFileTagList &&
            !state.data.disabledFileTagList.length
          ) {
            state.data.showAddFileTag = true;
          }
          // state.data.disabledFileTagList.forEach((item: any)=> {
          // state.data.showAddFileTag = true

          // })
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    // messageSeenByMember
    function messageSeeen(messageId: any) {
      state.item.error.erorList = [];
      state.item.hasError = false;
      ethitransService
        .messageSeenByMember(messageId)
        .then((res: any) => {
          console.log("createMasterTag:>>> ", res.data.data);
          // if (res.data.data && res.data.data.is_active === 1) {
          //   state.data.tags.options.push(res.data.data);
          // } else {
          //   state.data.tags.options.push({ name: tag });
          //   //  masterTagListForValidation(false)
          // }
          //
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    function moveSenderToEnd(arr: any) {
      // Filter objects where sender is false
      const nonSenderObjects = arr.filter((item: any) => !item.sender);

      // Filter objects where sender is true
      const senderObjects = arr.filter((item: any) => item.sender);

      // Concatenate the non-sender objects with the sender objects at the end
      return [...nonSenderObjects, ...senderObjects];
    }
    function getUniqueIds(items: any): number[] {
      const uniqueIds = new Set<number>();

      items.forEach((item: any) => {
        uniqueIds.add(item.id);
      });
      // console.log("CHECKING ID>>> ", uniqueIds)

      return Array.from(uniqueIds);
    }
    function checkMessageStatus(items: any): number[] {
      const currentUserEmail = localStorage.getItem("current_user_email");
      const matchingIds = new Set<number>();

      if (!currentUserEmail) {
        console.warn("No current user email found in localStorage.");
        return [];
      }

      items.forEach((item: any) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        item.message_receivers = moveSenderToEnd(item.message_receivers);
        const hasMatchingReceiver = item.message_receivers.some((receiver: any) =>
          receiver.email === currentUserEmail && receiver.status === 1
        );

        if (hasMatchingReceiver) {
          matchingIds.add(item.id);
        }
      });

      return Array.from(matchingIds);
    }
    // function checkMessageStatus(items: any) {
    //   // messageDeliveryIds
    //   console.log("Check the Messages >>> ", items)
    // }
    // messageSeenByMember
    function messageSeenByMember(messges: any) {
      if (routeLeaveStatus.value) return false;
      messges = messges.filter((element: any) => {
        const oldMessage = state.communicationList.find((ls: any) => (ls.id == element));
        if (oldMessage.message_status == 2 || oldMessage.user_id == state.activeUserDetails.id) {
          return false;
        }
        if (oldMessage.message_receivers) {
          const receiverStatus = oldMessage.message_receivers.find((receiver: any) => (receiver.email == state.activeUserDetails.email));
          if ((receiverStatus != undefined) && (receiverStatus.status == 2)) {
            return false;
          }
        }

        return (!state.data.seenIds.includes(element));
      });
      if (messges.length == 0) {
        return false;
      } else {
        state.data.seenIds = state.data.seenIds.concat(messges);
      }
      state.item.error.erorList = [];
      state.item.hasError = false;
      state.ui.isPostLoading.messageSeenByMember = false;
      if (state.ui.isPostLoading.messageSeenByMember) return false;
      const payLoad = {
        ids: messges
      }
      state.ui.isPostLoading.messageSeenByMember = true;
      ethitransService
        .messageSeenByMember(payLoad)
        .then((res: any) => {
          state.data.unreadMessage = Math.max(0, state.data.unreadMessage - messges.length);
          state.ui.isPostLoading.messageSeenByMember = false;
          // 1564
          // console.log("channelMessageReceived :>>> ", res.data.data);
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    // messageReceivedByMember
    function messageReceived(messges: any) {
      if (routeLeaveStatus.value) return false;
      messges = messges.filter((element: any) => {
        const oldMessage = state.communicationList.find((ls: any) => (ls.id == element && ls.message_status > 0));
        return (!oldMessage && !state.data.deliveredIds.includes(element));
      });

      if (messges.length == 0) {
        return false;
      } else {
        state.data.deliveredIds = state.data.deliveredIds.concat(messges);
      }

      state.item.error.erorList = [];
      state.item.hasError = false;
      const payLoad = {
        // messages: getUniqueIds(messges)
        messages: messges
      }
      ethitransService
        .channelMessageReceived(payLoad)
        .then((res: any) => {
          // 1564
          console.log("channelMessageReceived :>>> ", res.data.data);
          // messageSeenByMember(9)
          // setTimeout(() => {
          //   messges.forEach((item: any) => {
          //     messageSeenByMember(item);
          //   });
          // }, 2000);
          console.log("MESSAGE ID FOR SEEN WHICH IS DELIVERED >>> ", checkMessageStatus(state.communicationList))
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }

    function addTag(tag: any) {
      state.item.error.erorList = [];
      state.item.hasError = false;
      // console.log("Check the New Tag >>>", tag);
      const payLoad = {
        name: tag,
      };
      // state.postLoading = true;
      ethitransService
        .createMasterFileTag(payLoad)
        .then((res: any) => {
          console.log("createMasterTag:>>> ", res.data.data);
          if (res.data.data && res.data.data.is_active === 1) {
            state.data.tags.options.push(res.data.data);
          } else {
            state.data.tags.options.push({ name: tag });
            //  masterTagListForValidation(false)
          }
          //
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const masterTags = computed(() => {
      const array: any = [];
      state.data.tags.options.forEach((item: any) => {
        array.push(item.name);
      });

      return array;
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const channelStatus = computed(() => {
      let status = false;
      // console.log("check Project Ids", state.channel)
      if (state.channel && state.channel && state.channel.status) {
        if((state.channel.created_by_id == state.activeUserDetails.id) && state.channel.status.code === "active" ){
          status = true;
        }
        else if (
          state.channel.status.code === "active" &&
          common.daysEvalutor(state.data.projectMember.validity_date) === true
        ) {
          status = true;
        }
      }

      return status;
    });

    // eslint-disable-next-line vue/return-in-computed-property
    const readyToUploadImage = computed(() => {
      // if (localStorage.getItem("chat-msg")) {
      // }
      console.log("IMAGE LIST CHECK >>> ");
      return state.imageList;
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const communicationList = computed(() => {
      // if (localStorage.getItem("chat-msg")) {
      // }

      return state.messages;
    });
    function onEditorReady(editor: any) {
      editor.model.change((writer: any) => {
        writer.setSelection(editor.model.document.getRoot(), "end");
      });
      editor.editing.view.focus();
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const templateList = computed(() => {
      if (state.formList.docs && state.formList.docs.length) {
        return state.formList.docs.filter((form: any) => {
          // if (form.title) {
          //   return form.title.toLowerCase().match(state.searchBy.toLowerCase());
          return form;
          // }
        });
      } else {
        return [];
      }
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const selectedForm = computed(() => {
      if (
        state.selectedFormForEdit.elements &&
        state.selectedFormForEdit.elements.length
      ) {
        return state.selectedFormForEdit.elements.filter((form: any) => {
          // console.log("Check SelectForm >>>", form);
          return form;
        });
      }
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const formPrivacyMessage = computed(() => {
      if (state.newFormData.privacy.value === "private to this channel") {
        return "This form will remain private to this channel. Form will not be visible to other channels of this project.";
      } else if (
        state.newFormData.privacy.value === "share within this project"
      ) {
        return " This form will be shared in this project so that other channels of this project can utilize this form, but will not be shared with other projects.";
      } else {
        return "This form will be shared to public.";
      }
    });
    // eslint-disable-next-line vue/return-in-computed-property
    const isUserStatus = computed(() => {
      if (state.userRole) {
        if (state.userRole.level === 1 || state.userRole.level === 2) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });

    //  watch(channelId, (channelId, prevChannelId)=> {
    //    console.log( "Channel ID check", channelId);
    //    console.log( "Channel previous ID check", prevChannelId);
    //   connect()
    // })

    // methods

    function updateCommunicationList(messages: any) {
      state.communicationList = messages.sort((a: any, b: any) => a.id - b.id);
      if (state.communicationList.length > 0) {
        if (state.communicationList[state.communicationList.length - 1].id > state.data.latestMessageId) {
          state.data.latestMessageId = state.communicationList[state.communicationList.length - 1].id;
        }
      }
    }
    // communication card code here
    function hideDeleteSelectedImage() {
      state.selectedAttachment = {};
      state.selectedForDelete = {};
      $("#deleteSelectedAttachment").modal("hide");
    }
    function selectedDocumentToDelete(
      communication: any,
      sharedDocument: any,
      document: any
    ) {
      state.selectedAttachment = document;
      state.selectedForSharedDelete = sharedDocument;
      state.selectedForDelete = communication;
      $("#deleteSelectedAttachment").modal("show");
      // console.log("CHECK SHAREDDOC >>",state.selectedAttachment )
      // console.log("Communication Index >> ", state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)])
      // console.log("Shared Selected >>> ", state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history[common.findIndex(state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history, state.selectedForSharedDelete)])
      // console.log("Shared Index >>>", common.findIndex(state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history, sharedDocument))
      // state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history[common.findIndex(common.findIndex(state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history, sharedDocument))].documents
      // state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history[common.findIndex(state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history, state.selectedForSharedDelete)].documents

      // state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history[common.findIndex(state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history, state.selectedForSharedDelete)].documents = state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history[common.findIndex(state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)].share_history, state.selectedForSharedDelete)].documents.filter(
      //         (item: any, index: any) => item != state.selectedAttachment
      //       );
    }
    function deleteSharedDocument() {
      // console.log("Selected Communication >>>", communication)
      // console.log("Selected SharedDocument >>>", sharedDocument)
      // console.log("Selected Document for Delete >>>", document)
      // console.log("Selected Document for Delete ID>>>", document.file_id)

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        shared_file_id: state.selectedAttachment.id,
      };
      state.ui.isPostLoading.selectedDocumentToDelete = true;
      ethitransService
        .selectedSharedFileToDelete(payLoad)
        .then((res: any) => {
          console.log(
            "selectedSharedFileToDelete Response Check:>>> ",
            res.data.data
          );
          state.communicationList[
            common.findIndex(state.communicationList, state.selectedForDelete)
          ].share_history[
            common.findIndex(
              state.communicationList[
                common.findIndex(
                  state.communicationList,
                  state.selectedForDelete
                )
              ].share_history,
              state.selectedForSharedDelete
            )
          ].documents[
            common.findIndex(
              state.communicationList[
                common.findIndex(
                  state.communicationList,
                  state.selectedForDelete
                )
              ].share_history[
                common.findIndex(
                  state.communicationList[
                    common.findIndex(
                      state.communicationList,
                      state.selectedForDelete
                    )
                  ].share_history,
                  state.selectedForSharedDelete
                )
              ].documents,
              state.selectedAttachment
            )
          ] = res.data.data;
          // ].documents.filter(
          //   (item: any, index: any) => item != state.selectedAttachment
          // );
          // state.communicationList[common.findIndex(state.communicationList, state.selectedForDelete)]
          hideDeleteSelectedImage();
          state.selectedForSharedDelete = {};
          // state.data.formData = res.data ? res.data : {};
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.selectedDocumentToDelete = false;
        });
    }
    function dragOverHandler(ev: any) {
      // console.log('File(s) in drop zone', ev);
      // Prevent default behavior (Prevent file from being opened)
      // ev.preventDefault();
    }
    function backInvitation() {
      state.data.invitationVerify.showStatus =
        !state.data.invitationVerify.showStatus;
    }
    function openPdf(pdfLink: any) {
      window.open(pdfLink);
    }
    function showHideCalender(item: any) {
      item.show = !item.show;
    }
    // messageSeenByMember
    function channelMessageReplySeenByMember(messges: any) {
      if (routeLeaveStatus.value) return false;
      // seen code here
      messges = messges.filter((element: any) => {
        const oldMessage = state.data.selectedReplyUser.responses.find((ls: any) => (ls.id == element));
        if (oldMessage.message_status == 2 || oldMessage.response_by_id == state.activeUserDetails.id) {
          return false;
        }
        const receiverStatus = oldMessage.message_receivers.find((receiver: any) => (receiver.email == state.activeUserDetails.email));
        if ((receiverStatus != undefined) && (receiverStatus.status == 2)) {
          return false;
        }
        return (!state.data.seenThreadIds.includes(element));
      });
      if (messges.length == 0) {
        return false;
      } else {
        state.data.seenThreadIds = state.data.seenThreadIds.concat(messges);
      }
      // ends here
      state.item.error.erorList = [];
      state.item.hasError = false;
      state.ui.isPostLoading.channelMessageReplySeenByMember = false;
      if (state.ui.isPostLoading.channelMessageReplySeenByMember) return false;
      const payLoad = {
        ids: messges
      }
      state.ui.isPostLoading.channelMessageReplySeenByMember = true;
      ethitransService
        .channelMessageReplySeenByMember(payLoad)
        .then((res: any) => {
          // state.data.unreadMessage = Math.max(0, state.data.unreadMessage - messges.length);
          state.ui.isPostLoading.channelMessageReplySeenByMember = false;
          // 1564
          // console.log("channelMessageReceived :>>> ", res.data.data);
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }
    // new thred code test
    const getVisibleDivIdForReplyThread = () => {
      if (!chatReplyThreadBody.value) return []; // Safeguard

      const chatBodyHeight = chatReplyThreadBody.value.clientHeight; // Height of the container
      const scrollTop = chatReplyThreadBody.value.scrollTop; // Current scroll position
      const scrollBottom = scrollTop + chatBodyHeight; // Bottom of visible area

      // Filter visible <div> elements
      const visibleDivs = Array.from(chatReplyThreadBody.value.querySelectorAll('div')).filter((div: any) => {
        if (!div.id || !div.id.startsWith('reply-') || !div.classList.contains('chat-block')) {
          return false; // Skip if conditions aren't met
        }

        const liRect = div.getBoundingClientRect();
        const containerRect = chatReplyThreadBody.value.getBoundingClientRect();

        // Relative positions within the scroll container
        const liTopRelative = liRect.top - containerRect.top;
        const liBottomRelative = liTopRelative + liRect.height;

        return liBottomRelative > 0 && liTopRelative < chatBodyHeight; // Element is visible
      });

      // Map to IDs and extract the numeric part
      const visibleIds = visibleDivs.map((div: any) => {
        const id = div.id.replace('reply-', ''); // Remove the "reply-" prefix
        return parseInt(id, 10); // Convert to number
      }).filter((id: number) => id > 0); // Filter out 0 or any falsy values

      // Debugging
      // console.log("Visible IDs:", visibleIds);

      return visibleIds; // Return visible IDs
    };
    // function scrollToLastMessageUpdate() {
    //   $("#chat-body-content").animate(
    //     { scrollTop: $("#chat-body-content")[0].scrollHeight },
    //     1000
    //   );

    //   const element = $("#chat-body-content");

    //   // Smoothly scroll to the bottom
    //   element.animate({ scrollTop: element[0].scrollHeight }, 1000);

    //   // Continuously check until the element reaches the bottom
    //   const checkInterval = setInterval(() => {
    //     if (hasReachedMaxScroll()) {
    //       // console.log("Reached the bottom!");

    //       // Stop the interval
    //       clearInterval(checkInterval);

    //       // Execute future logic
    //       // if(state.notAllowdedThreadSeen) return false;
    //       if (!state.data.replyClassStatus) return false;
    //       channelMessageReplySeenByMember(getVisibleDivIdForReplyThread());
    //     } else {
    //       console.log("Still scrolling...");
    //     }
    //   }, 200); // Check every 200ms
    // }
    function getIdsByEmailMismatch(responses: any) {
      return responses
        .filter((response: any) => response.response_by.email !== state.activeUserDetails.email)
        .map((response: any) => response.id);
    }


    function scrollToLastThread() {
      $("#thread-chat-body-content").animate(
        { scrollTop: $("#thread-chat-body-content")[0].scrollHeight },
        1000
      );

      const element = $("#thread-chat-body-content");

      // Smoothly scroll to the bottom
      element.animate({ scrollTop: element[0].scrollHeight }, 1000);

      // Continuously check until the element reaches the bottom
      // const checkInterval = setInterval(() => {
      //   if (hasReachedMaxScroll()) {
      //     // console.log("Reached the bottom!");

      //     // Stop the interval
      //     clearInterval(checkInterval);

      //     // Execute future logic
      //     // skip for the reply sender
      //     if (state.currentActive.replySend) return false;
      //     console.log("FINAL IDS IN Thread >>>>", getVisibleDivIdForReplyThread())
      //     const scrolledIds: any = getVisibleDivIdForReplyThread();
      //     const matchIds: any = getIdsByEmailMismatch(state.data.selectedReplyUser.responses)

      //     if (scrollTimeoutThread.value) clearTimeout(scrollTimeoutThread.value);

      //     // Set a new timeout to detect when scrolling stops
      //     scrollTimeoutThread.value = setTimeout(() => {
      //       // Triggered when scroll has stopped

      //       channelMessageReplySeenByMember(scrolledIds.filter((element: any) => matchIds.includes(element)));
      //       // onScrollStopped();
      //     }, 200);
      //   } else {
      //     console.log(" thread Still scrolling...");
      //   }
      // }, 200); // Check every 200ms



      // if(state.data.isCommunicationReplyFile){
      //  const replyId: any = $(".chat mw-100").attr("id");
      //  if (replyId != null) {
      //     const element: any = document.getElementById(replyId);
      //     element.scrollIntoView();
      //   }
      // }else{
      //  $("#thread-chat-body-content").animate(
      //   { scrollTop: $("#thread-chat-body-content")[0].scrollHeight },
      //   1000
      // );
      // }
    }

    async function uploadReplyAttachmentByChannel(selectedChannelId: number,
      responseId: any,
      allFile: any
    ) {
      const fileList = [] as any;
      allFile.forEach((item: any) => {
        console.log("CHECK IMAGE LIST", item.tag);
        const tagIds = [] as any;
        const fileData = {} as any;
        if (item.isvalidToUpload) {
          if (item.tag && item.tag.length) {
            state.data.tags.options.forEach((tagItem: any) => {
              item.tag.forEach((dataItem: any) => {
                if (tagItem.name.toLowerCase() === dataItem.toLowerCase()) {
                  tagIds.push(tagItem.id);
                }
              });
            });
          }
          // isUploadedFile = true;
          console.log("im form if")
          fileData["fileName"] = item.fileName;
          fileData["base64"] = item.base64;
          fileData["extension"] = item.extension;
          fileData["item"] = item.item;
          fileData["master_file_tag_id"] = tagIds;
          fileList.push(fileData);
        } else {
          console.log("im form else")
          state.postLoading = false;
          state.threadImageList = state.threadImageList.filter((e: any) => e != item);
        }
      });

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_id: selectedChannelId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        response_id: responseId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        files: fileList
      };
      // console.log("PAYLOAD CHECK >>", state.imageList[isuplodedIndex]);
      state.postLoading = true;

      // state.imageList[isuplodedIndex].isuploded = true;
      return await new Promise((resolve, reject) => {
        ethitransService
          .uploadReplyAttachment(payLoad)
          .then((res: any) => {
            console.log("<<<IMAGE upload API Check:>>> ", res.data.data);
            if (
              res.data.data.messages && res.data.data.messages.data &&
              res.data.data.messages.data.length
            ) {
              res.data.data.messages.data.forEach((data: any) => {
                data.allowEdit = false;
                if (data.attachments && data.attachments.length) {
                  data.attachments.forEach((attachment: any) => {
                    attachment.convertedFileSize = common.formatBytes(
                      parseInt(
                        attachment.file_size
                        // parseInt(data.file_size.split(" ")[0])
                      )
                    );
                  });
                }
              });
            }
            if (res.data.data.messages &&
              res.data.data.messages.data &&
              res.data.data.messages.data.length
            ) {
              res.data.data.messages.data.forEach((item: any) => {
                if (item.user && item.user.email) {
                  if (
                    item.user.email ===
                    localStorage.getItem("current_user_email")
                  ) {
                    // console.log("email", item.user.email);
                    // console.log("active email", state.data.activeUserEmail);
                    item.editStatus = true;
                  } else {
                    item.editStatus = false;
                  }
                }
              });
            }
            res.data.data.messages.data.forEach((data: any) => {

              if (data.attachments) {
                data.attachments.forEach((video: any) => {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  if (video.file_type === "mov" || video.file_type === "MOV") {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    video.file_type = "mp4";
                  }
                });

                // console.log("Data Check >>> ", data);
              }
            });


            if (state.threadImageList && state.threadImageList.length) {
              // state.imageList[isuplodedIndex].isuploded = false;
            }
            // state.imageList = state.imageList.filter(
            //   // (e: any) => e.base64 != attachment.base64
            // );
            resolve(true);
          })
          .catch((error: any) => {
            console.log(error);
            reject(false);
          })
          .finally(() => {
            if (state.threadImageList && !state.threadImageList.length) {
              state.postLoading = false;
            }
            // state.postLoading = false;
            //  state.imageList[isuplodedIndex].isuploded = false;
            state.threadImageList = [];
          });
      });
    }
    function temporaryReplyMessage(responseId: any, replyMessage: string, images: any) {
      const attachments: any = [];
      if (images && images.length) {
        images.forEach((image: any) => {
          attachments.push(

            {
              "file_name": image.fileName,
              "file_type": image.extension,
              "file_size": image.size,
              "convertedFileSize": common.formatBytes(parseInt(image.size)),
              "link": image.base64,
              "created_at": common.getCurrentDateTimeISO(),
              "updated_at": common.getCurrentDateTimeISO(),
              "deleted_at": null,
              "file_link": image.base64
            }
          )

        })
      }


      const message = {
        // selected message for Reply ko Id
        "tempId": 0,
        "responseId": responseId,
        "messageSendStatus": true,
        "communication_id": state.data.selectedReplyUser.id,
        "response_by_id": state.activeUserDetails.id,
        "response_type": "text",
        "message": replyMessage,
        "file_name": null,
        "file_type": null,
        "file_size": null,
        "link": null,
        "sent_date": common.getCurrentDateTimeISO(),
        "sentDate": common.localDateAndTime(common.getCurrentDateTimeISO()),
        "created_at": common.getCurrentDateTimeISO(),
        "updated_at": common.getCurrentDateTimeISO(),
        "deleted_at": "",
        "lang": "en",
        // "file_link": "http:\/\/localhost:8000\/",
        "is_edited": 0,
        "response_by": state.activeUserDetails,
        "attachments": attachments,
      }
      saveUnsentMessageReply(message);
      state.data.selectedReplyUser.responses.push(message);
    }

    function scrollToLast() {
      if (state.isManualScroll) {
        return true;
      }
      if (!state.firstLoad) {
        state.firstLoad = false;
        return true;
      }
      if (state.data.isCommunicationFile) {
        const domId: any = $(".chat-block.active").attr("id");
        if (domId != null) {
          const element: any = document.getElementById(domId);
          element.scrollIntoView();
        }
      } else {
        if (state.messageStatus) {
          setTimeout(() => {
            $("#chat-body-content").animate(
              {
                scrollTop: $("#chat-body-content")[0].scrollHeight + 900,
              },
              1
            );

            setTimeout(() => {
              state.messageStatus = false;
            }, 500);
            state.fileLoadTime = 30;
          }, state.fileLoadTime);
        } else {
          $("#chat-body-content").animate(
            {
              scrollTop: $("#chat-body-content")[0].scrollHeight,
            },
            1000
          );
        }
      }
    }

    function setMentionMembers(channelMember: any) {
      store.dispatch("saveActiveMembers", channelMember);
    }
    function addChannelMember(member: any) {
      const index = state.memberList.findIndex((obj: any) => obj.id === member.id);
      if (index !== -1) {
        // Replace the existing object
        state.memberList[index] = member;
      } else {
        // Add the new object
        state.memberList.push(member);
      }
      const channelMember: any = [];
      state.memberList.forEach((li: any) => {
        channelMember.push(li.user.full_name);
      });
      setMentionMembers(channelMember);

    }
    function deleteChannelMember(userId: number){
        state.memberList = state.memberList.filter((receiver: any) => receiver.user_id !== (userId));
        const channelMember: any = [];
        state.memberList.forEach((li: any) => {
          channelMember.push(li.user.full_name);
        });
        setMentionMembers(channelMember);
    }
    function updateUnReadCount() {
      const newLength = state.communicationList.filter(
        (item: any) => ((item.user_id != state.activeUserDetails.id) && ((item.message_status === 0 || item.message_status === 1)))
      ).length;
      state.data.unreadMessage = newLength;
    }
    function addMessage(data: any) {
      console.log("CHECK Web Socket Add MSG >>> ", data);
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (data.sent_date) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        data.currentTime = common.localDateAndTime(data.sent_date)
      }
      if (data.user_id === state.activeUserDetails.id) {
        data.editStatus = true;
      } else {
        data.editStatus = false;
      }
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (data.deleted_at) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        data.deletedLocalTime = data.deleted_at;
      }
      const memberList: any = (state.memberList.length > 0) ? state.memberList : [];
      if (memberList) {
        let user = memberList ? memberList.find((member: any) => {
          return (member.user_id == data.user_id);
        }).user : {};
        // eslint-disable-next-line @typescript-eslint/camelcase
        user = { email: user.email, image: user.image, full_name: user.first_name + " " + user.last_name };
        // eslint-disable-next-line @typescript-eslint/camelcase
        data.message_receivers = data.message_receivers ? data.message_receivers.filter((receiver: any) => receiver.email !== state.activeUserDetails.email) : [];
        if (data.attachments && data.attachments.length) {
          data.attachments.forEach((attachment: any) => {
            attachment.convertedFileSize = common.formatBytes(parseInt(attachment.file_size));
          })
        }
        data.user = user;

        const message: any = data;
        if (data.user_id == state.activeUserDetails.id) {
          const index = state.communicationList.findIndex((obj: any) => {
            return obj.messageId == data.messageId;
          });
          if (index !== -1) {
            state.communicationList[index] = message; // Replace the object
          } else {
            const index = state.communicationList.findIndex((obj: any) => obj.id === message.id);
            if (index !== -1) {
              state.communicationList[index] = message;
            } else {
              // Add the new object
              // console.log("Check the LIST >>> 0", message)
              if (message.id > state.communicationList[state.communicationList.length - 1].id) {
                // message.newMessage = true;
                state.communicationList.push(message);
                // updateCommunicationList([]);
              }

            }
          }

        } else {
          if (message.message_status == 0) {
            messageReceived([data.id]);
          }
          const index = state.communicationList.findIndex((obj: any) => obj.id === message.id);
          if (index !== -1) {
            // Replace the existing object
            state.communicationList[index] = message;
          } else {
            // Add the new object
            if ((state.communicationList.length == 0) || (message.id > state.communicationList[state.communicationList.length - 1].id)) {
              // message.newMessage = true;
              state.communicationList.push(message);
              // updateCommunicationList([])
            }
          }
        }

        // if reply thread is open 

        if (state.data.selectedReplyUser && (state.data.selectedReplyUser.id == message.id)) {
          if (state.data.selectedReplyUser.responses.length > 0) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            const own_reply_new = message.responses.filter(function (reply: any) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              return (reply.response_by_id == parseInt(localStorage.current_user_id));
            });
            // eslint-disable-next-line @typescript-eslint/camelcase
            const own_reply_old = state.data.selectedReplyUser.responses.filter(function (reply: any) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              return (reply.response_by_id == parseInt(localStorage.current_user_id));
            });
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (own_reply_new.length == own_reply_old.length) {
              state.data.selectedReplyUser.responses = message.responses;
            } else if (state.data.selectedReplyUser.responses.length != message.responses.length) {
              // state.data.selectedReplyUser.responses[state.data.selectedReplyUser.responses.length -1].messageSendStatus = false;
              // const tempIndex = state.data.selectedReplyUser.responses.findIndex((item: any) => item.tempId === 0);
              // console.log("check the Temp Id >>> ", tempIndex);
              // state.data.selectedReplyUser.responses = state.communicationList[index].responses;
              console.log("REPLY CHECK THE MESSAGE >>> ", message.responses)
              // const responses = message.responses;
              // responses.push(state.data.selectedReplyUser.responses[state.data.selectedReplyUser.responses.length - 1]);
              state.data.selectedReplyUser.responses = state.data.selectedReplyUser.responses.filter((item: any) => item.tempId !== 0)
              state.data.selectedReplyUser.responses.push(message.responses[message.responses.length - 1])
              state.data.selectedReplyUser.responses.forEach((item: any) => {
                item.sentDate = common.localDateAndTime(item.sent_date)
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.deleted_at = item.deleted_at ? common.localDateAndTime(item.deleted_at) : "";
              })
            }


            //reply thread check and scroll at last
            // scrollToLastThread();
          }
        }

      }
      // was set to scroll last in the chat message
      if (state.newMessageStatus) {

        // setTimeout(scrollToLast, 100);
      }
      // updating latestMessageId
      if (data.id > 0) {
        if (state.data.latestMessageId < data.id) {
          state.data.latestMessageId = data.id;
        }
      }
      updateUnReadCount();
      return false;
    }

    function setSocket() {
      socket = getSocket();
      if (socket.connected) {
        socket.emit('join_room', config.apiBaseUrl + channelId);
        socket.on('room_message', (data: any) => {
          if (routeLeaveStatus.value) return false;
          if (data.type && data.type == 'member_added') {
            addChannelMember(data.data);
            state.pendingList = state.pendingList.filter((receiver: any) => receiver.email !== (data.data.user.email));
            state.pendingMembersCount = state.pendingList.length;
          }
          else if (data.type && data.type == 'message') {
            addMessage(data.data);
          }else if (data.type && data.type == 'user_removed') {
            if(state.activeUserDetails.id == data.data.user_id){
              router.push({
                name: "admin-project-details",
                params: { projectId: state.projectId },
              });
            }else{
              deleteChannelMember(data.data.user_id);
            }
            
          }
        });

      } else {
        // store.dispatch("saveSocketStatus", 2);
      }
    }

    function replyMessage() {
      if (!(socket && socket.connected)) {
        setSocket();
        // return false;
      }
      state.currentActive.replySend = true;
      const responseId = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
      // temporaryReplyMessage(state.data.replyMessage,fileList);
      // // sendReplyMsg
      const fileList = [] as any;
      if (state.threadImageList.length > 0) {
        state.threadImageList.forEach((item: any) => {
          const tagIds = [] as any;
          const fileData = {} as any;
          if (item.isvalidToUpload) {
            if (item.tag && item.tag.length) {
              state.data.tags.options.forEach((tagItem: any) => {
                item.tag.forEach((dataItem: any) => {
                  if (tagItem.name.toLowerCase() === dataItem.toLowerCase()) {
                    tagIds.push(tagItem.id);
                  }
                });
              });
            }
            console.log("SIZE CHECK >>> ", item.size)
            fileData["size"] = item.size;
            fileData["fileName"] = item.fileName;
            fileData["base64"] = item.base64;
            fileData["extension"] = item.extension;
            fileData["item"] = item.item;
            fileData["master_file_tag_id"] = tagIds;
            fileList.push(fileData);
            temporaryReplyMessage(responseId, state.data.replyMessage, fileList);
          } else {
            temporaryReplyMessage(responseId, state.data.replyMessage, []);
            console.log("im form else")
            state.postLoading = false;
            state.threadImageList = state.threadImageList.filter((e: any) => e != item);
          }
        });
      } else {
        temporaryReplyMessage(responseId, state.data.replyMessage, []);
        console.log("im form else")
        state.postLoading = false;
      }

      if (!state.data.replyMessage && !state.threadImageList.length) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_id: state.data.selectedReplyUser.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        response_type: "text",
        message: state.data.replyMessage.replace(/\r?\n/g, " <br>"),
        // eslint-disable-next-line @typescript-eslint/camelcase
        files: fileList,
        responseId: responseId

      };
      state.ui.isPostLoading.replyMessage = true;
      state.data.replyMessage = "";
      state.threadImageList = [];
      ethitransService
        .createResponseChannelCommunication(payLoad)
        .then((res: any) => {
          if (res.data.data.responseId > 0) {
            removeUnsentMessageReply(res.data.data.responseId);
          }
          // res.data.data.createdLatest = common.localDateAndTime(
          //   res.data.data.created_at
          // );

          // res.data.data.forEach((data: any) => {
          //   // eslint-disable-next-line @typescript-eslint/camelcase
          //   data.sentDate = common.localDateAndTime(data.sent_date);
          // });

          // state.data.selectedReplyUser.responses = res.data.data;
          // state.data.replyMessage = "";
          // // eslint-disable-next-line @typescript-eslint/camelcase
          // state.data.selectedReplyUser.responses_count =
          //   state.data.selectedReplyUser.responses.length;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.threadImageList = [];
          state.data.replyMessage = ""
          state.ui.isPostLoading.replyMessage = false;
          scrollToLastThread();
          state.isReplyMessage = true;
        });
      // }
    }
    function viewFormRetrieveByIdForSend(formId: any) {
      state.data.formData = {};
      if (state.ui.isPostLoading.getAccountFormRetrieveById) return false;
      // console.log("Get Account form Response:>>>", formId);
      state.ui.isPostLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          console.log("FORM VIEW");
          // console.log(
          //   "getAccountFormRetrieveById Response Check:>>> ",
          //   res.data.elements
          // );
          state.data.formData = res.data ? res.data : {};
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getAccountFormRetrieveById = false;
        });
    }

    function hideViewTemplateModal() {
      $("#showFormViewModal").modal("hide");
    }
    const channelClosedCheckNotification = () => {
      if(!channelStatus.value){
        alert("Channel's Closed !!!")
      }
    }
    function viewSelectSharedDocuments(document: any) {
      if(!channelStatus.value){
        channelClosedCheckNotification();
      }
      if(!channelStatus.value) return false;
      if (document.file_url) {
        window.open(document.file_url, "_blank");
      }
      if (document.file_link) {
        window.open(document.file_link, "_blank");
      }
    }
    
    function viewSelectForm(form: any) {
      if(!channelStatus.value){
        channelClosedCheckNotification();
      }
      if(!channelStatus.value) return false;
      $("#showFormViewModal").modal("show");
      console.log("Selected Form:>>", form);
      viewFormRetrieveByIdForSend(form._id);
    }

    function removeSelectedCommunicationImageAttached() {
      // function removeSelectedCommunicationImage(imagetoDelete: any) {
      console.log("Selected Image >>>> ", state.selectedAttachment);
      const selectedRowIndex = common.findIndex(
        state.communicationList,
        state.selectedForDelete
      );
      const selectedIndex = common.findIndex(
        state.communicationList[selectedRowIndex].attachments,
        state.selectedAttachment
      );
      console.log("Selected Row INDEX >>>", selectedRowIndex);
      // console.log("Image >>>", state.communicationList[selectedRowIndex].attachments)
      console.log("Image INDEX >>>", selectedIndex);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_attachment_id: state.selectedAttachment.id,
        // communication_attachment_id: state.selectedAttachment.communication_id
      };
      state.ui.isPostLoading.removeSelectedCommunicationImage = true;
      ethitransService
        .deleteAttachment(payLoad)
        .then((res: any) => {
          console.log("deleteAttachment Check:>>> ", res.data.data);
          state.communicationList[selectedRowIndex].attachments[selectedIndex] =
            res.data.data;
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.communicationList[selectedRowIndex].attachments[selectedIndex].deleted_at = common.localDateAndTime(common.getCurrentDateTimeISO());
          // state.communication.attachments = state.communication.attachments.filter(
          //   (item: any) => item != imagetoDelete
          // );
          // state.projectList = res.data.data.projects
          //   ? res.data.data.projects
          //   : [];
          // state.selectedFormForEdit = res.data ? res.data : {};
          // hideDeleteSelectedImage()
          hideDeleteSelectedImage();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.removeSelectedCommunicationImage = false;
        });
    }

    function deleteReplyImage(response: any, document: any) {
      state.selectedResponseAttachment = response;
      state.selectedResponseDelete = document;
      $("#deleteReplySelectedAttachment").modal("show");

    }

    function hideDeleteReplySelectedImage() {
      state.selectedResponseAttachment = {};
      state.selectedResponseDelete = {};
      $("#deleteReplySelectedAttachment").modal("hide");

    }
    function removeSelectedReplyCommunicationImageAttached() {

      const selectReponse = common.findIndex(state.data.selectedReplyUser.responses, state.selectedResponseAttachment);
      console.log(selectReponse, 'om')
      const selectedIndex = common.findIndex(state.data.selectedReplyUser.responses[selectReponse].attachments, state.selectedResponseDelete);
      console.log(selectedIndex, 'om2')

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        id: state.selectedResponseDelete.id,
        // communication_attachment_id: state.selectedAttachment.communication_id
      };
      state.ui.isPostLoading.removeSelectedCommunicationImage = true;
      ethitransService
        .deleteReplyAttachementCommunication(payLoad)
        .then((res: any) => {
          const deleteDate = new Date();
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.selectedReplyUser.responses[selectReponse].attachments[selectedIndex].deleted_at = common.localDateAndTime(deleteDate.toISOString());

        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.removeSelectedCommunicationImage = false;
          hideDeleteReplySelectedImage();
        });
    }

    function removeSelectedCommunicationImage() {
      if (state.selectedAttachment.file_name) {
        removeSelectedCommunicationImageAttached();
      } else {
        deleteSharedDocument();
      }
    }
    function viewPdf(pdfLink: any) {
      state.selectedPdf = pdfLink;
      window.open(pdfLink);
    }

    function downloadFile(pdfLink: any) {
      state.selectedPdf = pdfLink;

      window.open(pdfLink, "_blank");
    }

    function selctedCommunication(selected: any) {
      state.data.typeEditDisabled = false;
      state.data.selectedChatIndex = 0;
      state.selectedForEdit = {};
      state.data.selectedChatIndex = common.findIndex(
        state.communicationList,
        selected
      );
      console.log(
        "Selected Communication  INDEX>>> ",
        state.data.selectedChatIndex
      );
      // eslint-disable-next-line @typescript-eslint/camelcase
      // selected.is_edited = !selected.is_edited;
      selected.allowEdit = true;
      state.selectedForEdit = JSON.parse(JSON.stringify(selected));
    }
    function selctedReplyCommunication(selected: any) {
      console.log("edit", selected);
      state.data.typeEditDisabled = false;
      state.data.selectedChatIndex = 0;
      state.selectedForEdit = {};
      state.data.selectedChatIndex = state.communicationList.forEach((message: any) => {
        message.responses.forEach((resp: any, respIndex: any) => {
          console.log("match", resp.id, selected.id)
          if (resp.id === selected.id) {
            return respIndex;
          }
        });
      })
      selected.allowEdit = true;
      console.log("edit", state.data.selectedChatIndex);
      state.selectedForEdit = JSON.parse(JSON.stringify(selected));
    }
    function hideDeleteCommunicationModal() {
      state.item.hasError = false;
      state.item.error.deleteCommunication = [];
      $("#removecommunication").modal("hide");
    }
    function selctedCommunicationForDelete(selected: any) {
      state.item.hasError = false;
      state.item.error.deleteCommunication = [];
      state.selectedForEdit = {};
      $("#removecommunication").modal("show");
      // console.log("Selected Communication  WOWOWO>>> ", selected);
      // eslint-disable-next-line @typescript-eslint/camelcase
      // selected.is_edited = !selected.is_edited;
      selected.allowDelete = true;
      state.selectedForEdit = JSON.parse(JSON.stringify(selected));
    }

    //remove reply message
    function hideDeleteReplyMessage() {
      state.item.hasError = false;
      state.item.error.deleteCommunication = [];
      $("#removeReplyConverstion").modal("hide");
    }
    function deleteReplyMessage(selected: any) {
      state.item.hasError = false;
      state.item.error.deleteCommunication = [];
      state.selectedForEdit = {};
      $("#removeReplyConverstion").modal("show");
      selected.allowDelete = true;
      state.selectedForEdit = JSON.parse(JSON.stringify(selected));
    }

    function communicationDetails(selcted: any, image: any) {
      state.item.hasError = false;
      ethitransService
        .communicationDetails(selcted.id)
        .then((res: any) => {
          if (!image) {
            state.data.forwardMessage = res.data.data.communcitation;
            if (res.data.data.communcitation.share_history != undefined) {
              if (res.data.data.communcitation.share_history.length > 0) {
                res.data.data.communcitation.share_history.map((shareDocuments: any) => {
                  shareDocuments.documents.map((document: any) => {
                    // debugger;
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    state.data.forwardMessage.attachments.push({ file_type: document.file.filetype, file_link: document.file.file_url, file_name: document.file.filename });
                  });
                });
              }
            }
          } else {
            state.data.forwardMessage = res.data.data.communcitation;
            state.data.forwardMessage.message = "";
            state.data.forwardMessage.attachments = res.data.data.communcitation.attachments.filter((e: any) => e.id == image.id);

          }
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.deleteCommunication)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.forwardCommunication.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.deleteCommunication = false;
        });
    }

    function hideForwardMessage() {
      state.item.hasError = false;
      state.item.error.forwardCommunication = [];
      state.data.forwardWriteMessage = "";
      $("#forwardCommunication").modal("hide");
    }

    function forwardMessage(selected: any, image: any) {
      communicationDetails(selected, image);
      state.item.hasError = false;
      state.item.error.forwardCommunication = [];
      state.selectedForEdit = {};
      $("#forwardCommunication").modal("show");
      selected.allowDelete = true;
      state.selectedForEdit = JSON.parse(JSON.stringify(selected));
    }

    function temporaryMessge(messageId: number, message: string, imageList: any) {
      const attachamentCollection: any = [];
      if (imageList && imageList.length) {
        imageList.forEach((image: any) => {
          attachamentCollection.push({
            "file_name": image.fileName,
            "file_type": image.extension,
            "convertedFileSize": image.size,
            "file_size":image.size,
            "link": image.base64,
            "status_id": 1,
            "status_by_id": null,
            "status_date": common.getCurrentDateTime24HourFormat,
            "created_at": common.getCurrentDateTime24HourFormat,
            "updated_at": common.getCurrentDateTime24HourFormat,
            "deleted_at": null,
            "file_link": image.base64,
            "status": {
              "id": 1,
              "code": "active",
              "name": "Active",
              "is_active": 1,
              "created_at": common.getCurrentDateTimeISO(),
              "updated_at": common.getCurrentDateTimeISO()
            }
          })
        })
      }

      const data = {
        "messageSendStatus": true,
        "messageId": messageId,
        "tempId": 0,
        "editStatus": true,
        "user_id": state.activeUserDetails.id,
        "reply_to_id": null,
        "message": `${message}`,
        "sent_date": common.getCurrentDateTimeISO(),
        // "link": `channel\/${channelId}`,
        "is_edited": 0,
        "is_file": (imageList && imageList.length > 0)? 1 : 0,
        "status_id": 1,
        "status_by_id": null,
        "status_date": common.getCurrentDateTimeFormatted(),
        "created_at": common.getCurrentDateTimeISO(),
        "updated_at": common.getCurrentDateTimeISO(),
        "deleted_at": null,
        "imap_message_id": null,
        "lang": null,
        "responses_count": 0,
        "message_receivers": [

        ],
        "message_status": 0,
        "currentTime": common.localDateAndTime(common.getCurrentDateTimeISO()),
        "user": state.activeUserDetails,
        "attachments": attachamentCollection,
        "form_data": null,
        "share_history": [

        ],
        "status": {
          "id": 1,
          "code": "active",
          "name": "Active",
          "is_active": 1,
          "created_at": common.getCurrentDateTimeISO(),
          "updated_at": common.getCurrentDateTimeISO()
        },
        "forward": null,
        "communication_envelope": null,
        "channel": state.data.channelDetails.channel,
        "imageList":imageList
      }
      saveUnsentMessage(data);
      // to inject temporary message at the end of state.communicationLists
      state.communicationList.push(data);
    }

    function forWardCommunication() {
      state.item.hasError = false;
      state.item.error.forwardCommunication = [];
      const file = [] as any;
      if (state.data.forwardMessage.attachments.length) {
        state.data.forwardMessage.attachments.forEach((image: any) => {
          file.push(image.id)
        });
      }

      if (state.ui.isPostLoading.forwardCommunication) return false;
      const messageId = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_id: state.data.forwardMessage.id,
        message: state.data.forwardWriteMessage,
        files: file,
        messageId: messageId
      };
      temporaryMessge(messageId, state.data.forwardWriteMessage, file);

      // console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.forwardCommunication = true;
      ethitransService
        .forwardCommunication(payLoad)
        .then((res: any) => {
          removeUnsentMessage(res.data.data.messageId);
          hideForwardMessage();
          state.selectedForEdit = {};
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.deleteCommunication)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.forwardCommunication.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.forwardCommunication = false;
        });
    }

    function deleteCommunication() {
      console.log("CHECK TEH DATA", state.selectedForEdit);
      state.item.hasError = false;
      state.item.error.deleteCommunication = [];
      if (state.ui.isPostLoading.deleteCommunication) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_id: state.selectedForEdit.id,
      };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.deleteCommunication = true;
      ethitransService
        .deleteCommunication(payLoad)
        .then((res: any) => {
          // console.log("deleteCommunication Check:>>> ", res.data.data);id
          let indexSelected = 0;
          state.communicationList.filter((item: any, index: number) => {
            if (item.id === state.selectedForEdit.id) {
              indexSelected = index;
            }
          });
          // console.log("deleteCommunication Index:>>> ", indexSelected);
          // state.communicationList[indexSelected] = res.data.data;
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.communicationList[indexSelected] = res.data.data;
          state.communicationList[indexSelected].deletedLocalTime = common.localDateAndTime(res.data.data.deleted_at);
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (state.communicationList[indexSelected].sent_date) {
            state.communicationList[indexSelected].currentTime = common.localDateAndTime(state.communicationList[indexSelected].sent_date)
          }
          state.communicationList[indexSelected].allowDelete = false;
          hideDeleteCommunicationModal();
          // state.userList = res.data.data ? res.data.data : [];
          state.selectedForEdit = {};
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.deleteCommunication)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.deleteCommunication.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.deleteCommunication = false;
        });
    }

    function deleteReplyCommunication() {
      state.item.hasError = false;
      state.item.error.deleteCommunication = [];
      if (state.ui.isPostLoading.deleteCommunication) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        response_id: state.selectedForEdit.id,
      };
      state.ui.isPostLoading.deleteCommunication = true;

      // state.communicationList.responses[indexSelected].deletedLocalTime = common.localDateAndTime(common.getCurrentDateTimeISO())
      // state.communicationList.responses[indexSelected].deletedLocalTime = common.localDateAndTime(common.getCurrentDateTimeISO())
      // // state.communicationList.responses[indexSelected] = res.data.data;
      // state.communicationList.responses[indexSelected].allowDelete = false;


      ethitransService
        .deleteReplyCommunication(payLoad)
        .then((res: any) => {
          let indexSelected = 0;
          state.communicationList.filter((item: any, index: number) => {
            item.responses.filter((item2: any, index: number) => {
              if (item2.id === state.selectedForEdit.id) {
                indexSelected = index;
              }
            })
          });
          console.log("CHECK THE ID HERE >>>> ", indexSelected);
          // console.log("FINAL CHECK >>> ", state.communicationList.responses[indexSelected])
          // state.communicationList.responses[indexSelected] = res.data.data;
          // eslint-disable-next-line @typescript-eslint/camelcase
          state.data.selectedReplyUser.responses[indexSelected].deleted_at = common.localDateAndTime(common.getCurrentDateTimeISO());
          // console.log("deleteCommunication Check:>>> ", res.data.data);id

          hideDeleteReplyMessage();
          // state.userList = res.data.data ? res.data.data : [];
          state.selectedForEdit = {};
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.deleteCommunication)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.deleteCommunication.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.deleteCommunication = false;
        });
    }

    function selectedDocumentToEdit(
      communication: any,
      sharedDocument: any,
      document: any
    ) {
      state.item.error.renameSelectedAttachment = [];
      state.item.hasError = false;

      state.selectedAttachment = document;
      // console.log("Document Selected>>> ",state.selectedAttachment)
      state.selectedForSharedDelete = sharedDocument;
      state.selectedForDelete = communication;
      state.data.selectedForRename = JSON.parse(JSON.stringify(document));
      const extension = document.filename.substring(
        document.filename.lastIndexOf(".") + 1
      );
      state.data.selectedForRename.extension = extension;
      state.data.selectedForRename.newName = document.filename.replaceAll(
        `.${extension}`,
        ""
      );
      $("#renameModal").modal("show");
    }
    function renameDoc(communication: any, image: any) {
      state.item.error.renameSelectedAttachment = [];
      state.item.hasError = false;
      // console.log("rename doc Index>>> ", common.findIndex(state.communicationList, communication))
      // console.log("Image details>>> ", image.communication_id)

      // console.log("Image details INDEX>>> ", common.findIndex(state.communicationList[common.findIndex(state.communicationList, communication)].attachments, image))
      state.data.selectedForRename = JSON.parse(JSON.stringify(image));
      const extension = image.file_name.substring(
        image.file_name.lastIndexOf(".") + 1
      );
      state.data.selectedForRename.communicationIndex = common.findIndex(
        state.communicationList,
        communication
      );
      state.data.selectedForRename.attachmentIndex = common.findIndex(
        state.communicationList[
          common.findIndex(state.communicationList, communication)
        ].attachments,
        image
      );
      state.data.selectedForRename.extension = extension;
      state.data.selectedForRename.newName = image.file_name.replaceAll(
        `.${extension}`,
        ""
      );
      $("#renameModal").modal("show");
    }
    function showImageModel(images: any, image: any) {
      state.slides = [];
      const index = images.attachments.indexOf(image);
      if (index != -1) {
        images.attachments.splice(index, 1); // remove 1 element from index 
        images.attachments.unshift(image);
      }

      state.slides = images.attachments;
      state.slides.length > 1 ? state.isNext = true : state.isNext = false;
      $("#viewImageModal").modal("show");
    }
    function showImageModelHide() {
      $("#viewImageModal").modal("hide");
      state.currentIndex = 0;
    }

    function showReplyImageModel(image: any) {
      state.replyImage = '';
      state.replyImage = image;
      $("#viewReplyImageModal").modal("show");
    }
    function showReplyImageModelHide() {
      $("#viewReplyImageModal").modal("hide");
      state.currentIndex = 0;
    }


    function hideRenameAttachmentModal() {
      $("#renameModal").modal("hide");
    }
    function renameAttachment() {
      state.item.hasError = false;
      state.item.error.renameSelectedAttachment = [];
      if (state.ui.isPostLoading.renameSelectedAttachment) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_attachment_id: state.data.selectedForRename.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        file_name:
          state.data.selectedForRename.newName +
          "." +
          state.data.selectedForRename.extension,
      };
      state.ui.isPostLoading.renameSelectedAttachment = true;
      ethitransService
        .renameCommunicationAttachment(payLoad)
        .then((res: any) => {
          // console.log("renameSelectedAttachment Check:>>> ", res.data.data);
          console.log(
            "renameSelectedAttachment Check:>>> ",
            res.data.data.file_name
          );
          state.communicationList[
            state.data.selectedForRename.communicationIndex
          ].attachments[state.data.selectedForRename.attachmentIndex] =
            res.data.data;
          state.communicationList[
            state.data.selectedForRename.communicationIndex
          ].attachments[
            state.data.selectedForRename.attachmentIndex
          ].convertedFileSize = common.formatBytes(
            parseInt(
              res.data.data.file_size
              // parseInt(data.file_size.split(" ")[0])
            )
          );
          hideRenameAttachmentModal();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.renameSelectedAttachment)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.renameSelectedAttachment.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.renameSelectedAttachment = false;
        });
    }
    function removeMemberFromMemberList(data: object) {
      state.memberList = state.memberList.filter((e: any) => e != data);
    }

    function removeActiveMemberCount() {
      state.approvedMembersCount = state.approvedMembersCount - Number(1);
    }

    function renameSelectedSharedAttachment() {
      state.item.hasError = false;
      state.item.error.renameSelectedAttachment = [];
      if (state.ui.isPostLoading.renameSelectedAttachment) return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        shared_file_id: state.selectedAttachment.id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        filename:
          state.data.selectedForRename.newName +
          "." +
          state.data.selectedForRename.extension,
      };
      state.ui.isPostLoading.renameSelectedAttachment = true;
      ethitransService
        .selectedFileRename(payLoad)
        .then((res: any) => {
          // console.log("renameSelectedAttachment Check:>>> ", res.data.data);
          state.communicationList[
            common.findIndex(state.communicationList, state.selectedForDelete)
          ].share_history[
            common.findIndex(
              state.communicationList[
                common.findIndex(
                  state.communicationList,
                  state.selectedForDelete
                )
              ].share_history,
              state.selectedForSharedDelete
            )
          ].documents.forEach((doc: any) => {
            if (doc.id === res.data.data.id) {
              console.log("DOC", doc);
              doc.filename = res.data.data.filename;
              // eslint-disable-next-line @typescript-eslint/camelcase
              doc.updated_at = res.data.data.updated_at;
              //  console.log("Selected EDIT >>> ", doc)
            }
          });
          hideRenameAttachmentModal();
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.renameSelectedAttachment)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.renameSelectedAttachment.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.renameSelectedAttachment = false;
        });
    }
    function renameSelectedAttachment() {
      if (state.data.selectedForRename.file_name) {
        renameAttachment();
      } else {
        renameSelectedSharedAttachment();
      }
    }
    function removeForwardFile(image: any) {
      state.data.forwardMessage.attachments = state.data.forwardMessage.attachments.filter((e: any) => e != image);

    }
    function selectedCommunicationImageToDelete(
      communication: any,
      image: any
    ) {
      state.selectedAttachment = image;
      // state.selectedAttachment = JSON.parse(JSON.stringify(image));
      state.selectedForDelete = communication;
      $("#deleteSelectedAttachment").modal("show");
      console.log("DELETE>>> ", state.selectedAttachment);
      // removeSelectedCommunicationImage(image);
    }

    function cancelSelectedEdit(selected: any) {
      state.data.selectedChatIndex = 0;
      // console.log("Reset Selecte Communication >>>", state.selectedForEdit)
      // emmit the data
      // selected = state.selectedForEdit
      // selected.allowEdit = false
      selected.allowEdit = false;
      selected.message = state.selectedForEdit.message;
      //  emit("edited-communication", state.selectedForEdit);
    }

    function sendEditedCommunication(selectedCommunication: any) {
      selectedCommunication.allowEdit = false;
      const selectedRowIndex = common.findIndex(
        state.communicationList,
        selectedCommunication
      );

      console.log("INDEX CHECK ", selectedRowIndex);
      if (selectedCommunication && selectedCommunication.message) {
        const payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          communication_id: selectedCommunication.id,
          message: selectedCommunication.message,
        };
        state.ui.isPostLoading.sendEditedCommunication = true;
        ethitransService
          .editCommunication(payLoad)
          .then((res: any) => {
            // res.data.data.communication.allowEdit = false;
            console.log(
              "editCommunication Check:>>> ",
              res.data.data.communication.updated_at
            );
            res.data.data.communication.editedUpdated = common.localDateAndTime(res.data.data.communication.updated_at
            );
            state.communicationList[selectedRowIndex] = res.data.data.communication;
            if (state.communicationList[selectedRowIndex].forward && state.communicationList[selectedRowIndex].forward.communication) {
              state.communicationList[selectedRowIndex].forward.communication.editedUpdated = common.localDateAndTime(
                state.communicationList[selectedRowIndex].forward.communication.updated_at
              );
            }
            state.communicationList.forEach((item: any) => {
              if (item.forward && item.forward.communication.id == res.data.data.communication.id) {
                item.forward.communication.message = res.data.data.communication.message;
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.forward.communication.is_edited = 1;
                item.forward.communication.editedUpdated = common.localDateAndTime(
                  res.data.data.communication.updated_at
                );
              }
            });
            state.communicationList[selectedRowIndex].allowEdit = false;
            state.communicationList[selectedRowIndex].editStatus = true;
            state.data.typeEditDisabled = false;
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            state.ui.isPostLoading.sendEditedCommunication = false;
          });
      }
    }

    function sendEditedReplyCommunication(selectedCommunication: any) {
      selectedCommunication.allowEdit = false;
      const selectedRowIndex = common.findIndex(
        state.communicationList,
        selectedCommunication
      );

      console.log("INDEX CHECK ", selectedRowIndex);
      if (selectedCommunication && selectedCommunication.message) {
        const payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          response_id: selectedCommunication.id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          response_type: "text",
          message: selectedCommunication.message,

        };
        state.ui.isPostLoading.sendEditedCommunication = true;
        ethitransService
          .editReplyCommunication(payLoad)
          .then((res: any) => {
            // res.data.data.communication.allowEdit = false;
            console.log(
              "editCommunication Check:>>> ",
              res.data.data.communication.updated_at
            );
            res.data.data.communication.editedUpdated = common.localDateAndTime(res.data.data.communication.updated_at
            );
            state.communicationList[selectedRowIndex] = res.data.data.communication;
            if (state.communicationList[selectedRowIndex].forward && state.communicationList[selectedRowIndex].forward.communication) {
              state.communicationList[selectedRowIndex].forward.communication.editedUpdated = common.localDateAndTime(
                state.communicationList[selectedRowIndex].forward.communication.updated_at
              );
            }
            state.communicationList.forEach((item: any) => {
              item.responses.forEach((res: any) => {
                if (res.id == selectedCommunication.id) {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  res.is_edited = 1;
                  res.editedUpdated = common.localDateAndTime(res.data.data.communication.updated_at);
                }
              })
              if (item.forward && item.forward.communication.id == res.data.data.communication.id) {
                item.forward.communication.message = res.data.data.communication.message;
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.forward.communication.is_edited = 1;
                item.forward.communication.editedUpdated = common.localDateAndTime(
                  res.data.data.communication.updated_at
                );
              }
            });
            state.communicationList[selectedRowIndex].allowEdit = false;
            state.communicationList[selectedRowIndex].editStatus = true;
            state.data.typeEditDisabled = false;
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            state.ui.isPostLoading.sendEditedCommunication = false;
          });
      }
    }
    function editChatCommunicationEnterSetting(data: boolean) {
      // const userInfo: any = localStorage.getItem("current_user_info");
      // if (JSON.parse(userInfo).enter_key === 0) return false;
      if (
        state.communicationList[
          state.data.selectedChatIndex
        ].message.startsWith("<p></p>")
      ) {
        console.log("<<Removed >>");
        state.communicationList[state.data.selectedChatIndex].message = "";
      }
      if (
        state.communicationList[
          state.data.selectedChatIndex
        ].message.startsWith("<p><br></p>")
      ) {
        // console.log("<<Removed >>");
        state.communicationList[state.data.selectedChatIndex].message = "";
      }
      // if (!state.message) return false;
      // if (data === true) {
      //   enableEnterKey.value = true;
      // } else {
      //   enableEnterKey.value = false;
      // }
      if (!state.communicationList[state.data.selectedChatIndex].message)
        return false;
      const userInfo: any = localStorage.getItem("current_user_info");
      // console.log("##### API ENTER KEY Local setting #### ", userInfo)
      console.log(
        "##### API ENTER KEY Local setting #### ",
        typeof JSON.parse(userInfo).enter_key
      );
      //  if (JSON.parse(userInfo).enter_key === 1) return false;
      // addEventListener("keyup", function (event: any) {
      //   if (event.keyCode === 13 && !event.shiftKey) {
      sendEditedCommunication(
        state.communicationList[state.data.selectedChatIndex]
      );
      //   }
      // });
    }
    function replyChatCommunicationEnterSetting(data: boolean) {
      //  if (common.onlySpaces(state.data.replyMessage)) {
      //   console.log("<<Removed >>");
      //   state.data.replyMessage = "";
      // }
      if (state.data.replyMessage.startsWith("<p></p>")) {
        console.log("<<Removed >>");
        state.data.replyMessage = "";
      }
      if (state.data.replyMessage.startsWith("<p><br></p>")) {
        console.log("<<Removed >>");
        state.data.replyMessage = "";
      }
      if (!state.data.replyMessage) return false;
      replyMessage();
    }

    // communicatin ends here
    function radioChange(element: any, option: any) {
      console.log("Check Element >>>", element);
      console.log("Check Form Data >>>", option);
      // state.userFormData = state.userFormData.filter(
      //   (item: any) => item.text != removeText
      // );
      // if (element.type === "radioButton") {
      //   state.newFormData.elements.forEach((item: any) => {
      //     if (
      //       item.title === element.title &&
      //       item.type === "radioButton" &&
      //       element.type === "radioButton"
      //     ) {
      //       if (item.type === "radioButton") {
      //         item.select_values.filter((data: any) => {
      //           if (option.text != data.text && option.value != data.value) {
      //             data.selected = "";
      //           }
      //         });
      //       }
      //       // console.log("Form Data elements >>>> ", item);
      //       // console.log("Check selected Element >>>", element);
      //       // console.log("Check selected Element's Option >>>", option);
      //     }
      //   });
      // }
    }
    function redirectToDynamicForm() {
      // console.log("<<< Redirecting to Dynamic Form >>>", channelId);
      // with query, resulting in /channel?channelId=private
      router.push({
        name: "admin-channel-create-form",
        query: { projectId: state.projectId, channelId: channelId },
      });
      $(".modal-backdrop").remove();
      // router.push({ path: 'channel', query: { channelId: 'private' } })
    }

    function selectToChangeUsers(value: any) {
      const existingIndex = state.invite.users.some((obj: any) => obj.id === value.id);
      if (value.id == "convo101" && allUsers.value.length >= 2) {
        const filteredArray = allUsers.value.filter((item: any) => item.id !== "convo101");
        state.invite.users = state.invite.users.concat(filteredArray);
        allUsers.value = [
          {
            'id': 'convo101',
            'label': 'Select All users',
            'email': 'Select All users',
          }
        ];
      }
      else if (!existingIndex && value.id != "convo101") {
        state.invite.users.push(value);
        const filteredArray = allUsers.value.filter((item: any) => item.id !== value.id);
        allUsers.value = filteredArray;
      }
      selectedUsers.value = {};
    }
    function getUserList() {
      // console.log("USERLIST check for channel ID", channelId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.projectId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
      };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.isLoading.getUserList = true;
      ethitransService
        .projectMemberListByChannel(payLoad)
        .then((res: any) => {
          // console.log("UserList Check:>>> ", res.data.data);
          state.userList = res.data.data ? res.data.data : [];
          allUsers.value = [
            {
              'id': 'convo101',
              'label': 'Select All user',
              'email': 'Select All user',
              'full_name': 'Select All user'
            }
          ];
          res.data.data.forEach((user: any) => {
            allUsers.value.push({
              'id': user.uuid,
              'label': user.email,
              'email': user.email,
              'full_name': user.full_name,
            })
          })
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.isLoading.getUserList = false;
        });
    }
    function addOptions() {
      console.log("CHECK  ADD");
      state.form.radioButton.select_values.push({
        text: "",
        value: "",
        selected: "",
      });
    }
    function addSelectOptions() {
      state.form.selectOption.select_values.push({
        text: "",
        value: "",
        selectedOption: {},
        selected: false,
      });
    }
    function addCheckBox() {
      state.form.checkBox.select_values.push({
        text: "",
        value: "",
        selected: false,
      });
    }
    function removeCheckBox(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.checkBox.select_values =
        state.form.checkBox.select_values.filter(
          (item: any) => item.text != removeText
        );
    }
    function removeSelectOption(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.selectOption.select_values =
        state.form.selectOption.select_values.filter(
          (item: any) => item.text != removeText
        );
    }
    function removeOptions(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.radioButton.select_values =
        state.form.radioButton.select_values.filter(
          (item: any) => item.text != removeText
        );
    }
    function showCreateNewForm() {
      // state.formList = []
      state.item.error.erorListNewForm = [];
      state.newFormData.elements = [];
      state.newFormData.title = "";
      state.newFormData.description = "";
      state.newFormData.privacy.code = "channel";
      state.newFormData.privacy.value = "private to this channel";
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#innerchannelModal").modal("show");
    }
    function showTextFieldAdd(type: string, heading: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.checkBox.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.radioButton.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.selectOption.select_values = [];
      state.typePosition = "bottom";
      // console.log("CHECK THE TYPE:>>>>", type);
      // console.log("CHECK THE Heading:>>>>", heading);
      state.selected = heading;
      state.type = type;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.radioButton.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.selectOption.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.checkBox.select_values = [];
      if (type === "radioButton") {
        addOptions();
      } else if (type === "single_select") {
        addSelectOptions();
      } else if (type === "checkBox") {
        addCheckBox();
      }
      state.form.textField.title = "";
      state.form.textField.description = "";
      state.form.textbox.title = "";
      state.form.textArea.title = "";
      state.form.radioButton.title = "";
      state.form.selectOption.title = "";
      state.form.checkBox.title = "";
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#addfieldModal").modal("show");
    }
    function addOptionsinCustom() {
      state.form.radioButton.select_values.push({
        text: "",
        value: "",
        selected: "",
      });
    }
    function addOptionsinCustomEdit() {
      if (
        state.selectedElement.element.select_values &&
        state.selectedElement.element.select_values.length
      ) {
        if (
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].text &&
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].value
        ) {
          state.selectedElement.element.select_values.push({
            text: "",
            value: "",
            selected: false,
          });
          //     state.form.radioButton.select_values.push({
          //   text: "",
          //   value: "",
          //   selected: false,
          // });
        }
      }
    }
    function addSelectOptionsinCustomEdit() {
      if (
        state.selectedElement.element.select_values &&
        state.selectedElement.element.select_values.length
      ) {
        if (
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].text &&
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].value
        ) {
          state.selectedElement.element.select_values.push({
            text: "",
            value: "",
            selectedOption: {},
            selected: false,
          });
        }
      }
    }
    function addSelectOptionsinCustom() {
      state.form.selectOption.select_values.push({
        text: "",
        value: "",
        selectedOption: {},
        selected: false,
      });
    }
    function addCheckBoxinCustom() {
      state.form.checkBox.select_values.push({
        text: "",
        value: "",
        selected: false,
      });
    }
    function addCheckBoxinCustomEdit() {
      if (
        state.selectedElement.element.select_values &&
        state.selectedElement.element.select_values.length
      ) {
        if (
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].text &&
          state.selectedElement.element.select_values[
            state.selectedElement.element.select_values.length - 1
          ].value
        ) {
          state.selectedElement.element.select_values.push({
            text: "",
            value: "",
            selected: false,
          });
        }
      }
    }
    function showCustomEdit(type: string, heading: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.checkBox.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.radioButton.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.selectOption.select_values = [];
      state.typePosition = "bottom";
      state.selected = heading;
      // console.log("CHECK THE TYPE:>>>>", type);
      // console.log("CHECK THE Heading:>>>>", heading);
      state.type = type;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.radioButton.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.selectOption.select_values = [];
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.form.checkBox.select_values = [];
      if (type === "radioButton") {
        addOptionsinCustom();
      } else if (type === "single_select") {
        addSelectOptionsinCustom();
      } else if (type === "checkBox") {
        // console.log("Hello CheckBox");
        addCheckBoxinCustom();
      }
      state.form.textbox.title = "";
      state.form.textArea.title = "";
      state.form.radioButton.title = "";
      state.form.selectOption.title = "";
      state.form.checkBox.title = "";
      state.form.textField.description = "";
      state.form.textField.title = "";
      state.item.error.erorList = [];
      $("#addfieldModal1").modal("show");
    }

    function removeCheckBoxinCustomEdit(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.selectedElement.element.select_values =
        state.selectedElement.element.select_values.filter(
          (item: any) => item.text != removeText
        );
    }
    function removeSelectOptioninCustomEdit(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.selectedElement.element.select_values =
        state.selectedElement.element.select_values.filter(
          (item: any) => item.text != removeText
        );
    }
    function removeOptionsinCustomEdit(removeText: string) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.selectedElement.element.select_values =
        state.selectedElement.element.select_values.filter(
          (item: any) => item.text != removeText
        );
    }
    function titleSectionValiation() {
      state.item.error.erorList = [];
      state.item.hasError = false;

      if (!state.form.textField.title) {
        state.item.error.erorList.push("Project name is required.");
      }

      if (!state.item.error.erorList && !state.item.error.erorList.length) {
        state.item.hasError = false;
      } else if (state.item.error.erorList.length != 0) {
        state.item.hasError = true;
      }
    }
    function editElementsCustomValiation(type: string) {
      console.log("WOWOW", type);
      state.item.error.erorList = [];
      state.item.hasError = false;

      if (type === "section") {
        if (!state.form.textField.title) {
          console.log("Alert SSS");
          state.item.error.erorList.push("Section title is required.");
        }
      }
      if (type === "textbox") {
        console.log("Alert TB");
        if (!state.form.textbox.title) {
          state.item.error.erorList.push("Title is required.");
        }
      }
      if (type === "textArea") {
        if (!state.form.textArea.title) {
          state.item.error.erorList.push("Title is required.");
        }
      }
      if (type === "radioButton") {
        console.log("Radio Values Check", state.form.radioButton.select_values);
        if (!state.form.radioButton.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.radioButton.select_values &&
          state.form.radioButton.select_values.length
        ) {
          state.form.radioButton.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }
      if (type === "single_select") {
        console.log(
          "Radio Values Check",
          state.form.selectOption.select_values
        );
        if (!state.form.selectOption.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.selectOption.select_values &&
          state.form.selectOption.select_values.length
        ) {
          state.form.selectOption.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }
      if (type === "checkBox") {
        console.log("Radio Values Check", state.form.checkBox.select_values);
        if (!state.form.checkBox.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.checkBox.select_values &&
          state.form.checkBox.select_values.length
        ) {
          state.form.checkBox.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }

      if (!state.item.error.erorList && !state.item.error.erorList.length) {
        state.item.hasError = false;
      } else if (state.item.error.erorList.length != 0) {
        state.item.hasError = true;
      }
    }

    function addTextFieldInCustomEdit() {
      if (state.type === "section") {
        editElementsCustomValiation("section");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        if (state.typePosition === "top") {
          state.selectedFormForEdit.elements.unshift({
            type: "section",
            title: state.form.textField.title,
            description: state.form.textField.description,
          });
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.push({
              type: "section",
              title: state.form.textField.title,
              description: state.form.textField.description,
            });
          } else {
            state.selectedFormForEdit.elements =
              state.selectedFormForEdit.elements.concat([
                {
                  type: "section",
                  title: state.form.textField.title,
                  description: state.form.textField.description,
                },
              ]);
          }
        }
      }
      if (state.type === "textbox") {
        editElementsCustomValiation("textbox");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        console.log("FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          state.selectedFormForEdit.elements.unshift({
            type: "textbox",
            title: state.form.textbox.title,
            answer: "",
            tooltip: "Please write your full name",
            placeholder: "Write your name here",
            validation: [
              {
                type: "required",
                // eslint-disable-next-line @typescript-eslint/camelcase
                error_message: "Your name is required",
              },
            ],
          });
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.unshift({
              type: "textbox",
              title: state.form.textbox.title,
              answer: "",
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.selectedFormForEdit.elements =
              state.selectedFormForEdit.elements.concat([
                {
                  type: "textbox",
                  title: state.form.textbox.title,
                  answer: "",
                  tooltip: "Please write your full name",
                  placeholder: "Write your name here",
                  validation: [
                    {
                      type: "required",
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      error_message: "Your name is required",
                    },
                  ],
                },
              ]);
          }
        }
      }
      if (state.type === "title") {
        editElementsCustomValiation("title");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        console.log("FORM POSITION:>>", state.typePosition);
        titleSectionValiation();
        if (
          state.item.error.erorList.length != 0 ||
          state.item.hasError ||
          !state.typePosition
        )
          return false;
        if (state.typePosition === "top") {
          state.selectedFormForEdit.elements.unshift({
            type: "title",
            title: state.form.textField.title,
            description: state.form.textField.description,
          });
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.unshift({
              type: "title",
              title: state.form.textField.title,
              description: state.form.textField.description,
            });
          } else {
            state.selectedFormForEdit.elements =
              state.selectedFormForEdit.elements.concat([
                {
                  type: "title",
                  title: state.form.textField.title,
                  description: state.form.textField.description,
                },
              ]);
          }
        }
      }
      if (state.type === "textArea") {
        editElementsCustomValiation("textArea");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        if (state.typePosition === "top") {
          if (
            state.selectedFormForEdit &&
            state.selectedFormForEdit.elements.length
          ) {
            state.selectedFormForEdit.elements.unshift({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.selectedFormForEdit.elements.push({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.push({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.selectedFormForEdit.elements =
              state.selectedFormForEdit.elements.concat([
                {
                  type: "textArea",
                  title: state.form.textArea.title,
                  answer: state.form.textArea.text,
                  tooltip: "Please write your full name",
                  placeholder: "Write your name here",
                  validation: [
                    {
                      type: "required",
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      error_message: "Your name is required",
                    },
                  ],
                },
              ]);
          }
        }
      }
      if (state.type === "radioButton") {
        editElementsCustomValiation("radioButton");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        state.form.radioButton.select_values.forEach((item: any) => {
          if (item.text) {
            // console.log("CHECK FOR WHITE SPACE", hasWhiteSpace(item.text.toLowerCase()));
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        if (state.typePosition === "top") {
          if (state.form.radioButton) {
            state.selectedFormForEdit.elements.unshift({
              type: "radioButton",
              title: state.form.radioButton.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.radioButton.select_values,
            });
            // state.selectedFormForEdit
            // .elements.unshift( { state.form.radioButton
            // });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.unshift({
              type: "radioButton",
              title: state.form.radioButton.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.radioButton.select_values,
            });
          } else {
            state.selectedFormForEdit.elements =
              state.selectedFormForEdit.elements.concat([
                {
                  type: "radioButton",
                  title: state.form.radioButton.title,
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  select_values: state.form.radioButton.select_values,
                },
              ]);
          }
        }
      }
      if (state.type === "single_select") {
        editElementsCustomValiation("single_select");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        state.form.selectOption.select_values.forEach((item: any) => {
          if (item.text) {
            // console.log("CHECK FOR WHITE SPACE", hasWhiteSpace(item.text.toLowerCase()));
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        if (state.typePosition === "top") {
          if (state.form.selectOption) {
            state.selectedFormForEdit.elements.unshift({
              type: "single_select",
              selectedOption: {},
              title: state.form.selectOption.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.selectOption.select_values,
            });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.unshift({
              type: "single_select",
              selectedOption: {},
              title: state.form.selectOption.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.selectOption.select_values,
            });
          } else {
            state.selectedFormForEdit.elements =
              state.selectedFormForEdit.elements.concat([
                {
                  type: "single_select",
                  selectedOption: {},
                  title: state.form.selectOption.title,
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  select_values: state.form.selectOption.select_values,
                },
              ]);
          }
        }
      }
      if (state.type === "checkBox") {
        editElementsCustomValiation("checkBox");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        state.form.checkBox.select_values.forEach((item: any) => {
          if (item.text) {
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        if (state.typePosition === "top") {
          if (state.form.selectOption) {
            state.selectedFormForEdit.elements.unshift({
              type: "checkBox",
              title: state.form.checkBox.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.checkBox.select_values,
            });
            // state..unshift( { state.form.checkBox
            // });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.selectedFormForEdit.elements.length === 0) {
            state.selectedFormForEdit.elements.unshift({
              type: "checkBox",
              title: state.form.checkBox.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.checkBox.select_values,
            });
          } else {
            state.selectedFormForEdit.elements =
              state.selectedFormForEdit.elements.concat([
                {
                  type: "checkBox",
                  title: state.form.checkBox.title,
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  select_values: state.form.checkBox.select_values,
                },
              ]);
          }
        }
      }

      $("#addfieldModal1").modal("hide");
      // console.log("CHECK CUstom Edit Data", state.selectedFormForEdit);
    }
    function addElementsCreateNewValiation(type: string) {
      state.item.error.erorList = [];
      state.item.hasError = false;

      if (type === "section") {
        if (!state.form.textField.title) {
          state.item.error.erorList.push("Section title is required.");
        }
      }
      if (type === "textbox") {
        if (!state.form.textbox.title) {
          state.item.error.erorList.push("Title is required.");
        }
      }
      if (type === "textArea") {
        if (!state.form.textArea.title) {
          state.item.error.erorList.push("Title is required.");
        }
      }
      if (type === "radioButton") {
        console.log("Radio Values Check", state.form.radioButton.select_values);
        if (!state.form.radioButton.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.radioButton.select_values &&
          state.form.radioButton.select_values.length
        ) {
          state.form.radioButton.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }
      if (type === "single_select") {
        console.log(
          "Radio Values Check",
          state.form.selectOption.select_values
        );
        if (!state.form.selectOption.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.selectOption.select_values &&
          state.form.selectOption.select_values.length
        ) {
          state.form.selectOption.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }
      if (type === "checkBox") {
        console.log("Radio Values Check", state.form.checkBox.select_values);
        if (!state.form.checkBox.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.form.checkBox.select_values &&
          state.form.checkBox.select_values.length
        ) {
          state.form.checkBox.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }

      if (!state.item.error.erorList && !state.item.error.erorList.length) {
        state.item.hasError = false;
      } else if (state.item.error.erorList.length != 0) {
        state.item.hasError = true;
      }
    }
    function addTextField() {
      if (state.type === "section") {
        addElementsCreateNewValiation("section");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        console.log("Section FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          state.newFormData.elements.unshift({
            type: "section",
            title: state.form.textField.title,
            description: state.form.textField.description,
          });
        }
        if (state.typePosition === "bottom") {
          if (state.newFormData.elements.length === 0) {
            state.newFormData.elements.push({
              type: "section",
              title: state.form.textField.title,
              description: state.form.textField.description,
            });
          } else {
            state.newFormData.elements = state.newFormData.elements.concat([
              {
                type: "section",
                title: state.form.textField.title,
                description: state.form.textField.description,
              },
            ]);
          }
        }
      }
      if (state.type === "textbox") {
        addElementsCreateNewValiation("textbox");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        console.log("FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          state.newFormData.elements.unshift({
            type: "textbox",
            title: state.form.textbox.title,
            answer: "",
            tooltip: "Please write your full name",
            placeholder: "Write your name here",
            validation: [
              {
                type: "required",
                // eslint-disable-next-line @typescript-eslint/camelcase
                error_message: "Your name is required",
              },
            ],
          });
        }
        if (state.typePosition === "bottom") {
          console.log(
            "Check Array length >>>",
            state.newFormData.elements.length
          );
          if (state.newFormData.elements.length === 0) {
            state.newFormData.elements.push({
              type: "textbox",
              title: state.form.textbox.title,
              answer: "",
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.newFormData.elements = state.newFormData.elements.concat([
              {
                type: "textbox",
                title: state.form.textbox.title,
                answer: "",
                tooltip: "Please write your full name",
                placeholder: "Write your name here",
                validation: [
                  {
                    type: "required",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    error_message: "Your name is required",
                  },
                ],
              },
            ]);
          }
        }
      }
      if (state.type === "textArea") {
        addElementsCreateNewValiation("textArea");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        if (state.typePosition === "top") {
          if (state.newFormData && state.newFormData.elements.length) {
            state.newFormData.elements.unshift({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.newFormData.elements.push({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          }
        }
        if (state.typePosition === "bottom") {
          console.log(
            "Check Array length >>>",
            state.newFormData.elements.length
          );
          if (state.newFormData.elements.length === 0) {
            state.newFormData.elements.unshift({
              type: "textArea",
              title: state.form.textArea.title,
              answer: state.form.textArea.text,
              tooltip: "Please write your full name",
              placeholder: "Write your name here",
              validation: [
                {
                  type: "required",
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  error_message: "Your name is required",
                },
              ],
            });
          } else {
            state.newFormData.elements = state.newFormData.elements.concat([
              {
                type: "textArea",
                title: state.form.textArea.title,
                answer: state.form.textArea.text,
                tooltip: "Please write your full name",
                placeholder: "Write your name here",
                validation: [
                  {
                    type: "required",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    error_message: "Your name is required",
                  },
                ],
              },
            ]);
          }
        }
      }
      if (state.type === "radioButton") {
        addElementsCreateNewValiation("radioButton");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // console.log("FORM POSITION:>>", state.typePosition);
        // console.log("Check Radio Button:>>", state.form.radioButton);
        state.form.radioButton.select_values.forEach((item: any) => {
          if (item.text) {
            // console.log("CHECK FOR WHITE SPACE", hasWhiteSpace(item.text.toLowerCase()));
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        if (state.typePosition === "top") {
          if (state.form.radioButton) {
            state.newFormData.elements.unshift({
              type: "radioButton",
              title: state.form.radioButton.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.radioButton.select_values,
            });
            // state.newFormData.elements.unshift( { state.form.radioButton
            // });
          }
        }
        if (state.typePosition === "bottom") {
          console.log(
            "Check Array length >>>",
            state.newFormData.elements.length
          );
          if (state.newFormData.elements.length === 0) {
            if (state.form.radioButton) {
              state.newFormData.elements.unshift({
                type: "radioButton",
                title: state.form.radioButton.title,
                // eslint-disable-next-line @typescript-eslint/camelcase
                select_values: state.form.radioButton.select_values,
              });
              // state.newFormData.elements.unshift( { state.form.radioButton
              // });
            }
          } else {
            state.newFormData.elements = state.newFormData.elements.concat([
              {
                type: "radioButton",
                title: state.form.radioButton.title,
                // eslint-disable-next-line @typescript-eslint/camelcase
                select_values: state.form.radioButton.select_values,
              },
            ]);
          }
        }
        console.log(
          "Check the radio data",
          state.form.radioButton.select_values
        );
      }
      if (state.type === "single_select") {
        console.log("SELECT OPTION >>>", state.form.selectOption.select_values);
        addElementsCreateNewValiation("single_select");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        state.form.selectOption.select_values.forEach((item: any) => {
          if (item.text) {
            // console.log("CHECK FOR WHITE SPACE", hasWhiteSpace(item.text.toLowerCase()));
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        console.log("FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          if (state.form.selectOption) {
            state.newFormData.elements.unshift({
              type: "single_select",
              selectedOption: {},
              title: state.form.selectOption.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.selectOption.select_values,
            });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.newFormData.elements.length === 0) {
            state.newFormData.elements.unshift({
              type: "single_select",
              selectedOption: {},
              title: state.form.selectOption.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.selectOption.select_values,
            });
          } else {
            state.newFormData.elements = state.newFormData.elements.concat([
              {
                type: "single_select",
                selectedOption: {},
                title: state.form.selectOption.title,
                // eslint-disable-next-line @typescript-eslint/camelcase
                select_values: state.form.selectOption.select_values,
              },
            ]);
          }
        }
      }
      if (state.type === "checkBox") {
        addElementsCreateNewValiation("checkBox");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        state.form.checkBox.select_values.forEach((item: any) => {
          if (item.text) {
            item.value = common.hasWhiteSpace(item.text);
          }
        });
        console.log("FORM POSITION:>>", state.typePosition);
        if (state.typePosition === "top") {
          if (state.form.selectOption) {
            state.newFormData.elements.unshift({
              type: "checkBox",
              title: state.form.checkBox.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.checkBox.select_values,
            });
            // state..unshift( { state.form.checkBox
            // });
          }
        }
        if (state.typePosition === "bottom") {
          if (state.newFormData.elements.length === 0) {
            if (state.form.selectOption) {
              state.newFormData.elements.unshift({
                type: "checkBox",
                title: state.form.checkBox.title,
                // eslint-disable-next-line @typescript-eslint/camelcase
                select_values: state.form.checkBox.select_values,
              });
              // state..unshift( { state.form.checkBox
              // });
            }
          } else {
            state.newFormData.elements = state.newFormData.elements.concat({
              type: "checkBox",
              title: state.form.checkBox.title,
              // eslint-disable-next-line @typescript-eslint/camelcase
              select_values: state.form.checkBox.select_values,
            });
          }
        }
      }

      $("#addfieldModal").modal("hide");
      console.log("CHECK", state.newFormData);
    }

    function scrollAtButton() {
      //   var myDiv = document.getElementById("myDiv");
      // myDiv.scrollTop = myDiv.scrollHeight;
      // chat-body-content
      // location.reload();
      // window.onload = function () {
      const objDiv: any = document.getElementById("chat-body-content");
      objDiv.scrollTop = objDiv.scrollHeight - 3;
      // };
      // return false;
    }

    function scrollToTop() {
      if (state.isManualScroll) {
        return true;
      }
      // if(routerQuery.query.page && routerQuery.query.communicationId){
      //  router.push({
      //   name: "admin-channel",
      //   params: { channelId: channelId },
      // });
      // }

      $("#chat-body-content").animate(
        { scrollTop: $("#chat-body-content")[0].scrollHeight / 15 },
        10
      );
      state.firstScroll = false;
    }

    function cancelEditSelectedForm() {
      // state.selectedFormForEdit.title = state.selectedFormForEdit.title
      //   state.selectedFormForEdit.description = state.selectedFormForEdit.description
      $("#editSelectedElement").modal("hide");
    }
    function updateEditFormElementsValidation(type: string) {
      state.item.error.erorList = [];
      state.item.hasError = false;

      if (type === "title") {
        if (!state.selectedElement.title) {
          state.item.error.erorList.push("Form title is required.");
        }
      }
      if (type === "section" || type === "textbox" || type === "textArea") {
        if (!state.selectedElement.title) {
          state.item.error.erorList.push("Title is required.");
        }
      }
      if (
        type === "radioButton" ||
        type === "single_select" ||
        type === "checkBox"
      ) {
        console.log("Radio Values Check", state.form.radioButton.select_values);
        if (!state.selectedElement.element.title) {
          state.item.error.erorList.push("Title is required.");
        }
        if (
          state.selectedElement.element.select_values &&
          state.selectedElement.element.select_values.length
        ) {
          state.selectedElement.element.select_values.forEach((item: any) => {
            if (!item.text) {
              state.item.error.erorList.push("Options is required.");
            }
          });
        }
      }
      if (!state.item.error.erorList && !state.item.error.erorList.length) {
        state.item.hasError = false;
      } else if (state.item.error.erorList.length != 0) {
        state.item.hasError = true;
      }
    }
    function editSelectedForm() {
      if (state.selectedElement.type === "title") {
        updateEditFormElementsValidation("title");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // console.log("<<< Title Change >>>");
        state.selectedFormForEdit.title = state.selectedElement.title;
        state.selectedFormForEdit.description =
          state.selectedElement.description;
      } else if (
        state.selectedElement.type === "textbox" ||
        state.selectedElement.type === "section"
      ) {
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // state.selectedFormForEdit.title = state.selectedElement.title;
        state.selectedFormForEdit.elements[state.selectedElement.index].title =
          state.selectedElement.title;
        state.selectedFormForEdit.elements[
          state.selectedElement.index
        ].description = state.selectedElement.description;
        // console.log("<<< Title Change >>>");
      } else if (state.selectedElement.type === "textArea") {
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // state.selectedFormForEdit.title = state.selectedElement.title;
        // console.log("<<< Title Change >>>");
        state.selectedFormForEdit.elements[state.selectedElement.index].title =
          state.selectedElement.title;
      } else if (
        state.selectedElement.type === "radioButton" ||
        state.selectedElement.type === "single_select" ||
        state.selectedElement.type === "checkBox"
      ) {
        // console.log("<<< Title Change >>>");
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;

        state.selectedFormForEdit.elements[state.selectedElement.index] =
          state.selectedElement.element;
      }
      // state.selectedElement.index

      // state.selectedFormForEdit.elements.splice(
      //   state.selectedElement.index,
      //   1,
      //   state.selectedElement.element
      // );
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#editSelectedElement").modal("hide");
    }
    function showEditFormName(data: any) {
      state.selectedElement.type = data;
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#editSelectedElement").modal("show");
      console.log("Title Data Check: >>", state.selectedElement.type);
      console.log("edit Title:>>", state.selectedFormForEdit.title);
      console.log("edit Description:>>", state.selectedFormForEdit.description);
      state.selectedElement.title = state.selectedFormForEdit.title;
      state.selectedElement.description = state.selectedFormForEdit.description;
    }
    function showDeleteElement(form: any, elementType: string) {
      // addTextField;
      console.log("Element Selected for Delete: >>> ", form);
      console.log("Element Type: >>> ", elementType);
      const formIndex = common.findIndex(
        state.selectedFormForEdit.elements,
        form
      );
      console.log("Selected Element Index For Delete", formIndex);
      state.selectedElement.element = form;
      $("#deleteSelectedElement").modal("show");
    }
    function cancelDeleteSelectedForm() {
      state.selectedElement.element = {};
      $("#deleteSelectedElement").modal("hide");
    }
    function confirmDeleteElement() {
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.selectedFormForEdit.elements =
        state.selectedFormForEdit.elements.filter(
          (item: any) => item != state.selectedElement.element
        );
      $("#deleteSelectedElement").modal("hide");
    }
    function showEditElement(form: any, elementType: string) {
      // addTextField;
      console.log("Edit Element Selected: >>> ", form);
      console.log("Element Type: >>> ", elementType);
      const formIndex = common.findIndex(
        state.selectedFormForEdit.elements,
        form
      );
      console.log("Selected Form Index", formIndex);
      $("#editSelectedElement").modal("show");
      state.selectedElement.element = form;
      state.selectedElement.type = elementType;
      state.selectedElement.index = common.findIndex(
        state.selectedFormForEdit.elements,
        form
      );
      state.selectedElement.title = form.title;
      state.selectedElement.description = form.description;
    }
    function selectedElement(form: any) {
      console.log("Selected element>>>:", form);
    }
    function handleClick() {
      console.log("EMIT Check");
    }
    function getAccountFormRetrieveById(formId: any) {
      state.selectedFormForEdit = {};
      // console.log("Get Account form Response:>>>", formId);
      state.ui.isPostLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          console.log(
            "getAccountFormRetrieveById Response Check:>>> ",
            res.data.elements
          );
          // state.formData = res.data;
          state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getAccountFormRetrieveById = false;
        });
    }
    function getAccountFormRetrieveByIdForSend(formId: any) {
      state.formData = {};
      // console.log("Get Account form Response:>>>", formId);
      state.ui.isPostLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          console.log(
            "getAccountFormRetrieveById Response Check:>>> ",
            res.data.elements
          );
          state.formData = res.data ? res.data : {};
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getAccountFormRetrieveById = false;
        });
    }
    function showMentionUser() {
      getUserList();
      state.invite.users = [];
      state.ui.template.msg = "";
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;
      $("#mentionuser").modal("show");
    }

    const unReadMessageStatus = computed(() => {
      // if (state.newMessageStatus) return false;
      // const newLength = state.communicationList.filter(
      //   (item: any) => ( (item.user_id != state.activeUserDetails.id) && ((  item.message_status === 0 || item.message_status === 1) && item.newMessage))
      // ).length;
      // state.data.unreadMessage =  state.data.unreadMessage + newLength;
      // return state.data.unreadMessage;
      // return newLength === 0 ? state.data.unreadMessage : state.data.unreadMessage + newLength;
      return false;
    });


    // eslint-disable-next-line vue/return-in-computed-property
    const sentToUsers = computed(() => {
      if (state.formData.sentToUsers && state.formData.sentToUsers.length) {
        return state.formData.sentToUsers.filter((form: any) => {
          if (form.email) {
            return form.email
              .toLowerCase()
              .match(state.item.searchUser.toLowerCase());
          }
        });
      } else {
        return [];
      }
    });
    // function hidecalender() {
    //   state.data.isCalenderHide = false;
    // }

    function hidecalender() {
      state.userFormData.forEach((item: any) => {
        if (item.type === "datePicker") {
          item.show = false;
        }
      });
    }

    watch(
      () => state.data.convertedDate,
      () => {
        setTimeout(hidecalender, 100);
      }
    );

    const userFormData = computed(() => {
      state.userFormData.forEach((item: any) => {
        if (item.type === "datePicker") {
          const day = item.date ? item.date : new Date();
          // console.log("DATE PICKER CHECK >>>", item.date);
          // console.log("DATE PICKER CHECK fINAL >>>", day);
          const dayWrapper = moment(day);
          const dayString = dayWrapper.format("L");
          // return dayString;
          item.convertedDate = dayString;
          // if(item.type === 'digitalSignature') {
          //     console.log("Digital Signature Data", item)
          //     item.canvas = document.getElementById(item.canvasName);
          //     if (item.canvas) {
          //       item.signaturePad = new SignaturePad(item.canvas, {
          //       backgroundColor: "rgb(255, 255, 255)",
          //     });
          //     // window.onresize = resizeCanvas;
          //     // resizeCanvas()
          //   }
          state.data.convertedDate = item.convertedDate;
        }
      });
      if (state.data.startValidation) {
        state.userFormData.forEach((data: any) => {
          // console.log("CHECK ::: >>> ", data)
          if (data.required) {
            if (data.type === "textbox" || data.type === "textArea") {
              if (!data.answer) {
                data.hasError = data.title;
                // state.item.error.validationList.push(`${data.title} is required.`);
              } else {
                data.hasError = "";
              }
            } else if (
              data.type === "checkBox" ||
              data.type === "radioButton"
            ) {
              if (data.options && data.options.length) {
                // console.log("USER's DATA FILL CHECK >>> ", data)
                const optionList: any = [];
                data.options.forEach((item: any) => {
                  // if(item.selected) return false;
                  if (item.selected) {
                    optionList.push(item);
                  }
                });
                // console.log("CHECK CHECK >>", optionList)
                if (optionList && !optionList.length) {
                  data.hasError = data.title;
                  // state.item.error.validationList.push(`${data.title} is required.`);
                } else {
                  data.hasError = "";
                }
              }
            } else if (data.type === "single_select") {
              if (data.options && data.options.length) {
                // console.log("USER's DATA FILL CHECK >>> ", data)
                if (!data.selected) {
                  data.hasError = data.title;
                  // state.item.error.validationList.push(`${data.title} is required.`);
                } else {
                  data.hasError = "";
                }
              }
            } else if (data.type === "datePicker") {
              // if(data.options && data.options.length) {
              // console.log("USER's DATA FILL CHECK >>> ", data)
              if (!data.date || !data.convertedDate) {
                data.hasError = data.title;
                // state.item.error.validationList.push(`${data.title} is required.`);
              } else {
                data.hasError = "";
              }
              // }
            }
          }
        });
      }

      return state.userFormData;
    });

    function setCanvas(elementRow: any) {
      // console.log("Canvas Data Check", elementRow.canvasName)
      elementRow.canvas = document.getElementById(`${elementRow.canvasName}`);
      // elementRow.canvas = document.getElementById("myCanvas0");
      if (elementRow.canvas) {
        elementRow.signaturePad = new SignaturePad(elementRow.canvas, {
          backgroundColor: "rgb(255, 255, 255)",
        });
      }
    }
    function canvasSet() {
      state.userFormData.forEach((element: any) => {
        if (element.type === "digitalSignature") {
          // console.log("CHECK", element)
          setCanvas(element);
        }
      });
      // state.canvas = document.getElementById("myCanvas");
      // // console.log("Canvas Data Check", state.canvas)
      // if (state.canvas) {
      //   state.signaturePad = new SignaturePad(state.canvas, {
      //     backgroundColor: "rgb(255, 255, 255)",
      //   });
      //   // console.log("Canvassignature", state.signaturePad)
      //   // window.onresize = resizeCanvas;
      //   // resizeCanvas()
      // }
    }

    function userFormClick() {
      state.userFormData = JSON.parse(JSON.stringify(state.formData.elements));
      $("#responseModal").modal("show");
      setTimeout(canvasSet, 100);
      // canvas Active
    }
    function resetheight() {
      document.querySelectorAll("textarea").forEach((element) => {
        element.style.height = "10px";
      });
    }
    function mideHeight() {
      // const element = document.getElementById("chat-body-content");
      //  element.scrollIntoView();
    }



    // messageSeenByMember
    function messageReplyThreadSeenByMember(ids: any) {
      if (routeLeaveStatus.value) return false;
      state.item.error.erorList = [];
      state.item.hasError = false;
      state.ui.isPostLoading.messageReplyThreadSeenByMember = false;
      if (state.ui.isPostLoading.messageReplyThreadSeenByMember) return false;
      const payLoad = {
        ids: ids
      }
      state.ui.isPostLoading.messageReplyThreadSeenByMember = true;
      ethitransService
        .channelMessageReplyReceived(payLoad)
        .then((res: any) => {
          // state.data.unreadMessage = Math.max(0, state.data.unreadMessage - messges.length);
          state.ui.isPostLoading.messageReplyThreadSeenByMember = false;
          // 1564
          // console.log("channelMessageReceived :>>> ", res.data.data);
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }

    function convertStringsToNumbers(array: any) {
      return array.map((item: any) => {
        return typeof item === "string" && !isNaN(item as any) ? Number(item) : item;
      });
    }

    // const getVisibleDivIdForReplyThread = () => {
    //   if (chatReplyThreadBody.value) {
    //     // const chatBodyRect = chatBody.value.getBoundingClientRect();
    //     const chatBodyHeight = chatReplyThreadBody.value.clientHeight; // Height of the container

    //     // Get the current scroll position
    //     const scrollTop = chatReplyThreadBody.value.scrollTop;
    //     const scrollBottom = scrollTop + chatBodyHeight;

    //     // Filter the <li> elements that are within the visible scroll area
    //     const visibleLis = Array.from(chatReplyThreadBody.value.querySelectorAll('div')).filter((div: any) => {
    //       const liRect = div.getBoundingClientRect();
    //       const liTop = liRect.top;
    //       const liBottom = liRect.bottom;

    //       // Return true if the <li> element is within the scrollable area
    //       return (
    //         div.id &&
    //         div.id.startsWith('reply-') && // Check if the div id starts with 'reply-'
    //         div.classList.contains('chat-block') &&
    //         liTop < scrollBottom && // <li> is above the bottom of the viewport
    //         liBottom > scrollTop    // <li> is below the top of the viewport
    //       );
    //     });
    //     console.log("CHECK THE DIVs >>> ", visibleLis)

    //     // Log the IDs of all visible <li> elements
    //     const visibleIds: any = visibleLis.map((li: any) => li.id);
    //     console.log("CHECK THE DIV ID >>> ", visibleIds)
    //     const messageIds = checkMessageStatus(state.communicationList).filter(value => convertStringsToNumbers(visibleIds).includes(value));
    //     const uniqueRepliesAsNumbers: any = [];
    //     for (const reply of visibleIds) {
    //       const number = parseInt(reply.replace('reply-', ''), 10);
    //       if (!uniqueRepliesAsNumbers.includes(number)) {
    //         uniqueRepliesAsNumbers.push(number);
    //       }
    //     }
    //     if (!uniqueRepliesAsNumbers) return false;
    //     channelMessageReplySeenByMember(uniqueRepliesAsNumbers)
    //   }
    // };

    




    function replyCommunication(data: any) {
      if(!channelStatus.value){
        channelClosedCheckNotification();
      }
      if(!channelStatus.value) return false;


      state.data.replyMessage = "";
      state.isReplyMessage = true;
      if (state.data.showReplyCommunication) {
        state.data.replyClassStatus = true;
      } else {
        state.data.replyClassStatus = !state.data.replyClassStatus;
      }


      data.responses.forEach((data: any) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        data.sentDate = common.localDateAndTime(data.sent_date);
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (data.is_edited === 1) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          data.editedUpdated = common.localDateAndTime(
            data.updated_at
          );
        }
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (data.deleted_at) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          data.deleted_at = common.localDateAndTime(data.deleted_at);
        }
      });
      // state.data.selectedReplyUser.responses = data;

      state.data.selectedReplyUser = data;
      // eslint-disable-next-line @typescript-eslint/camelcase
      state.data.selectedReplyUser.sentDate = common.localDateAndTime(
        data.sent_date
      );
      if (data.responses && data.responses.length != 0) {
        const replyIds: any = data.responses
          .filter((item: any) => item.response_by?.email != state.activeUserDetails.email && item.message_status === 0)
          .map((item: any) => item.id);

        if (replyIds && replyIds.length > 0) {
          messageReplyThreadSeenByMember(replyIds)
        }
      }
      // scrollToLastThread()
      if (data.responses && data.responses.length === 0) return false;
      setTimeout(scrollToLastThread, 500);
      // setTimeout(getVisibleDivIdForReplyThread, 500);
    }
    function replaceFirstWithTempIdZero(
      objects: any,
      newData: any
    ): any {
      const index = objects.findIndex((obj: any) => obj.tempId === 0);
      if ((parseInt(localStorage.current_user_id) == newData.user.id) && (index !== -1) && (newData.responses.length == 0)) {
        objects[index] = { ...newData };
      } else {
        const oldMessage: any = objects.find((obj: any) => obj.id === newData.id);
        if (oldMessage == undefined) {
          objects.push(newData);
        } else {
          // old code
          // if ((oldMessage.responses.length < newData.responses.length) || (oldMessage.responses[oldMessage.responses.length - 1].response_by_id == parseInt(localStorage.current_user_id))) {
          //   const index = objects.findIndex((obj: any) => obj.id === newData.id);
          //   if (index !== -1) {
          //     // Replace the object at the found index with new data
          //     objects[index] = { ...newData };
          //   }
          // }

          // new code
          if (
            oldMessage.responses.length > 0 &&
            (oldMessage.responses.length < newData.responses.length ||
              (oldMessage.responses[oldMessage.responses.length - 1].response_by_id == parseInt(localStorage.current_user_id))) &&
            oldMessage.responses[oldMessage.responses.length - 1].response_by_id !== null
          ) {
            const index = objects.findIndex((obj: any) => obj.id === newData.id);
            if (index !== -1) {
              objects[index] = { ...newData };
            }
          }

        }
      }
      return objects;

    }
    function checkInstantMessageStatus(message: any, state: any) {
      // Check if message_status is 1
      if (message?.message_status === 1) {

        if (Array.isArray(message.message_receivers) && message.message_receivers.length > 0) {
          return message.message_receivers.some((receiver: any) =>
            receiver.status === 1 && receiver.email === state.activeUserDetails.email
          );
        }
      }
      // Return false if conditions are not met
      return false;
    }
    function replaceItem(items: any, updatedItem: any): any {
      return items.map((item: any) =>
        item.id === updatedItem.id ? updatedItem : item
      );
    }
    function checkEmailAndStatus(users: any, email: string) {
      const user = users.find((user: any) => user.email === email);

      if (!user) {
        return false; // Email not found
      }

      return user.status === 0; // Return true if status is 0, otherwise false
    }
    function replaceObjectIfIdMatches(array: any, newObject: any) {
      const index = array.findIndex((obj: any) => obj.id === newObject.id);

      if (index !== -1) {
        array[index] = newObject; // Replace the existing object with the provided object
        return true; // Indicate replacement was successful
      }

      return false; // No matching object found
    }
    function checkIdIfNoMatch(array: any, newObject: any) {
      const index = array.findIndex((obj: any) => obj.id === newObject.id);

      if (index === -1) {
        return newObject.id; // Return the id if no match is found
      }

      return false; // Return false if a match is found
    }
    function replaceObjectInArray(items: any, newItem: any) {
      return items.map((item: any) => (item.id === newItem.id ? newItem : item));
    }

    //get instance message
    function getInstanceMessageList(messageId: any) {
      return false;

      // below code is commented for testing socket
      // const payLoad = {
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   communication_id: messageId,
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   channel_id: channelId,
      // };
      // ethitransService
      //   .getInstanceMessage(payLoad)
      //   .then((res: any) => {
      //     // reset the trigger of msg and reply thread send
      //     state.currentActive.msgSend = false;


      //     // check the reply thread is triggerd by me, then only trigger seenBY
      //     const replyThreadIds: any = [];
      //     const replyThreadReceiver: any = [];
      //     if (res.data.data.messages && res.data.data.messages.responses && res.data.data.messages.responses.length) {
      //       res.data.data.messages.responses.forEach((replyThread: any) => {
      //         if ((replyThread.response_by.email != state.activeUserDetails.email) && replyThread.message_status === 0) {
      //           replyThreadIds.push(replyThread.id)

      //           if ((replyThread.response_by.email != state.activeUserDetails.email) && (replyThread.message_receivers && replyThread.message_receivers.length === 0) && replyThread.message_status === 0 && state.data.replyClassStatus === true) {
      //             channelMessageReplySeenByMember([replyThread.id])
      //           }
      //         }
      //         // filter users icon here
      //         // eslint-disable-next-line @typescript-eslint/camelcase
      //         if (replyThread.message_receivers && replyThread.message_receivers.length) {
      //           replyThread.message_receivers.forEach((reply: any) => {
      //             if (((reply.email === state.activeUserDetails.email) && reply.status === 1 && state.data.replyClassStatus === true) || (reply.email === state.activeUserDetails.email) && reply.status === 0 && state.data.replyClassStatus === true) {
      //               replyThreadReceiver.push(replyThread.id);
      //             }
      //           })

      //           // eslint-disable-next-line @typescript-eslint/camelcase
      //           replyThread.message_receivers = filterMessageReceivers(replyThread.message_receivers);
      //           // eslint-disable-next-line @typescript-eslint/camelcase
      //           replyThread.message_receivers = filterSenderForReplyThread(replyThread.message_receivers, replyThread.response_by.email);
      //         }

      //       })
      //     }

      //     // for the reply thread open then check them as seen

      //     // if (replyThreadIds && replyThreadIds.length) {
      //     //   state.notAllowdedThreadSeen = true;
      //     //   channelMessageReplySeenByMember(replyThreadIds)
      //     //   state.currentActive.replySend = false;
      //     // }

      //     if (replyThreadReceiver && replyThreadReceiver.length) {
      //       console.log("RECEIVERS LIST >>> ", replyThreadReceiver)
      //       //   // state.notAllowdedThreadSeen = true;
      //       channelMessageReplySeenByMember(replyThreadReceiver)
      //       //   state.currentActive.replySend = false;
      //     }
      //     // console.log("instancemessage", res.data.data.messages);
      //     res.data.data.messages.messageSendStatus = false;
      //     res.data.data.messages.editStatus = true

      //     res.data.data.messages.attachments.forEach((data: any) => {
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       data.created_at = common.localDateAndTime(data.created_at);
      //       // eslint-disable-next-line @typescript-eslint/camelcase
      //       data.deleted_at = data.deleted_at ? common.localDateAndTime(data.deleted_at) : null;
      //       data.convertedFileSize = common.formatBytes(parseInt(data.file_size));

      //     });
      //     res.data.data.messages.currentTime = common.localDateAndTime(res.data.data.messages.sent_date);
      //     if (state.data.selectedReplyUser.id && state.data.selectedReplyUser.responses && state.data.selectedReplyUser.responses.length) {
      //       if ((state.data.selectedReplyUser.id == res.data.data.messages.id)) {
      //         if (state.data.selectedReplyUser.responses.length > 0) {
      //           // eslint-disable-next-line @typescript-eslint/camelcase
      //           const own_reply_new = res.data.data.messages.responses.filter(function (reply: any) {
      //             // eslint-disable-next-line @typescript-eslint/camelcase
      //             return (reply.response_by_id == parseInt(localStorage.current_user_id));
      //           });
      //           // eslint-disable-next-line @typescript-eslint/camelcase
      //           const own_reply_old = state.data.selectedReplyUser.responses.filter(function (reply: any) {
      //             // eslint-disable-next-line @typescript-eslint/camelcase
      //             return (reply.response_by_id == parseInt(localStorage.current_user_id));
      //           });
      //           // eslint-disable-next-line @typescript-eslint/camelcase
      //           if (own_reply_new.length == own_reply_old.length) {
      //             state.data.selectedReplyUser.responses = res.data.data.messages.responses;
      //           } else if (state.data.selectedReplyUser.responses.length != res.data.data.messages.responses.length) {
      //             const responses = res.data.data.messages.responses;
      //             responses.push(state.data.selectedReplyUser.responses[state.data.selectedReplyUser.responses.length - 1]);
      //             state.data.selectedReplyUser.responses = responses;
      //           }
      //           //reply thread check and scroll at last
      //           scrollToLastThread();
      //         }
      //         // if(state.scrollThreadActive){
      //         // }
      //       }
      //       console.log("CHECK 2")
      //       replaceFirstWithTempIdZero(state.communicationList, res.data.data.messages);
      //     } else {
      //       console.log("CHECK 1")
      //       if (res.data.data.messages.message_status === 0 && (res.data.data.messages && res.data.data.messages.user.email != state.activeUserDetails.email)) {
      //         console.log("<<< Message Receiver >>>")

      //         console.log("Delivered");
      //         let ids: any = [];
      //         ids.push(res.data.data.messages.id)
      //         messageReceived(ids);
      //         replaceObjectByIdInArray(res.data.data.messages, res.data.data.messages, state.communicationList);
      //         ids = [];
      //       } else if (res.data.data.messages.message_status === 1 && (res.data.data.messages && res.data.data.messages.user.email != state.activeUserDetails.email)) {
      //         const user = {
      //           activeUserDetails: { email: state.activeUserDetails.email }
      //         };
      //         console.log("BEFORE SEEN CHECK  the >>> ", checkInstantMessageStatus(res.data.data.messages, user))
      //         // check if that message is for me or not then only trigger the seen
      //         if (!checkInstantMessageStatus(res.data.data.messages, user)) return false;
      //         console.log("SEEN");
      //         const ids: any = [];
      //         ids.push(res.data.data.messages.id)
      //         messageSeenByMember(ids);
      //         // ids = [];

      //       }
      //       replaceFirstWithTempIdZero(state.communicationList, res.data.data.messages);
      //       // if (res.data.data.messages.message_status === 0 && (res.data.data.messages && res.data.data.messages.user.email != state.activeUserDetails.email)) {
      //       state.communicationList.forEach((item: any) => {
      //         // console.log("MSG >>> ", item)
      //         if (res.data.data.messages.id === item.id) {
      //           // console.log("Match Found")
      //           // eslint-disable-next-line @typescript-eslint/camelcase
      //           res.data.data.messages.message_receivers = res.data.data.messages.message_receivers.filter((receiver: any) => receiver.email !== state.activeUserDetails.email);
      //           // eslint-disable-next-line @typescript-eslint/camelcase
      //           item.message_receivers = res.data.data.messages.message_receivers;
      //           item.responses = res.data.data.messages.responses;
      //           // eslint-disable-next-line @typescript-eslint/camelcase
      //           item.responses_count = res.data.data.messages.responses_count;
      //           // eslint-disable-next-line @typescript-eslint/camelcase
      //           item.message_status = res.data.data.messages.message_status;
      //           item.user = res.data.data.messages.user;
      //           // state.data.selectedReplyUser.responses_count = res.data.data.messages.responses.length
      //         }
      //       })
      //       //}

      //     }

      //     // this is for not allow to scroll when scrolled
      //     // if (!state.scrollActive) {
      //     //   setTimeout(scrollToLast, 500);
      //     // }

      //     // res.data.data.messages.data.forEach((item: any) => {
      //     // if (item.message_receivers && item.message_receivers.length) {

      //     // }
      //     // })
      //   })
      //   .catch((error: any) => {
      //     console.log(error);
      //   })
    }


    function replyCounter(replies: any, regex: any) {
      if (replies && replies.length > 0) {
        replies.forEach((item: any) => {
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (item.message || item.file_name) {
            console.log("replymessage", item.message)
            // eslint-disable-next-line @typescript-eslint/camelcase
            const result = (item.message && item.message.match(regex)) || (item.file_name && item.file_name.match(regex));
            if (result) {
              state.totalSearchResultCount = state.totalSearchResultCount + result.length;
              replies.matchCount = state.totalSearchResultCount;
            }
          }
        })
      }

    }

    function shareFileCounter(shareFiles: any, regex: any) {
      console.log("imomsss", shareFiles);
      if (shareFiles && shareFiles.length > 0) {
        console.log("imom", shareFiles);
        shareFiles.forEach((item: any) => {
          item.documents.forEach((file: any) => {
            if (file.filename) {
              const result = file.filename.match(regex);
              if (result) {
                state.totalSearchResultCount = state.totalSearchResultCount + result.length;

              }
              file.filename = file.filename.replace(regex, '<mark class="highlight">$&</mark>');
            }
          })
        })
      }

    }
    function injectChatMessages(channelID: any, pageNumber: any) {
      console.log("<<INJECTION ON>>")
      state.ui.showLoader = true;
      state.data.loadedPage = pageNumber;
      state.item.error.erorList = [];
      state.item.hasError = false;
      if (state.postLoading) return false;
      if (state.ui.isPostLoading.injectChatMessages) return false;


      const payLoad: any = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelID,
        user: localStorage.getItem("current_user_role"),
        page: pageNumber,
      };

      console.log("Unshift Payload >>>>", payLoad)
      state.ui.isPostLoading.injectChatMessages = true;
      state.ui.isLoading.loaded = false;
      ethitransService
        // .createCommunicationList(payLoad)
        .createCommunicationList(payLoad)
        .then(async (res: any) => {
          state.ui.isPostLoading.loadInjectChatMessages = true;
          state.ui.isPostLoading.injectChatMessages = false;
          state.postLoading = false;
          state.data.injectChatMessages = true;
          // res.data.data.messages.data.forEach((message: any)=> {
          //   if(message.message_receivers && message.message_receivers.length > 0){
          //     message.message_receivers = filterMessageReceivers(message.message_receivers);
          //   }
          // })
          res.data.data.messages.data.forEach((message: any) => {
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (message.message_receivers && message.message_receivers.length > 0) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              message.message_receivers = filterMessageReceivers(message.message_receivers);
            }
            if (message.responses && message.responses.length > 0) {
              message.responses.forEach((reply: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                reply.message_receivers = filterMessageReceivers(reply.message_receivers);
              })


            }
          })
          state.data.unreadMessage = res.data.data.unread_count ? res.data.data.unread_count : 0;
          let arrayMessageDeliveredIds: any = [];

          state.ui.isLoading.loaded = true;
          // state.data.channelDetails = res.data.data

          state.data.subscriptionDetails = res.data.data.package_features;
          state.isPublicUser = res.data.data.is_public_user;

          const comID: any = routerQuery.query.communication_id;
          const communicationIdInQuery: any = routerQuery.query.communicationId;

          res.data.data.messages.data.forEach((item: any) => {
            // if ((item.user.email != localStorage.getItem("current_user_email")) && item.message_status === 0) {
            //   arrayMessageDeliveredIds.push(item.id)
            // }
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (item.message_receivers && item.message_receivers.length) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.message_receivers = moveSenderToEnd(item.message_receivers);
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.message_receivers.forEach((message: any) => {
                // console.log("Login User >>> ", localStorage.getItem("current_user_email"));
                // console.log("Members >>> ", message.email)
                if (message.email === localStorage.getItem("current_user_email")) {
                  message.sender = true;
                } else {
                  message.sender = false;
                }
              })
            }

            // eslint-disable-next-line @typescript-eslint/camelcase
            if (routerQuery.query.communicationId) {
              state.data.isCommunicationFile =
                routerQuery.query.communicationId;
              //  document.getElementById(state.data.isCommunicationFile).scrollIntoView();
              //  const element: any= document.getElementById(state.data.isCommunicationFile);
              //  element.scrollIntoView();
            }

            if (parseInt(comID) === item.id) {
              state.data.showReplyCommunication = true;
              replyCommunication(item);

              console.log("message in in communicationList", item);
            }

            if (
              parseInt(communicationIdInQuery) === item.id &&
              routerQuery.query.reply
            ) {
              state.data.showReplyCommunication = true;
              state.data.isCommunicationReplyFile = routerQuery.query.reply;
              replyCommunication(item);

              // console.log("message in in communicationList", item);
            }
            //      if(parseInt(comFIleID) === item.id){
            //     state.data.isCommunicationFile=true;
            //  }
            item.responses.forEach((response: any) => {
              response.createdLatest = common.localDateAndTime(
                response.created_at
              );
            });
            if (item.forward && item.forward.communication && item.forward.communication.responses) {
              item.forward.communication.responses.forEach((response: any) => {
                response.createdLatest = common.localDateAndTime(
                  response.created_at
                );
              });
            }

            item.editedUpdated = common.localDateAndTime(item.updated_at);
            if (item.forward && item.forward.communication) {
              item.forward.communication.editedUpdated = common.localDateAndTime(item.forward.communication.updated_at);
            }
            item.allowEdit = false;
            item.allowDelete = false;
            if (item.share_history && item.share_history.length) {
              item.share_history.forEach((sharedHistory: any) => {
                sharedHistory.documents.forEach((doc: any) => {
                  if (doc.filetype === "mov" || doc.filetype === "MOV") {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    doc.filetype = "mp4";
                  }
                  doc.file.convertedFileSize = common.formatBytes(
                    parseInt(doc.file.filesize)
                  );
                });
              });
            }
          });


          // console.log("<<< IDS COLLECTION >>>", arrayMessageDeliveredIds)
          res.data.data.messages.data.forEach((data: any) => {
            // console.log("DATE >>> ", data.sent_date);
            data.currentTime = common.localDateAndTime(data.sent_date);
            // eslint-disable-next-line @typescript-eslint/camelcase
            data.deletedLocalTime = data.deleted_at ? common.localDateAndTime(data.deleted_at) : null;
            if (data.attachments) {
              data.attachments.forEach((video: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                if (video.file_type === "mov" || video.file_type === "MOV") {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  video.file_type = "mp4";
                }
              });

              // console.log("Data Check >>> ", data)
            }
          });
          checkMessageStatus(res.data.data.messages.data);
          // console.log("messagecheckbyom >>> ", state.communicationList);
          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            //  state.data.scrollTop = false;
          }
          let admin: any = {};

          if (
            res.data.data.project_role.level === 1 &&
            res.data.data.project_role.code === "owner"
          ) {
            admin = res.data.data.project_role;
          } else {
            if (
              res.data.data.project_role.level === 2 &&
              res.data.data.project_role.code === "co-owner"
            ) {
              admin = res.data.data.project_role;
            }
          }
          const filteredItems = res.data.data.channel.members.filter((mem: any) => mem.user.email === state.email && mem.is_mute == 1);
          console.log('fimt0: ', filteredItems)
          state.isMute = filteredItems.length > 0 ? true : false;

          const channelMember: any = [];
          res.data.data.channel.members.forEach((member: any) => {
            channelMember.push(member.user.full_name);
            member.isChangeRole = false;
            if (admin && admin.level && admin.code) {
              if (member.user && member.user.email) {
                if (member.user.email !== state.email) {
                  member.isChangeRole = true;
                  // console.log("CHECK MEMBER >>> ", member.user);
                }
              }
            }
          });
          // console.log("FINAL MEMBERLIST CHECK", channelMember);
          setMentionMembers(channelMember);

          state.memberList = res.data.data.channel.members
            ? res.data.data.channel.members
            : [];

          // state.messages = res.data.data
          state.data.projectMember = res.data.data.project_member
            ? res.data.data.project_member
            : {};
          // console.log("Date Validity Check >>> ", state.data.projectMember.validity_date)
          state.channel = res.data.data.channel ? res.data.data.channel : {};
          state.pendingMembersCount = res.data.data.pending_members_count
            ? res.data.data.pending_members_count
            : 0;
          state.approvedMembersCount = res.data.data.approved_members_count
            ? res.data.data.approved_members_count
            : 0;
          state.pendingList = res.data.data.pending_members
            ? res.data.data.pending_members
            : [];
          state.userRole = res.data.data.project_role
            ? res.data.data.project_role
            : {};
          state.projectId = res.data.data.channel.project.uuid
            ? res.data.data.channel.project.uuid
            : "";
          // state.projectId = res.data.data.channel.project_id
          //   ? res.data.data.channel.project_id
          //   : 0;
          res.data.data.messages.data.forEach((item: any) => {
            if (item.form_data != null) {
              // console.log("check msg :>>", item.form_data);
              if (item.form_data && item.form_data.sent_to_users) {
                // console.log( "Type check", item.form_data.sent_to_users)
                if (typeof item.form_data.sent_to_users === "string") {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  item.form_data.sent_to_users = JSON.parse(
                    item.form_data.sent_to_users
                  );
                }
                // console.log(
                //   "Final :>>",
                //   JSON.parse(item.form_data.sent_to_users)
                // );
                // eslint-disable-next-line @typescript-eslint/camelcase
              }
            }
          });

          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((data: any) => {
              if (data.forward && data.forward.attachments.length) {
                data.forward.attachments.forEach((attachment: any) => {
                  attachment.convertedFileSize = common.formatBytes(
                    parseInt(
                      attachment.file_size
                    )
                  );
                });
              }
            });
          }

          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((data: any) => {
              data.allowEdit = false;
              if (data.attachments && data.attachments.length) {
                data.attachments.forEach((attachment: any) => {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  attachment.deleted_at = attachment.deleted_at ? common.localDateAndTime(attachment.deleted_at) : null;
                  attachment.convertedFileSize = common.formatBytes(
                    parseInt(
                      attachment.file_size
                      // parseInt(data.file_size.split(" ")[0])
                    )
                  );
                });
              }
              const result: any = [];
              const map = new Map();
              data.responses.forEach((item: any) => {
                if (!map.has(item.response_by_id)) {
                  map.set(item.response_by_id, true); // set any value to Map
                  result.push(item);
                }
              });
              data.editStatus = result;

              // console.log("FINAL CHECK", result)
              // for (const item of array) {

              // }
              //             data.uniqueResponse = data.responses.map((item: any) => {item.response_by_id})
              // .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
              // data.responses.forEach((item: any) => {
              //   item.uniqueUsers = [];
              //   item.response_by

              // })
            });
          }
          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((item: any) => {
              if (item.user && item.user.email) {
                if (
                  item.user.email === localStorage.getItem("current_user_email")
                ) {
                  // console.log("email", item.user.email);
                  // console.log("active email", state.data.activeUserEmail);
                  item.editStatus = true;
                } else {
                  item.editStatus = false;
                }
              }
            });
          }

          state.totalSearchResultCount = 0
          res.data.data.messages.data.forEach(async (item: any) => {
            const searchText = state.data.search;
            const regex = new RegExp(searchText, 'gi');
            if (state.data.search && item.attachments.length && item.message) {

              item.attachments.forEach((item2: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                const result0 = item2.file_name.match(regex);
                console.log("this is result 0", result0);
                if (result0) {
                  state.totalSearchResultCount = state.totalSearchResultCount + result0.length;

                }
                // eslint-disable-next-line @typescript-eslint/camelcase
                item2.file_name = item2.file_name.replace(regex, '<mark class="highlight">$&</mark>');
                if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                  item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
                }
              })
              const result1 = item.message.match(regex);
              if (result1) {
                state.totalSearchResultCount = state.totalSearchResultCount + result1.length;
              }
              item.message = item.message.replace(regex, '<mark class="highlight">$&</mark>');
              if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
              }
              console.log("fow", item.forward.communication.message)
              await replyCounter(item.responses, regex);
              // eslint-disable-next-line @typescript-eslint/camelcase
              await shareFileCounter(item.share_history, regex);

            }

            if (state.data.search && item.attachments.length) {
              item.attachments.forEach((item2: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                const result2 = item2.file_name.match(regex);
                if (result2) {
                  state.totalSearchResultCount = state.totalSearchResultCount + result2.length;
                }
                // eslint-disable-next-line @typescript-eslint/camelcase
                item2.file_name = item2.file_name.replace(regex, '<mark class="highlight">$&</mark>');
                if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                  item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
                }
              })

              await replyCounter(item.responses, regex);
              // eslint-disable-next-line @typescript-eslint/camelcase
              await shareFileCounter(item.share_history, regex);

            }

            if (state.data.search && !item.attachments.length) {
              const result3 = item.message && item.message.match(regex) || item.forward.communication.message.match(regex);
              if (result3) {
                state.totalSearchResultCount = state.totalSearchResultCount + result3.length;
                // item.message = item.message.replace(/(<mark class="highlight">|<\/mark>)/gim, '');
                item.message = item.message.replace(regex, '<mark class="highlight">$&</mark>');
                if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                  item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
                }

              }

              await replyCounter(item.responses, regex);
              // eslint-disable-next-line @typescript-eslint/camelcase
              await shareFileCounter(item.share_history, regex);

            }

          });

          // unshift the msg in the current messages
          res.data.data.messages.data =
            res.data.data.messages.data.reverse();
          res.data.data.messages.data.forEach((data: any) => {
            state.communicationList.unshift(data);
          });

          console.log("IDs for Delivered >>>", arrayMessageDeliveredIds)
          // messageReceived(res.data.data.messages.data);
          if (arrayMessageDeliveredIds && arrayMessageDeliveredIds.length != 0) {
            // messageDeliveryIds.value = arrayMessageDeliveredIds;
            messageReceived(arrayMessageDeliveredIds);
            arrayMessageDeliveredIds = [];
          }
          state.ui.showLoader = false;

        })
        .catch((error: any) => {
          state.data.scrollTop = false;
          // console.log("message", error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          const visibleMessages = Array.from(document.querySelectorAll('.message')).filter((element: any) => {
            // console.log("ELEMENTS CHECL >>> ", element)
            // const itemElement = document.getElementById(`item-${element.id}`);
            // console.log("CHECK THE ITEMS >>> ")
            // Check if the element is visible
            const style = window.getComputedStyle(element);
            return style.display !== 'none' && style.visibility !== 'hidden' && element.offsetHeight > 0 && element.offsetWidth > 0;
          });

          // Select the container you want to track (e.g., the window or a specific scrollable element)
          const scrollContainer = document.documentElement || document.body;

          let scrollTimeout: any;

          // Function to get the scroll position as a percentage of total scrollable height
          const getScrollPosition = () => {
            state.isManualScroll = true;
            const scrollTop = scrollContainer.scrollTop;
            const scrollHeight = scrollContainer.scrollHeight;
            const clientHeight = scrollContainer.clientHeight;

            // Calculate scroll position as a percentage
            const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

            window.clearTimeout(scrollTimeout);

            scrollTimeout = setTimeout(function () {
              state.isManualScroll = false;
              // Perform any additional actions after the scroll stop is detected
            }, 200); // Delay of 200ms (adjust as needed)
            // console.log(`Scroll Position: ${scrollPercentage.toFixed(2)}%`);
          };

          // Add a scroll event listener
          scrollContainer.addEventListener('scroll', getScrollPosition);


          if (!state.firstScroll) {
            scrollToLast();
          } else {
            state.data.scrollTop = false;
            if (!state.messageStatus) {
              scrollToTop();
            } else {
              scrollToLast();
            }
          }
        });
    }
    // checks the msg screen Filled or not if yes then only triggers again
    const checkMessageToFillChatScroll = (totalHeight: any, chatScreenHeight: any) => {

      // console.log("<<< I AM IN HERE >>> ");
      const msgDetails: any = state.data.channelDetails;
      let activePage = JSON.parse(JSON.stringify(msgDetails.messages.current_page ? msgDetails.messages.current_page : 0));
      const totalPage = msgDetails.messages.last_page ? msgDetails.messages.last_page : 0;

      //check the loaded message reaches the max height or not
      // console.log("<li> Tag Total height >>> ", totalHeight);
      // console.log("Screen Msg Height >> ", chatScreenHeight)
      // console.log("Active page >> ", activePage)
      // console.log("Last Page >> ", totalPage)
      // if(state.ui.isPostLoading.loadInjectChatMessages) return false;
      if (state.ui.isPostLoading.loadInjectChatMessages) return false;
      // console.log("IN HERE")
      if (totalHeight > chatScreenHeight) return false;
      if ((activePage === totalPage) && totalPage > 1) {

        // console.log("CHECK THE DATA >>>", activePage)
        // if its the last page and have less message the trigger the previous message
        activePage--
        // console.log("CHECK THE DATA Again >>>", activePage)
        injectChatMessages(channelId, activePage);
      }
      // state.ui.showLoader = false;
      // injectChatMessages

      // injectChatMessages()
      // maintain only current, past and previous 1 page each


    }
    const hasCommonNumber = (array1: any, array2: any) => {
      return array2.some((number: any) => array1.includes(number));
    }
    const scrollToLiById = (liId: any) => {
      const chatBodyContent = document.getElementById('chat-body-content');

      if (!chatBodyContent) {
        console.error('Element with id "chat-body-content" not found.');
        return;
      }

      const liElement = document.getElementById(liId);

      if (!liElement) {
        console.error(`Element with id "${liId}" not found.`);
        return;
      }

      // Get the height of the `<li>` element
      const liHeight = liElement.offsetHeight;

      // Get the top position of the `<li>` relative to the container
      const liTop = liElement.offsetTop;

      // Scroll the container to position the `<li>` at the top of the visible area
      chatBodyContent.scrollTop = liTop;

      // console.log(`Height of <li> with id "${liId}":`, liHeight);
      // console.log(`Scrolled to position of <li> with id "${liId}":`, liTop);

      if (state.data.goToAttachmentRow) {
        state.data.goToAttachmentRow = false;
      }


      return { liHeight, liTop };
    };

    const getLiIdsBasedOnScroll = () => {
      const chatBodyContent = document.getElementById('chat-body-content');

      if (!chatBodyContent) {
        console.error('Element with id "chat-body-content" not found.');
        return;
      }

      const { scrollTop, scrollHeight, clientHeight } = chatBodyContent;
      const scrollBottom = scrollTop + clientHeight;

      console.log('Scroll Position:', { scrollTop, scrollBottom, scrollHeight });
      if (scrollBottom === scrollHeight) {
        state.newMessageStatus = true;
      } else {
        state.newMessageStatus = false;
      }

      // Fetch all <li> elements
      const allLis = Array.from(chatBodyContent.querySelectorAll('li')) as HTMLElement[];

      let collectiveHeight = 0;

      // Filter <li> elements within the visible scroll range
      const visibleLis = allLis.filter((li) => {
        const { top, bottom, height } = li.getBoundingClientRect();
        const parentTop = chatBodyContent.getBoundingClientRect().top;

        // Adjust `top` and `bottom` to relative position within the container
        const relativeTop = top - parentTop + scrollTop;
        const relativeBottom = bottom - parentTop + scrollTop;

        const isVisible = relativeBottom > scrollTop && relativeTop < scrollBottom;

        // If visible, add to the collective height
        if (isVisible) {
          collectiveHeight += height;
        }

        return isVisible;
      });

      // Map the visible <li> elements to their IDs
      const visibleIds = visibleLis
        .map((li) => li.id)
        .filter((id) => id && !isNaN(Number(id))) // Ensure IDs are valid and numeric
        .map(Number); // Convert IDs to numbers

      // console.log('Visible <li> IDs:', visibleIds);
      // console.log('Collective Height of Visible <li> Elements:', collectiveHeight);

      return { visibleLis, visibleIds, collectiveHeight };
    };

    const createIncrementalObjects = () => {
      let currentId = 0; // Keeps track of the last `id`

      const randomWords = [
        "Hello World",
        "Random Text",
        "Test Message",
        "Quick Brown",
        "Lazy Dog",
        "Foo Bar",
        "Lorem Ipsum",
        "Nice Day",
        "Good Luck",
        "Code Time"
      ];

      return function generateObjects(count = 1) {
        const result = [];
        for (let i = 0; i < count; i++) {
          currentId++;
          const randomText = randomWords[Math.floor(Math.random() * randomWords.length)];
          result.push({
            id: currentId,
            message: `ID: ${currentId} - ${randomText}`
          });
        }
        return result;
      };
    }






    // Method to get the ID of the <li> at the current scroll position
    const getVisibleLiId = () => {
      const { visibleIds, collectiveHeight }: any = getLiIdsBasedOnScroll();
      // console.log('Visible IDs:', visibleIds);
      console.log('Visible IDs Total Height:', collectiveHeight);



      if (chatBody.value) {
        // const chatBodyRect = chatBody.value.getBoundingClientRect();
        const chatBodyHeight = chatBody.value.clientHeight; // Height of the container

        console.log("HEIGHT >>> ", chatBodyHeight)

        // Get the current scroll position
        const scrollTop = chatBody.value.scrollTop;
        const scrollBottom = scrollTop + chatBodyHeight;

        console.log("TOP >>> ", chatBodyHeight)
        console.log("Bottom >>> ", scrollBottom)


        // scroll position check 

        // // Filter the <li> elements that are within the visible scroll area
        // const visibleLis = Array.from(chatBody.value.querySelectorAll('li')).filter((li: any) => {
        //   const liRect = li.getBoundingClientRect();
        //   const liTop = liRect.top;
        //   const liBottom = liRect.bottom;


        //   // Return true if the <li> element is within the scrollable area
        //   return (
        //     li.id &&
        //     liTop < scrollBottom && // <li> is above the bottom of the viewport
        //     liBottom > scrollTop    // <li> is below the top of the viewport
        //   );
        // });

        let scrollHeight = 0;

        const chatBodyContent = document.getElementById('chat-body-content');
        if (chatBodyContent) {
          scrollHeight = chatBodyContent.scrollHeight;
          state.data.screenHeight = scrollHeight;
          // console.log(`Scroll Height: ${scrollHeight}px`);
        } else {
          console.error('Element with id "chat-body-content" not found.');
        }

        // console.log("HEIGHT CHECK OF CHANNEL >>> ", scrollHeight)

        // send the <li> total Height & Chat Channel Height
        checkMessageToFillChatScroll(collectiveHeight, scrollHeight);



        const idsCollectionForUser = getIdsofNotMyMessage(visibleIds, getIdsForActiveUser(state.communicationList))

        // Only proceed if messageIds has length

        if (visibleIds.length > 0) {
          console.log("IDS >>> ", visibleIds)

          console.log("USERs Ids >>> ",)
          const ids = state.data.lastPageMessages.map(function (item: any) {
            return item.id;
          });

          const uniqueIds = ids.filter(function (id: any, index: any, self: any) {
            return self.indexOf(id) === index;
          });
          // check the ids is of last page or not
          if (hasCommonNumber(visibleIds, uniqueIds)) {
            state.data.isAllowToGoLatest = true;
          } else {
            state.data.isAllowToGoLatest = false;
          }

          // myMessageList();
          // if (idsCollectionForUser.length > 0) {
          //   // Call messageSeenByMember only if visibleIds has valid data and matches with the API response that is of member's or not.If yes then hit with that collection
          //   messageSeenByMember(idsCollectionForUser);
          messageSeenByMember(visibleIds);

          //   state.scrollActive = false;
        } else {
          console.log('No valid message IDs found');
        }

      }
    };
    const onScrollStopped = () => {
      console.log('Scrolling stopped');
      if (!state.allowScrollAction) return false;
      setTimeout(() => {
        // Triggered when scroll has stopped
        getVisibleLiId();
      }, 500);
      // Your logic when scrolling stops goes here
    };
    function scrollToChatItem(matchId: any) {
      const chatBodyContent = document.getElementById('chat-body-content');

      if (chatBodyContent) {
        // Find the <li> tag with the matching ID
        const targetItem: any = chatBodyContent.querySelector(`li[id="${matchId}"]`);

        if (targetItem) {
          // Get the offsetTop (distance from the top of its parent container)
          const scrollHeight = targetItem.offsetTop;

          // Scroll the container to the target item's position
          chatBodyContent.scrollTo({
            top: scrollHeight,
            behavior: 'smooth' // Optional for a smooth scrolling effect
          });

          console.log(`Scrolled to item with ID: ${matchId}, Scroll Height: ${scrollHeight}`);
        } else {
          console.error(`No item found with ID: ${matchId}`);
        }
      } else {
        console.error('Element with ID "chat-body-content" not found.');
      }
    }

    // Example usage:
    scrollToChatItem(3); // Scrolls to <li id="3">

    function loadLatestMessages(latestClicked: any) {
      state.ui.isActive.latestMessageTriggered = true;
      state.item.error.erorList = [];
      state.item.hasError = false;
      state.ui.isPostLoading.loadLatestMessages = false;


      if (state.ui.isPostLoading.onScrollStopped) return false;
      if (state.ui.isPostLoading.loadLatestMessages) return false;


      let page: any = 0;
      if (!latestClicked) {
        page = state.data.page;
      }
      const payLoad: any = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,

        user: localStorage.getItem("current_user_role"),
        // eslint-disable-next-line @typescript-eslint/camelcase
        // communication_id: rmessageSeenByMemberouterQuery.query.communicationId
        //   ? routerQuery.query.communicationId
        //   : "",
      };
      if (latestClicked === 1) {
        state.ui.isPostLoading.loadInjectChatMessages = false;
        // eslint-disable-next-line @typescript-eslint/camelcase
        payLoad.latest = latestClicked;
        payLoad.page = state.data.channelDetails.messages.last_page;
      } else {
        payLoad.page = state.data.page;
      }
      if (state.data.search.length > 0) {
        payLoad.search = state.data.search;
      }
      state.ui.isPostLoading.loadLatestMessages = true;
      ethitransService
        // .createCommunicationList(payLoad)
        .createCommunicationList(payLoad)
        .then(async (res: any) => {
          // state.data.loadedPages
          if (latestClicked === 1) {
            // state.communicationList = [];
            if (res.data.data.messages.last_page == res.data.data.messages.current_page) {
              state.data.page = res.data.data.messages.current_page;
            }
          }

          res.data.data.messages.data.forEach((message: any) => {
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (message.message_receivers && message.message_receivers.length > 0) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              message.message_receivers = filterMessageReceivers(message.message_receivers);
            }
            if (message.responses && message.responses.length > 0) {
              message.responses.forEach((reply: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                reply.message_receivers = filterMessageReceivers(reply.message_receivers);
              })


            }
          })
          state.data.unreadMessage = res.data.data.unread_count ? res.data.data.unread_count : 0;
          let arrayMessageDeliveredIds: any = [];

          state.ui.isLoading.loaded = true;
          state.data.channelDetails = res.data.data
          state.data.subscriptionDetails = res.data.data.package_features;
          state.data.currentPage = res.data.data.messages.current_page;
          state.data.lastPage = res.data.data.messages.last_page;
          state.isPublicUser = res.data.data.is_public_user;
          // console.log("before", res.data.data.messages.data);

          if (res.data.data.messages.current_page === res.data.data.messages.last_page) {
            state.data.lastPageMessages = res.data.data.messages.data;
          }
          if (latestClicked === 1) {
            state.communicationList = [];
            onScrollStopped();

          }

          res.data.data.messages.data =
            await res.data.data.messages.data.reverse();
          // console.log("After", res.data.data.messages.data);

          const comID: any = routerQuery.query.communication_id;
          const communicationIdInQuery: any = routerQuery.query.communicationId;

          res.data.data.messages.data.forEach((item: any) => {
            if ((item.user.email != localStorage.getItem("current_user_email")) && item.message_status === 0) {
              arrayMessageDeliveredIds.push(item.id)
            }
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (item.message_receivers && item.message_receivers.length) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.message_receivers = moveSenderToEnd(item.message_receivers);
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.message_receivers.forEach((message: any) => {
                // console.log("Login User >>> ", localStorage.getItem("current_user_email"));
                // console.log("Members >>> ", message.email)
                if (message.email === localStorage.getItem("current_user_email")) {
                  message.sender = true;
                } else {
                  message.sender = false;
                }
              })
            }

            // eslint-disable-next-line @typescript-eslint/camelcase
            if (routerQuery.query.communicationId) {
              state.data.isCommunicationFile =
                routerQuery.query.communicationId;
              // state.data.scrolledPages.push(state.data.page);

              //  document.getElementById(state.data.isCommunicationFile).scrollIntoView();
              //  const element: any= document.getElementById(state.data.isCommunicationFile);
              //  element.scrollIntoView();
            }

            if (parseInt(comID) === item.id) {
              state.data.showReplyCommunication = true;
              replyCommunication(item);

              console.log("message in in communicationList", item);
            }

            if (
              parseInt(communicationIdInQuery) === item.id &&
              routerQuery.query.reply
            ) {
              state.data.showReplyCommunication = true;
              state.data.isCommunicationReplyFile = routerQuery.query.reply;
              replyCommunication(item);

              // console.log("message in in communicationList", item);
            }
            //      if(parseInt(comFIleID) === item.id){
            //     state.data.isCommunicationFile=true;
            //  }
            item.responses.forEach((response: any) => {
              response.createdLatest = common.localDateAndTime(
                response.created_at
              );
            });
            if (item.forward && item.forward.communication && item.forward.communication.responses) {
              item.forward.communication.responses.forEach((response: any) => {
                response.createdLatest = common.localDateAndTime(
                  response.created_at
                );
              });
            }

            item.editedUpdated = common.localDateAndTime(item.updated_at);
            if (item.forward && item.forward.communication) {
              item.forward.communication.editedUpdated = common.localDateAndTime(item.forward.communication.updated_at);
            }
            item.allowEdit = false;
            item.allowDelete = false;
            if (item.share_history && item.share_history.length) {
              item.share_history.forEach((sharedHistory: any) => {
                sharedHistory.documents.forEach((doc: any) => {
                  if (doc.filetype === "mov" || doc.filetype === "MOV") {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    doc.filetype = "mp4";
                  }
                  doc.file.convertedFileSize = common.formatBytes(
                    parseInt(doc.file.filesize)
                  );
                });
              });
            }
          });
          // messageReceived(res.data.data.messages.data);
          if (arrayMessageDeliveredIds && arrayMessageDeliveredIds.length != 0) {
            // messageDeliveryIds.value = arrayMessageDeliveredIds;
            messageReceived(arrayMessageDeliveredIds);
            arrayMessageDeliveredIds = [];
          }

          // console.log("<<< IDS COLLECTION >>>", arrayMessageDeliveredIds)
          res.data.data.messages.data.forEach((data: any) => {
            // console.log("DATE >>> ", data.sent_date);
            data.currentTime = common.localDateAndTime(data.sent_date);
            // eslint-disable-next-line @typescript-eslint/camelcase
            data.deletedLocalTime = data.deleted_at ? common.localDateAndTime(data.deleted_at) : null;
            if (data.attachments) {
              data.attachments.forEach((video: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                if (video.file_type === "mov" || video.file_type === "MOV") {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  video.file_type = "mp4";
                }
              });

              // console.log("Data Check >>> ", data)
            }
          });
          checkMessageStatus(res.data.data.messages.data);

          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            //  state.data.scrollTop = false;
          }

          let admin: any = {};

          if (
            res.data.data.project_role.level === 1 &&
            res.data.data.project_role.code === "owner"
          ) {
            admin = res.data.data.project_role;
          } else {
            if (
              res.data.data.project_role.level === 2 &&
              res.data.data.project_role.code === "co-owner"
            ) {
              admin = res.data.data.project_role;
            }
          }
          const filteredItems = res.data.data.channel.members.filter((mem: any) => mem.user.email === state.email && mem.is_mute == 1);
          console.log('fimt0: ', filteredItems)
          state.isMute = filteredItems.length > 0 ? true : false;

          const channelMember: any = [];
          res.data.data.channel.members.forEach((member: any) => {
            channelMember.push(member.user.full_name);
            member.isChangeRole = false;
            if (admin && admin.level && admin.code) {
              if (member.user && member.user.email) {
                if (member.user.email !== state.email) {
                  member.isChangeRole = true;
                  // console.log("CHECK MEMBER >>> ", member.user);
                }
              }
            }
          });
          // console.log("FINAL MEMBERLIST CHECK", channelMember);
          setMentionMembers(channelMember);
          // setTimeout(store.dispatch("saveActiveMembers", channelMember), 100);

          // saveActiveMembers
          // console.log("Members", res.data.data.channel.members);
          // if (admin && admin.level && admin.code) {
          //   res.data.data.channel.members.forEach((member: any) => {
          //     console.log("Check Member >>> ", member)
          //     console.log("Check LOGIN USER >>> ", admin)
          //     if (admin.level != member.project_role.level) {
          //       if (member.project_role.level > admin.level) {
          //         // console.log("INDIDE ADMIN", admin.level);
          //         // console.log("INDIDE MEMBER", member.project_role.level);
          //         member.isChangeRole = true;
          //       }
          //     }
          //   });
          // }
          state.memberList = res.data.data.channel.members
            ? res.data.data.channel.members
            : [];

          // state.messages = res.data.data
          state.data.projectMember = res.data.data.project_member
            ? res.data.data.project_member
            : {};
          // console.log("Date Validity Check >>> ", state.data.projectMember.validity_date)
          state.channel = res.data.data.channel ? res.data.data.channel : {};
          state.pendingMembersCount = res.data.data.pending_members_count
            ? res.data.data.pending_members_count
            : 0;
          state.approvedMembersCount = res.data.data.approved_members_count
            ? res.data.data.approved_members_count
            : 0;
          state.pendingList = res.data.data.pending_members
            ? res.data.data.pending_members
            : [];
          state.userRole = res.data.data.project_role
            ? res.data.data.project_role
            : {};
          state.projectId = res.data.data.channel.project.uuid
            ? res.data.data.channel.project.uuid
            : "";
          // state.projectId = res.data.data.channel.project_id
          //   ? res.data.data.channel.project_id
          //   : 0;
          res.data.data.messages.data.forEach((item: any) => {
            if (item.form_data != null) {
              // console.log("check msg :>>", item.form_data);
              if (item.form_data && item.form_data.sent_to_users) {
                // console.log( "Type check", item.form_data.sent_to_users)
                if (typeof item.form_data.sent_to_users === "string") {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  item.form_data.sent_to_users = JSON.parse(
                    item.form_data.sent_to_users
                  );
                }
                // console.log(
                //   "Final :>>",
                //   JSON.parse(item.form_data.sent_to_users)
                // );
                // eslint-disable-next-line @typescript-eslint/camelcase
              }
            }
          });

          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((data: any) => {
              if (data.forward && data.forward.attachments.length) {
                data.forward.attachments.forEach((attachment: any) => {
                  attachment.convertedFileSize = common.formatBytes(
                    parseInt(
                      attachment.file_size
                    )
                  );
                });
              }
            });
          }

          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((data: any) => {
              data.allowEdit = false;
              if (data.attachments && data.attachments.length) {
                data.attachments.forEach((attachment: any) => {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  attachment.deleted_at = attachment.deleted_at ? common.localDateAndTime(attachment.deleted_at) : null;
                  attachment.convertedFileSize = common.formatBytes(
                    parseInt(
                      attachment.file_size
                      // parseInt(data.file_size.split(" ")[0])
                    )
                  );
                });
              }
              const result: any = [];
              const map = new Map();
              data.responses.forEach((item: any) => {
                if (!map.has(item.response_by_id)) {
                  map.set(item.response_by_id, true); // set any value to Map
                  result.push(item);
                }
              });
              data.editStatus = result;

              // console.log("FINAL CHECK", result)
              // for (const item of array) {

              // }
              //             data.uniqueResponse = data.responses.map((item: any) => {item.response_by_id})
              // .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
              // data.responses.forEach((item: any) => {
              //   item.uniqueUsers = [];
              //   item.response_by

              // })
            });
          }
          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((item: any) => {
              if (item.user && item.user.email) {
                if (
                  item.user.email === localStorage.getItem("current_user_email")
                ) {
                  // console.log("email", item.user.email);
                  // console.log("active email", state.data.activeUserEmail);
                  item.editStatus = true;
                } else {
                  item.editStatus = false;
                }
              }
            });
          }

          state.totalSearchResultCount = 0
          res.data.data.messages.data.forEach(async (item: any) => {
            const searchText = state.data.search;
            const regex = new RegExp(searchText, 'gi');
            if (state.data.search && item.attachments.length && item.message) {

              item.attachments.forEach((item2: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                const result0 = item2.file_name.match(regex);
                console.log("this is result 0", result0);
                if (result0) {
                  state.totalSearchResultCount = state.totalSearchResultCount + result0.length;

                }
                // eslint-disable-next-line @typescript-eslint/camelcase
                item2.file_name = item2.file_name.replace(regex, '<mark class="highlight">$&</mark>');
                if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                  item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
                }
              })
              const result1 = item.message.match(regex);
              if (result1) {
                state.totalSearchResultCount = state.totalSearchResultCount + result1.length;
              }
              item.message = item.message.replace(regex, '<mark class="highlight">$&</mark>');
              if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
              }
              console.log("fow", item.forward.communication.message)
              await replyCounter(item.responses, regex);
              // eslint-disable-next-line @typescript-eslint/camelcase
              await shareFileCounter(item.share_history, regex);

            }

            if (state.data.search && item.attachments.length) {
              item.attachments.forEach((item2: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                const result2 = item2.file_name.match(regex);
                if (result2) {
                  state.totalSearchResultCount = state.totalSearchResultCount + result2.length;
                }
                // eslint-disable-next-line @typescript-eslint/camelcase
                item2.file_name = item2.file_name.replace(regex, '<mark class="highlight">$&</mark>');
                if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                  item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
                }
              })

              await replyCounter(item.responses, regex);
              // eslint-disable-next-line @typescript-eslint/camelcase
              await shareFileCounter(item.share_history, regex);

            }

            if (state.data.search && !item.attachments.length) {
              const result3 = item.message && item.message.match(regex) || item.forward.communication.message.match(regex);
              if (result3) {
                state.totalSearchResultCount = state.totalSearchResultCount + result3.length;
                // item.message = item.message.replace(/(<mark class="highlight">|<\/mark>)/gim, '');
                item.message = item.message.replace(regex, '<mark class="highlight">$&</mark>');
                if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                  item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
                }

              }

              await replyCounter(item.responses, regex);
              // eslint-disable-next-line @typescript-eslint/camelcase
              await shareFileCounter(item.share_history, regex);

            }

          });
          console.log("Message match count >>>", state.totalSearchResultCount);

          state.ownerId = res.data.data.owner.user_id;
          state.owner = res.data.data.channel;

          console.log(
            "state.data.scrollTop && !state.data.scrollBottom",
            state.data.scrollTop,
            state.data.scrollBottom
          );





          if (res.data.data.messages.data && res.data.data.messages.data.length === 0) return false;
          // new code for replacing message
          // <<< checking for the position Check >>>>
          // if (state.data.chatScroll.scrollToTop) {
          //   // res.data.data.messages.data =
          //   //   res.data.data.messages.data.reverse();
          //   res.data.data.messages.data.forEach((data: any) => {
          //     state.communicationList.unshift(data);
          //   });



          //   console.log("I AM ON TOP")
          //   state.data.chatScroll.scrollToTop = false;
          // } else if (state.data.chatScroll.scrollToBottom) {
          //   res.data.data.messages.data = res.data.data.messages.data.reverse();

          //   res.data.data.messages.data.forEach((data: any) => {
          //     state.communicationList.push(data);
          //   });

          //   console.log("I AM ON BOTTOM")
          //   // return the scroll normal
          //   state.data.chatScroll.scrollToBottom = false;
          // } else {
          //   res.data.data.messages.data.forEach((data: any) => {
          //     state.communicationList.push(data);
          //   });
          //   console.log("I AM ON NORMAL MSG")
          // }




          updateCommunicationList(alignArrayWithOrder(state.communicationList, res.data.data.messages.data));
          // let lastScrollId: any = "";

          // if(res.data.data.messages.data && res.data.data.messages.data.length){
          //   lastScrollId = res.data.data.messages.data[res.data.data.messages.data.length +1].id
          // }
          // console.log("CHEKC TH ID >> ", lastScrollId);

          const latestIdList = res.data.data.messages.data.map((item: any) => item.id).filter((id: any) => id !== undefined);


          console.log("Latest Ids >>> ", latestIdList[0]);

          setTimeout(() => {
            scrollToChatItem(latestIdList[0])
            // getVisibleLiId();
          }, 100);

          // const chatBodyContent = document.getElementById('chat-body-content');

          // if (!chatBodyContent) {
          //   console.error('Element with id "chat-body-content" not found.');
          //   return;
          // }

          // setTimeout(() => {
          //   const lastId = JSON.stringify(latestIdList[0] + 1);
          // console.log("last id >>> ", lastId)
          // console.log("type of last  >>>", typeof lastId)
          // // lastId.toString()
          // const allLis = Array.from(chatBodyContent.querySelectorAll('li')) as HTMLElement[];
          // // console.log("All LIS >>> ", allLis)
          // // allLis.forEach((item: any) => {
          // //   console.log("C IDS >>> ", item.id)
          // //   if (JSON.stringify(item.id) == lastId) {
          // //   }
          // // })
          // // offsetTop

          // const lastLiHeight: any = allLis.find(li => li.id == lastId)?.offsetTop;

          // if (lastLiHeight !== undefined) {
          //   console.log(`Height of the last ID's <li>: ${lastLiHeight}px`);
          // } else {
          //   console.log('The last ID does not exist in the chat-body-content.');
          // }

          // const chatBodyContentTest: any = document.getElementById('chat-body-content');
          // console.log("SCROLL HEIGHT >>> ", chatBodyContentTest.scrollHeight);
          // console.log("Client HEIGHT >>> ", chatBodyContentTest.clientHeight)

          // // Set the scroll position to a specific number, e.g., 100 pixels
          // const { scrollTop, scrollHeight, clientHeight } = chatBodyContentTest;
          // const scrollBottom = scrollTop + clientHeight;
          // chatBodyContentTest.scrollTop = event.target.scrollHeight - event.target.clientHeight;
          // chatBodyContentTest.scrollTop = lastLiHeight;
          // }, 1000);




          state.data.approvalDiscussionInvitationId = res.data.data.payer_request_id ? res.data.data.payer_request_id : '';
          getUserList();
          state.ui.isLoading.communicationListAPICall = true;
          // setTimeout(toggleTourGuide, 1000);
          state.data.scrollTop = false;
          state.data.scrollBottom = false;
          // console.log("FINAL CHECK COLLECTION", arrayUniqueByKey.length)
          // const liElements = document.querySelectorAll('li');
          //  console.log("Check the ITEMS >>> ", liElements)

          state.allowScrollAction = true;
          setTimeout(() => {
            scrollToChatItem(latestIdList[0])
            getVisibleLiId();
          }, 2500);
          state.ui.isPostLoading.loadLatestMessages = false;
          state.ui.isActive.loadLatestMessages = true;
        })
        .catch((error: any) => {
          state.data.scrollTop = false;
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          if (latestClicked === 1) {
            $("#chat-body-content").animate(
              {
                scrollTop: $("#chat-body-content")[0].scrollHeight,
              },
              1000
            );

          }
          // state.ui.isPostLoading.loadLatestMessages = false;
          // $("#chat-body-content").animate(
          //   {
          //     scrollTop: $("#chat-body-content")[0].scrollHeight,
          //   },
          //   1000
          // );
          // state.scrollActive = true;
        })

    }



    

    function searchByInChatMessage(channelID: any, pageNumber: any) {
      //  if(state.data.lastPage<state.data.page) return false;
      state.item.error.erorList = [];
      state.item.hasError = false;
      if (state.postLoading) return false;
      // if (state.ui.isPostLoading.getCommunicationList) return false;

      const payLoad: any = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelID,
        search: state.data.search,
        user: localStorage.getItem("current_user_role"),
        page: pageNumber,
        // eslint-disable-next-line @typescript-eslint/camelcase
        // communication_id: routerQuery.query.communicationId
        //   ? routerQuery.query.communicationId
        //   : "",
      };
      state.ui.isPostLoading.getCommunicationList = true;
      ethitransService
        // .createCommunicationList(payLoad)
        .createCommunicationListUpdated(payLoad)
        .then(async (res: any) => {

          // search match count

          state.data.currentPage = res.data.data.messages.data.current_page;
          state.data.page = res.data.data.messages.data.current_page;
          state.data.lastPage = res.data.data.messages.data.last_page;
          if (res.data.data.messages.current_page == 1) {
            state.communicationList = [];
          }
          console.log("Check the Response >>> ", res.data.data.messages)
          state.ui.isLoading.loaded = true;
          state.ui.isPostLoading.getCommunicationList = false;
          state.postLoading = false;
          state.data.getCommunicationList = true;
          // res.data.data.messages.data.forEach((message: any)=> {
          //   if(message.message_receivers && message.message_receivers.length > 0){
          //     message.message_receivers = filterMessageReceivers(message.message_receivers);
          //   }
          // })
          res.data.data.messages.data.forEach((message: any) => {
            console.log("CHECK THE MESAGE >>> ", message);
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (message.message_receivers && message.message_receivers.length > 0) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              message.message_receivers = filterMessageReceivers(message.message_receivers);
            }
            if (message.responses && message.responses.length > 0) {
              message.responses.forEach((reply: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                reply.message_receivers = filterMessageReceivers(reply.message_receivers);
              })


            }
          })
          state.data.unreadMessage = res.data.data.unread_count ? res.data.data.unread_count : 0;
          let arrayMessageDeliveredIds: any = [];

          // state.ui.isLoading.loaded = true;
          state.data.channelDetails = res.data.data;

          state.data.subscriptionDetails = res.data.data.package_features;
          state.data.currentPage = res.data.data.messages.current_page;
          state.data.page = res.data.data.messages.current_page;
          state.data.lastPage = res.data.data.messages.last_page;
          state.isPublicUser = res.data.data.is_public_user;

          if (state.data.currentPage === state.data.page) {
            state.data.lastPageMessages = res.data.data.messages.data;
          }


          const comID: any = routerQuery.query.communication_id;
          const communicationIdInQuery: any = routerQuery.query.communicationId;

          res.data.data.messages.data.forEach((item: any) => {
            if ((item.user.email != localStorage.getItem("current_user_email")) && item.message_status === 0) {
              arrayMessageDeliveredIds.push(item.id)
            }
            // eslint-disable-next-line @typescript-eslint/camelcase
            if (item.message_receivers && item.message_receivers.length) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.message_receivers = moveSenderToEnd(item.message_receivers);
              // eslint-disable-next-line @typescript-eslint/camelcase
              item.message_receivers.forEach((message: any) => {
                // console.log("Login User >>> ", localStorage.getItem("current_user_email"));
                // console.log("Members >>> ", message.email)
                if (message.email === localStorage.getItem("current_user_email")) {
                  message.sender = true;
                } else {
                  message.sender = false;
                }
              })
            }

            // eslint-disable-next-line @typescript-eslint/camelcase
            if (routerQuery.query.communicationId) {
              state.data.isCommunicationFile =
                routerQuery.query.communicationId;
              // state.data.scrolledPages.push(state.data.page);

              //  document.getElementById(state.data.isCommunicationFile).scrollIntoView();
              //  const element: any= document.getElementById(state.data.isCommunicationFile);
              //  element.scrollIntoView();
            }

            if (parseInt(comID) === item.id) {
              state.data.showReplyCommunication = true;
              replyCommunication(item);

              console.log("message in in communicationList", item);
            }

            if (
              parseInt(communicationIdInQuery) === item.id &&
              routerQuery.query.reply
            ) {
              state.data.showReplyCommunication = true;
              state.data.isCommunicationReplyFile = routerQuery.query.reply;
              replyCommunication(item);

              // console.log("message in in communicationList", item);
            }
            //      if(parseInt(comFIleID) === item.id){
            //     state.data.isCommunicationFile=true;
            //  }
            item.responses.forEach((response: any) => {
              response.createdLatest = common.localDateAndTime(
                response.created_at
              );
            });
            if (item.forward && item.forward.communication && item.forward.communication.responses) {
              item.forward.communication.responses.forEach((response: any) => {
                response.createdLatest = common.localDateAndTime(
                  response.created_at
                );
              });
            }

            item.editedUpdated = common.localDateAndTime(item.updated_at);
            if (item.forward && item.forward.communication) {
              item.forward.communication.editedUpdated = common.localDateAndTime(item.forward.communication.updated_at);
            }
            item.allowEdit = false;
            item.allowDelete = false;
            if (item.share_history && item.share_history.length) {
              item.share_history.forEach((sharedHistory: any) => {
                sharedHistory.documents.forEach((doc: any) => {
                  if (doc.filetype === "mov" || doc.filetype === "MOV") {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    doc.filetype = "mp4";
                  }
                  doc.file.convertedFileSize = common.formatBytes(
                    parseInt(doc.file.filesize)
                  );
                });
              });
            }
          });


          // console.log("<<< IDS COLLECTION >>>", arrayMessageDeliveredIds)
          res.data.data.messages.data.forEach((data: any) => {
            // console.log("DATE >>> ", data.sent_date);
            data.currentTime = common.localDateAndTime(data.sent_date);
            // eslint-disable-next-line @typescript-eslint/camelcase
            data.deletedLocalTime = data.deleted_at ? common.localDateAndTime(data.deleted_at) : null;
            if (data.attachments) {
              data.attachments.forEach((video: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                if (video.file_type === "mov" || video.file_type === "MOV") {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  video.file_type = "mp4";
                }
              });

              // console.log("Data Check >>> ", data)
            }
          });
          if (!state.data.search) {
            checkMessageStatus(res.data.data.messages.data);
          }
          // console.log("messagecheckbyom >>> ", state.communicationList);
          // if (
          //   res.data.data.messages.data &&
          //   res.data.data.messages.data.length
          // ) {
          //   //  state.data.scrollTop = false;
          // }
          let admin: any = {};

          if (
            res.data.data.project_role.level === 1 &&
            res.data.data.project_role.code === "owner"
          ) {
            admin = res.data.data.project_role;
          } else {
            if (
              res.data.data.project_role.level === 2 &&
              res.data.data.project_role.code === "co-owner"
            ) {
              admin = res.data.data.project_role;
            }
          }
          const filteredItems = res.data.data.channel.members.filter((mem: any) => mem.user.email === state.email && mem.is_mute == 1);
          console.log('fimt0: ', filteredItems)
          state.isMute = filteredItems.length > 0 ? true : false;

          const channelMember: any = [];
          res.data.data.channel.members.forEach((member: any) => {
            channelMember.push(member.user.full_name);
            member.isChangeRole = false;
            if (admin && admin.level && admin.code) {
              if (member.user && member.user.email) {
                if (member.user.email !== state.email) {
                  member.isChangeRole = true;
                  // console.log("CHECK MEMBER >>> ", member.user);
                }
              }
            }
          });
          // console.log("FINAL MEMBERLIST CHECK", channelMember);
          setMentionMembers(channelMember);
          // setTimeout(store.dispatch("saveActiveMembers", channelMember), 100);

          // saveActiveMembers
          // console.log("Members", res.data.data.channel.members);
          // if (admin && admin.level && admin.code) {
          //   res.data.data.channel.members.forEach((member: any) => {
          //     console.log("Check Member >>> ", member)
          //     console.log("Check LOGIN USER >>> ", admin)
          //     if (admin.level != member.project_role.level) {
          //       if (member.project_role.level > admin.level) {
          //         // console.log("INDIDE ADMIN", admin.level);
          //         // console.log("INDIDE MEMBER", member.project_role.level);
          //         member.isChangeRole = true;
          //       }
          //     }
          //   });
          // }
          state.memberList = res.data.data.channel.members
            ? res.data.data.channel.members
            : [];

          // state.messages = res.data.data
          state.data.projectMember = res.data.data.project_member
            ? res.data.data.project_member
            : {};
          // console.log("Date Validity Check >>> ", state.data.projectMember.validity_date)
          state.channel = res.data.data.channel ? res.data.data.channel : {};
          state.pendingMembersCount = res.data.data.pending_members_count
            ? res.data.data.pending_members_count
            : 0;
          state.approvedMembersCount = res.data.data.approved_members_count
            ? res.data.data.approved_members_count
            : 0;
          state.pendingList = res.data.data.pending_members
            ? res.data.data.pending_members
            : [];
          state.userRole = res.data.data.project_role
            ? res.data.data.project_role
            : {};
          state.projectId = res.data.data.channel.project.uuid
            ? res.data.data.channel.project.uuid
            : "";
          // state.projectId = res.data.data.channel.project_id
          //   ? res.data.data.channel.project_id
          //   : 0;
          res.data.data.messages.data.forEach((item: any) => {
            if (item.form_data != null) {
              // console.log("check msg :>>", item.form_data);
              if (item.form_data && item.form_data.sent_to_users) {
                // console.log( "Type check", item.form_data.sent_to_users)
                if (typeof item.form_data.sent_to_users === "string") {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  item.form_data.sent_to_users = JSON.parse(
                    item.form_data.sent_to_users
                  );
                }
                // console.log(
                //   "Final :>>",
                //   JSON.parse(item.form_data.sent_to_users)
                // );
                // eslint-disable-next-line @typescript-eslint/camelcase
              }
            }
          });

          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((data: any) => {
              if (data.forward && data.forward.attachments.length) {
                data.forward.attachments.forEach((attachment: any) => {
                  attachment.convertedFileSize = common.formatBytes(
                    parseInt(
                      attachment.file_size
                    )
                  );
                });
              }
            });
          }

          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((data: any) => {
              data.allowEdit = false;
              if (data.attachments && data.attachments.length) {
                data.attachments.forEach((attachment: any) => {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  attachment.deleted_at = attachment.deleted_at ? common.localDateAndTime(attachment.deleted_at) : null;
                  attachment.convertedFileSize = common.formatBytes(
                    parseInt(
                      attachment.file_size
                      // parseInt(data.file_size.split(" ")[0])
                    )
                  );
                });
              }
              const result: any = [];
              const map = new Map();
              data.responses.forEach((item: any) => {
                if (!map.has(item.response_by_id)) {
                  map.set(item.response_by_id, true); // set any value to Map
                  result.push(item);
                }
              });
              data.editStatus = result;

              // console.log("FINAL CHECK", result)
              // for (const item of array) {

              // }
              //             data.uniqueResponse = data.responses.map((item: any) => {item.response_by_id})
              // .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
              // data.responses.forEach((item: any) => {
              //   item.uniqueUsers = [];
              //   item.response_by

              // })
            });
          }
          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((item: any) => {
              if (item.user && item.user.email) {
                if (
                  item.user.email === localStorage.getItem("current_user_email")
                ) {
                  // console.log("email", item.user.email);
                  // console.log("active email", state.data.activeUserEmail);
                  item.editStatus = true;
                } else {
                  item.editStatus = false;
                }
              }
            });
          }

          state.totalSearchResultCount = 0
          res.data.data.messages.data.forEach(async (item: any) => {
            const searchText = state.data.search;
            const regex = new RegExp(searchText, 'gi');
            if (state.data.search && item.attachments.length && item.message) {
              alert(1);

              item.attachments.forEach((item2: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                const result0 = item2.file_name.match(regex);
                console.log("this is result 0", result0);
                if (result0) {
                  state.totalSearchResultCount = state.totalSearchResultCount + result0.length;

                }
                // eslint-disable-next-line @typescript-eslint/camelcase
                item2.file_name = item2.file_name.replace(regex, '<mark class="highlight">$&</mark>');
                if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                  item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
                }
              })
              const result1 = item.message.match(regex);
              if (result1) {
                state.totalSearchResultCount = state.totalSearchResultCount + result1.length;
              }
              item.message = item.message.replace(regex, '<mark class="highlight">$&</mark>');
              if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
              }
              console.log("fow", item.forward.communication.message)
              // await replyCounter(item.responses, regex);
              // eslint-disable-next-line @typescript-eslint/camelcase
              // await shareFileCounter(item.share_history, regex);

            }

            if (state.data.search && item.attachments.length) {
              alert(2);
              item.attachments.forEach((item2: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                const result2 = item2.file_name.match(regex);
                if (result2) {
                  state.totalSearchResultCount = state.totalSearchResultCount + result2.length;
                }
                // eslint-disable-next-line @typescript-eslint/camelcase
                item2.file_name = item2.file_name.replace(regex, '<mark class="highlight">$&</mark>');
                if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                  item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
                }
              })

              // await replyCounter(item.responses, regex);
              // eslint-disable-next-line @typescript-eslint/camelcase
              // await shareFileCounter(item.share_history, regex);

            }

            if (state.data.search && !item.attachments.length) {
              // alert(3);
              const result3 = item.message && item.message.match(regex) || item.forward.communication.message.match(regex);
              console.log("CHECK THE COUINT >>> ", result3);
              console.log("Previous Count >>> ", state.totalSearchResultCount)
              if (result3) {
                state.totalSearchResultCount = state.totalSearchResultCount + result3.length;
                // item.message = item.message.replace(/(<mark class="highlight">|<\/mark>)/gim, '');
                item.message = item.message.replace(regex, '<mark class="highlight">$&</mark>');
                if (item.forward != null && item.forward.communication && item.forward.communication.message) {
                  item.forward.communication.message = item.forward.communication.message.replace(regex, '<mark class="highlight">$&</mark>');
                }

              }

              // await replyCounter(item.responses, regex);
              // eslint-disable-next-line @typescript-eslint/camelcase
              // await shareFileCounter(item.share_history, regex);

            }

          });
          console.log("Message match count >>>", state.totalSearchResultCount);

          state.ownerId = res.data.data.owner.user_id;
          state.owner = res.data.data.channel;
          console.log("FILERED IDS>>> ", getIdsForActiveUser(res.data.data.messages.data))



          // <<< checking for the position Check >>>>
          if (state.data.chatScroll.scrollToTop) {
            // alert("I AM ON TOP")
            res.data.data.messages.data =
              res.data.data.messages.data.reverse();
            res.data.data.messages.data.forEach((data: any) => {
              state.communicationList.push(data);
            });



            console.log("I AM ON TOP")
            state.data.chatScroll.scrollToTop = false;
          } else if (state.data.chatScroll.scrollToBottom) {
            // alert("I AM ON BOTTOM")
            res.data.data.messages.data = res.data.data.messages.data.reverse();

            res.data.data.messages.data.forEach((data: any) => {
              state.communicationList.unshift(data);
            });

            console.log("I AM ON BOTTOM")
            // return the scroll normal
            state.data.chatScroll.scrollToBottom = false;
          } else {
            // alert("I AM ON NORMAL MSG")
            res.data.data.messages.data.forEach((data: any) => {
              state.communicationList.push(data);
            });
            setTimeout(() => {
              // Triggered when scroll has stopped
              // console.log("<<< Triggered >>>  INSIDE")
              getVisibleLiId();
            }, 500);
            console.log("I AM ON NORMAL MSG")
          }

          // }

          updateCommunicationList(state.communicationList.filter(
            (value: any, index: any, self: any) =>
              self.findIndex((v: any) => v.id === value.id) === index
          ));
          console.log("IDs for Delivered >>>", arrayMessageDeliveredIds)
          // messageReceived(res.data.data.messages.data);
          if (arrayMessageDeliveredIds && arrayMessageDeliveredIds.length != 0) {
            // messageDeliveryIds.value = arrayMessageDeliveredIds;
            messageReceived(arrayMessageDeliveredIds);
            arrayMessageDeliveredIds = [];
          }

          state.data.approvalDiscussionInvitationId = res.data.data.payer_request_id ? res.data.data.payer_request_id : '';
          getUserList();
          state.ui.isLoading.communicationListAPICall = true;
          // setTimeout(toggleTourGuide, 1000);


          state.data.chatScroll.scrollToTop = false;
          state.data.chatScroll.scrollToBottom = false;



          state.data.scrollTop = false;
          state.data.scrollBottom = false;
          // console.log("FINAL CHECK COLLECTION", arrayUniqueByKey.length)
          // const liElements = document.querySelectorAll('li');
          //  console.log("Check the ITEMS >>> ", liElements)

          state.allowScrollAction = true;
          // setTimeout(() => {
          //   // Triggered when scroll has stopped
          //   // console.log("<<< Triggered >>>  INSIDE")
          //   getVisibleLiId();
          // }, 500);

        })
        .catch((error: any) => {
          state.data.scrollTop = false;
          // console.log("message", error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
      // .finally(() => {
      //   const visibleMessages = Array.from(document.querySelectorAll('.message')).filter((element: any) => {
      //     // console.log("ELEMENTS CHECL >>> ", element)
      //     // const itemElement = document.getElementById(`item-${element.id}`);
      //     // console.log("CHECK THE ITEMS >>> ")
      //     // Check if the element is visible
      //     const style = window.getComputedStyle(element);
      //     return style.display !== 'none' && style.visibility !== 'hidden' && element.offsetHeight > 0 && element.offsetWidth > 0;
      //   });

      //   // Select the container you want to track (e.g., the window or a specific scrollable element)
      //   const scrollContainer = document.documentElement || document.body;

      //   // Function to get the scroll position as a percentage of total scrollable height
      //   const getScrollPosition = () => {
      //     const scrollTop = scrollContainer.scrollTop;
      //     const scrollHeight = scrollContainer.scrollHeight;
      //     const clientHeight = scrollContainer.clientHeight;

      //     // Calculate scroll position as a percentage
      //     const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

      //     // console.log(`Scroll Position: ${scrollPercentage.toFixed(2)}%`);
      //   };

      //   // Add a scroll event listener
      //   scrollContainer.addEventListener('scroll', getScrollPosition);

      //   // state.data.scrollTop = false;
      //   // alert("LAST")
      //   state.ui.isPostLoading.getCommunicationList = false;
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   //  if(state.isReplyMessage) return ;
      //   // console.log("state.messageStatus", state.messageStatus);
      //   // console.log("messge.reply ", state.communicationList);
      //   if (!state.firstScroll) {
      //     scrollToLast();
      //   } else {
      //     // alert("RESET")
      //     state.data.scrollTop = false;
      //     if (!state.messageStatus) {
      //       scrollToTop();
      //     } else {
      //       scrollToLast();
      //     }
      //   }
      // });
    }

    // function scrollWithPageDetail(){

    // }
    // function scrollByPageDetails(){
    //   searchByInChatMessage(channelId, state.data.page);
    // }



    // function getUnreadMessage(){
    //   const unreadMessage = 
    // }

    function searchAllMessage() {

      if (routerQuery.query.communication_id) {
        if (state.data.search.length == 0) {
          state.totalSearchResult = false
          state.communicationList = [];
          getCommunicationList(channelId);
        } else {
          state.totalSearchResult = true
          state.communicationList = [];
          searchByInChatMessage(channelId, 1);
        }

      } else {
        // alert("CHECK >>>")
        // state.ui.isPostLoading.getCommunicationList = true;
        state.communicationList = [];
        searchByInChatMessage(channelId, 1);
        if (state.data.search.length == 0) {
          state.totalSearchResult = false
        } else {
          state.totalSearchResult = true
        }
      }


    }
    function searchClearMessage() {
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (routerQuery.query.communication_id) {
        state.data.search = "";
        state.communicationList = [];
        getCommunicationList(channelId);
        state.data.clearBtn = false;
        if (state.data.search.length == 0) {
          state.totalSearchResult = false
        } else {
          state.totalSearchResult = true
        }
      } else {
        console.log("im form outthred");
        state.ui.isPostLoading.getCommunicationList = true;
        state.data.search = "";
        state.data.clearBtn = false;
        if (state.data.search.length == 0) {
          state.totalSearchResult = false
        } else {
          state.totalSearchResult = true
        }
        state.communicationList = [];
        searchByInChatMessage(channelId, 1);
      }

    }
    // function setRouteQueryPage(){
    //   if(routerQuery.query.page){
    //     const pageNum: any = routerQuery.query.page;
    //   console.log("HELLO", pageNum)
    //   // state.data.page = parseInt(pageNum);
    //   }
    // }
    // Pusher.logToConsole = true;
    function addMember(member: any) {
      const p = $("<a />", {
        text: member.info.id,
        id: "member_" + member.id,
      });
      $("#members").append(p);
    }
    function removeMember(member: any) {
      $("#member_" + member.id).remove();
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const scrollCheckChat = computed(() => {
      return false;
    });
    const token = localStorage.getItem("current_user_token");
    let newToken = "";
    if (token) {
      newToken = token.replace(/"/gi, "");
    }
    const privatePusher = new Pusher(`${process.env.VUE_APP_WEBSOCKETS_KEY}`, {
      cluster: "ap2",
      authEndpoint: `${config.privateChannel}broadcasting/auth`,
      auth: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + newToken,
        },
      },
    });
    const channel = privatePusher.subscribe(`private-channelCommunication.${channelId}`);

    channel.bind("channelCommunication.created", function (data: any) {
      if (routeLeaveStatus.value) return false;
      if (JSON.stringify(data)) {
        getInstanceMessageList(data.id);
      }
    });
    const userTyping = privatePusher.subscribe(`userTyping.${channelId}`);
    userTyping.bind("userTyping", function (data: any) {
      console.log("dataset", data);
      if (state.data.userFullName !== data.username) {
        state.data.userTyping = data.username;
      }
      setTimeout(function () {
        //clear user is typing message
        state.data.userTyping = "";
      }, 1000);
      // console.log('PUSHER CHECK TYPING...', data.username);
      // if(state.data.userFullName != data.username){
      // state.data.userTypingStatus = true;

      // state.data.userTyping = ''
      // console.log('PUSHER CHECK TYPING...', state.data.userTyping);
      // state.data.userTypingStatus = false;
      // }

      // if (JSON.stringify(data)) {
      //   // console.log('CHANNEL', data.chat);
      //   getCommunicationList();
      // }
    });
    // channel.bind(userIsTypingEvent, function(data: any) {
    // }

    // const channelCommunicationAttachment = pusher.subscribe(`channelCommunicationAttachment.${attachmentId}`);
    // channelCommunicationAttachment.bind("channelCommunicationAttahcment.created", function(data: any) {
    //   // console.log('PUSHER CHECK', data);

    //   if (JSON.stringify(data)) {
    //     // console.log('PUSHER CHECK 1', data.chat);
    //     getCommunicationList();
    //   }
    // });

    function communicationTyping() {
      state.ui.isPostLoading.channelCommunicationTyping = false;
    }

    function channelCommunicationTyping() {
      if (
        !state.data.userUUID ||
        !channelId ||
        state.ui.isPostLoading.channelCommunicationTyping
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_id: state.data.userUUID,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
      };
      state.ui.isPostLoading.channelCommunicationTyping = true;
      ethitransService
        .channelCommunicationTyping(payLoad)
        .then((res: any) => {
          // console.log("HELLOOO", res.data.data);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          setTimeout(communicationTyping, 300);
          // state.ui.isPostLoading.channelCommunicationTyping = false;
        });
    }
    function removeImageTags(htmlContent: string): string {
      // Use a regular expression to find and remove <img> tags
      return htmlContent.replace(/<img[^>]*>/gi, '');
    }
    function cleanString(inputString: string) {
      // Define regex for file paths and "pastedText"
      const filePathRegex = /\/[a-zA-Z0-9._-]+(\/[a-zA-Z0-9._-]+)*\/[a-zA-Z0-9._-]+\.[a-zA-Z0-9]+/g;
      const pastedTextRegex = /pastedText/g;

      // Replace all matches with an empty string
      return inputString.replace(filePathRegex, '').replace(pastedTextRegex, '');
    }
    watch(
      () => state.message,
      () => {
        if (!state.message) return false;
        // console.log("Typing");
        state.message = cleanString(state.message);
        // state.message = removeImageTags(state.message);
        channelCommunicationTyping();
        // state.existingMember = "";
        // state.invite.users = [];
      }
    );
    function clientDocumentRetrieveWorkflow(
      clientDocumentId: any,
      selectedChannelId: any
    ) {
      if (!clientDocumentId || !selectedChannelId) return false;
      const payLoad = {
        id: clientDocumentId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        account_form_id: selectedChannelId,
      };
      state.ui.isLoading.clientDocumentRetrieveWorkflow = true;
      ethitransService
        .clientDocumentRetrieveWorkflowLink(payLoad)
        .then((res: any) => {
          console.log("HELLOOO");
          // console.log("clientDocumentRetrieveWorkflow :>>> ", res.data.data.docId);
          // accountFormRetrieve(res.data.data.docId);
          state.data.clientDocumentRetrieveWorkflowLink = res.data.data
            ? res.data.data
            : {};
          // $('.nav-tabs a[href="#profile"]').tab('show')
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.clientDocumentRetrieveWorkflow = false;
        });
    }
    function getAccountFormResponseByEmailAndId(user: any, index: any) {
      state.item.selectedUserFormDetails = {};
      state.selectedUser = user;
      console.log("Index", state.activeUserIndex);
      state.activeUserIndex = index;
      const email = user.email;
      console.log("Index", index);
      if (!email || !state.item.formId) return false;

      // console.log("CHECK the Form ID", state.item.formId);
      // console.log("CHECK Email Id", email);
      state.ui.isLoading.accountFormResponseByEmailAndId = true;
      ethitransService
        .accountFormResponseByEmailAndId(email, state.item.formId)
        .then((res: any) => {
          console.log(
            "getAccountFormResponseByEmailAndId Response Check:>>> ",
            res.data
          );
          // if(state.data.selectedFormData && !res.data){
          clientDocumentRetrieveWorkflow(
            state.data.selectedFormData.client_document_id,
            state.formData._id
          );
          // }
          state.item.selectedUserFormDetails = res.data ? res.data : {};
          state.currentSendUser = true;
          if (state.formData.edoc_id) {
            console.log("p8");
            getCommunicationList(channelId);
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.accountFormResponseByEmailAndId = false;
        });
    }

    function selectedUserAccountFormResponse() {
      let payLoad = {};
      state.userFormData.forEach((item: any) => {
        if (item.type === "digitalSignature") {
          item.signatureBase64 = item.signaturePad.toDataURL();
        }
      });
      const dt = new Date();
      if (state.formData.edoc_id) {
        payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          account_form_id: state.formData._id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          edoc_id: state.formData.edoc_id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          edoc_pdf_path: state.edocPdfPath,
          // eslint-disable-next-line @typescript-eslint/camelcase
          filled_by: {
            name: localStorage.getItem("current_user_email"),
            email: state.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_date:
            dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate(),
          elements: state.userFormData,
        };
      } else {
        payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          account_form_id: state.formData._id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          filled_by: {
            name: localStorage.getItem("current_user_email"),
            email: state.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_date:
            dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate(),
          elements: state.userFormData,
        };
      }
      // console.log(
      //   "<<< FINAL FINAL >>>selectedUserAccountFormResponse PAYLOAD CHECK ",
      //   payLoad
      // );
      state.ui.isPostLoading.selectedUserAccountFormResponse = true;
      ethitransService
        .accountformResponseSave(payLoad)
        .then((res: any) => {
          console.log(
            "selectedUserAccountFormResponse Response Check:>>> ",
            res.data
          );
          // state.ui.template.response = res.data;
          $("#responseModal").modal("hide");
          getAccountFormResponseByEmailAndId(
            state.selectedUser,
            state.activeUserIndex
          );
          // $("#formview").modal("hide");
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.selectedUserAccountFormResponse = false;
        });
    }
    function generateEdocStatement() {
      const fileName = common.removeWhiteSpace(state.formData.title);
      let metaData = "";
      const metaDataMapped = [] as any;
      state.userFormData.forEach((element: any) => {
        const optionsCollection: any = [];
        let obj = {} as any;
        if (element.type === "textbox" || element.type === "textArea") {
          // console.log("Elements check text box", element)
          obj = {};
          obj[element.metaData] = element.answer;
          metaDataMapped.push(obj);
        } else if (element.type === "single_select") {
          if (element.options && element.options.length) {
            // console.log("Elements options", element.options)
            element.options.forEach((meta: any) => {
              console.log("meta", meta);
              if (meta.selected) {
                obj = {};
                obj[meta.metaData] = `${meta.selected}`;
                metaDataMapped.push(obj);
              }
            });
          }
        } else if (element.type === "radioButton") {
          if (element.options && element.options.length) {
            // console.log("Elements options", element.options)
            element.options.forEach((meta: any) => {
              if (meta.selected) {
                console.log("meta", meta);
                obj = {};
                obj[meta.metaData] = `${meta.selected.toLowerCase()}`;
                metaDataMapped.push(obj);
              }
            });
          }
        } else if (element.type === "digitalSignature") {
          // if (element.options && element.options.length) {
          console.log("Elements Digital Signature", element);
          obj = {};
          obj[element.metaData] = element.signaturePad.toDataURL();
          console.log("CHECK SIGNATURE", element.signaturePad.toDataURL());
          metaDataMapped.push(obj);
          // }
        } else if (element.type === "datePicker") {
          // if (element.options && element.options.length) {
          console.log("Elements Digital Signature", element);
          obj = {};
          obj[element.metaData] = element.convertedDate;
          metaDataMapped.push(obj);
          // }
        }
        // else {
        //   if (element.options && element.options.length) {
        //     metaData = element.options[0].metaData;
        //     element.options.forEach((meta: any, index: number) => {
        //       // console.log("Elements Meta", meta);
        //       if (meta.selected === true) {
        //         const object  = {
        //           option: '',
        //           selected: false,
        //         };
        //         object.option = meta.option;
        //         object.selected = meta.selected;

        //         optionsCollection.push(object)

        //       }
        //     });

        //           obj = {};
        //           obj[metaData] = '';
        //           metaDataMapped.push(obj);
        //     // console.log("Collection CHECK>>>", optionsCollection)
        //   }
        //   // obj[element.metaData] = "";
        //   // metaDataMapped.push(obj);
        // }
      });
      // console.log("FINAL >>>", metaDataMapped)
      const map = JSON.stringify(metaDataMapped);
      // eslint-disable-next-line no-useless-escape
      const objFromMap = map.replace(/[\[\]']+/g, "");
      // // eslint-disable-next-line no-useless-escape
      const obje = `{${objFromMap.replace(/[{}]/g, "")}}`;
      // console.log("FINAL CHECK OBJECT >>>", obje)
      // console.log("JSON COLLECTION >>>", [JSON.parse(obje)])
      const formDataJson = [JSON.parse(obje)];
      state.userFormData.forEach((element: any) => {
        const optionsCollection: any = [];
        let obj = {} as any;
        if (element.type === "checkBox") {
          if (element.options && element.options.length) {
            metaData = element.options[0].metaData;
            element.options.forEach((meta: any, index: number) => {
              // console.log("Elements Meta", meta);
              if (meta.selected === true) {
                const object = {
                  option: "",
                  selected: false,
                };
                object.option = `${meta.option.toLowerCase()}`;
                // object.option = meta.option ;
                object.selected = meta.selected;

                optionsCollection.push(object);
              }
            });

            obj = {};
            formDataJson[0][`${metaData}`] = optionsCollection;
            // metaDataMapped.push(obj);
            // console.log("Collection CHECK>>>", optionsCollection)
          }
        }
      });
      // console.log("PAYLOAD CHECK : generateEdocStatement", JSON.parse(obje));
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        document_id: state.formData.edoc_id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        document_title: state.formData.title,
        // formdata: [JSON.parse(obje)],
        formdata: formDataJson,
        saveIndividualFileAs: `${fileName + "-" + common.generateUUID()}.pdf`,
      };
      // console.log("PAYLOAD CHECK : generateEdocStatement", payLoad);
      state.ui.isPostLoading.selectedUserAccountFormResponse = true;
      // state.ui.isPostLoading.generateEdocStatement = true;
      ethitransService
        .generateEdocStatement(payLoad)
        .then((res: any) => {
          if (res.data.data) {
            // console.log(
            //   "generateEdocStatement Response Check:>>> ",
            //   res.data.data
            // );
            state.edocPdfPath = res.data.data;
            selectedUserAccountFormResponse();
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.selectedUserAccountFormResponse = false;
          // state.ui.isPostLoading.generateEdocStatement = false;
        });
    }
    function userformInputValidationCheck() {
      state.item.error.validationList = [];
      state.item.hasError = false;
      state.userFormData.forEach((data: any) => {
        // console.log("CHECK ::: >>> ", data)
        if (data.required) {
          if (data.type === "textbox" || data.type === "textArea") {
            if (!data.answer) {
              data.hasError = data.title;
              state.item.error.validationList.push(
                `${data.title} is required.`
              );
            } else {
              data.hasError = "";
            }
          } else if (data.type === "checkBox" || data.type === "radioButton") {
            if (data.options && data.options.length) {
              // console.log("USER's DATA FILL CHECK >>> ", data)
              const optionList: any = [];
              data.options.forEach((item: any) => {
                // if(item.selected) return false;
                if (item.selected) {
                  optionList.push(item);
                }
              });
              // console.log("CHECK CHECK >>", optionList)
              if (optionList && !optionList.length) {
                data.hasError = data.title;
                state.item.error.validationList.push(
                  `${data.title} is required.`
                );
              } else {
                data.hasError = "";
              }
            }
          } else if (data.type === "single_select") {
            if (data.options && data.options.length) {
              // console.log("USER's DATA FILL CHECK >>> ", data)
              if (!data.selected) {
                data.hasError = data.title;
                state.item.error.validationList.push(
                  `${data.title} is required.`
                );
              } else {
                data.hasError = "";
              }
            }
          } else if (data.type === "datePicker") {
            // if(data.options && data.options.length) {
            // console.log("USER's DATA FILL CHECK >>> ", data)
            if (!data.date || !data.convertedDate) {
              data.hasError = data.title;
              state.item.error.validationList.push(
                `${data.title} is required.`
              );
            } else {
              data.hasError = "";
            }
            // }
          }
        }
      });
      console.log("VALIDATION CHECK >>> ", state.item.error.validationList);
      if (
        !state.item.error.validationList &&
        !state.item.error.validationList.length
      ) {
        state.item.hasError = false;
      } else if (state.item.error.validationList.length != 0) {
        state.item.hasError = true;
      }
    }
    function submitUserInputForm() {
      userformInputValidationCheck();
      state.data.startValidation = true;

      if (
        (state.item.error.validationList &&
          state.item.error.validationList.length) ||
        state.item.hasError
      )
        return false;
      if (state.formData.edoc_id) {
        generateEdocStatement();
      } else {
        selectedUserAccountFormResponse();
      }
    }
    // function generateEdocStatement() {
    //   if (state.ui.isPostLoading.generateEdocStatement) return false;
    //   // console.log("After", state.formData);
    //   const fileName = common.removeWhiteSpace(state.formData.title)
    //   const metaDataMapped = [] as any
    //   state.formData.elements.forEach((element: any) => {
    //     let obj = {} as any
    //     if (element.type === "textbox" || element.type === "textArea") {
    //       // console.log("Elements check text box", element)
    //       obj = {};
    //       obj[element.metaData] = element.answer;
    //       metaDataMapped.push(obj);
    //     } else {
    //       if(element.options && element.options.length){
    //           console.log("Elements options", element.options)
    //           element.options.forEach((meta: any) => {
    //             obj = {};
    //             obj[meta.metaData] = `${meta.selected}`;
    //             metaDataMapped.push(obj);
    //           })
    //         }
    //       // obj[element.metaData] = "";
    //       // metaDataMapped.push(obj);
    //     }
    //   })
    //   const map = JSON.stringify( metaDataMapped );
    //   const objFromMap = map.replace(/[\[\]']+/g,'');
    //   const obje = `{${objFromMap.replace(/[{}]/g, "")}}`;
    //   // console.log("PAYLOAD CHECK : generateEdocStatement", JSON.parse(obje));
    //   const payLoad = {
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     document_id: state.formData.edoc_id,
    //     formdata: [JSON.parse(obje)],
    //     saveIndividualFileAs :`${fileName}.pdf`
    //   };
    //   console.log("PAYLOAD CHECK : generateEdocStatement", payLoad);
    //   state.ui.isPostLoading.generateEdocStatement = true;
    //   ethitransService
    //     .generateEdocStatement(payLoad)
    //     .then((res: any) => {
    //       console.log("generateEdocStatement Response Check:>>> ", res);
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //     })
    //     // .finally(() => {
    //     //   state.ui.isPostLoading.generateEdocStatement = false;
    //     // });
    // }

    function hideFormView() {
      $("#formview").modal("hide");
    }
    function showWorkFlowLink(data: any) {
      // console.log("LINK CHECK", data.workflowLink);
      window.open(data.workflowLink);
      hideFormView();
    }
    function accountFormRetrieve(formId: any) {
      state.formData = {};
      state.ui.isPostLoading.getAccountFormRetrieveById = true;
      ethitransService
        .accountFormRetrieveById(formId)
        .then((res: any) => {
          // state.formResponse = res.data
          console.log("HELLO FROM COMMUNICATION Response Check:>>> ", res.data);
          state.formData = res.data;
          if (state.formData.sentToUsers && state.formData.sentToUsers.length) {
            getAccountFormResponseByEmailAndId(
              state.formData.sentToUsers[state.activeUserIndex],
              0
            );
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.getAccountFormRetrieveById = false;
        });
    }
    function showPreviewLink(data: any) {
      window.open(data);
    }

    function showFormView(data: any) {
      state.item.selectedUserFormDetails = {};
      state.formData = {};
      state.data.selectedFormData = {};
      const formData = data.form_data;
      state.data.clientDocumentRetrieveWorkflowLink = {};
      state.item.selectedUserFormDetails = {};
      state.data.selectedFormData = {};
      // console.log("CHECK THE FORM ;HEREE:::::", data.user);
      state.data.msgByUser = data.user;
      if (formData.client_document_id) {
        // console.log("WOWOWO ID:>>>");
        // console.log("Get Account form Response:>>>", formData);
        // console.log("Get Account DOC ID:>>>", formData.client_document_id);
        state.item.formId = formData.form_id;
        // $('.nav-tabs a[href="#profile"]').tab('show')
        if (formData.client_document_id) {
          state.data.selectedFormData = formData;
          // clientDocumentRetrieveWorkflow(state.data.selectedFormData.client_document_id, state.formData._id);
          accountFormRetrieve(formData.form_id);
        }
        // clientDocumentRetrieveWorkflow(formData.client_document_id, channelId);
        $("#formview").modal("show");
      } else if (formData.form_id) {
        state.item.formId = formData.form_id;
        accountFormRetrieve(formData.form_id);

        $("#formview").modal("show");
      }
    }
    function hideTemplateModal() {
      $("#exampleModal").modal("hide");
    }
    function hideEditTemplateModal() {
      $("#editExampleModal").modal("hide");
    }
    function showEditTemplateModal() {
      $("#editExampleModal").modal("show");
    }
    function selectEditForm(form: any) {
      state.invite.users = [];
      state.ui.template.msg = "";
      state.newFormData.privacy.code = "channel";
      state.newFormData.privacy.value = "private to this channel";
      $(".modal-backdrop").remove();
      $("#createnewformModal").modal("show");
      router.push({
        name: "admin-channel-create-form",
        query: { formId: form._id, mode: "edit" },
      });
      $(".modal-backdrop").remove();
      // getAccountFormRetrieveById(form._id);
      // console.log("Selected FOR FOR EDIT:>>", state.selectedFormForEdit.id);
    }
    function selectForm(form: any) {
      state.newFormData.privacy.code = "channel";
      state.newFormData.privacy.value = "private to this channel";
      $(".modal-backdrop").remove();
      // $("#exampleModal").modal("show");
      // console.log("Selected Form:>>", form);
      router.push({
        name: "admin-channel-create-form",
        query: { formId: form._id, mode: "send" },
      });
      // getAccountFormRetrieveByIdForSend(form._id);
    }

    function getAccountFormList() {
      const payLoad = {
        search: {
          // project_id: '278241ec-7e7f-468f-a17b-9504773225c0',
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_name: state.searchBy,
        },
        filter: {
          type: state.filter.type,
          email: state.email,
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_by: {
          title: 1,
        },
        paging: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          per_page: 1000,
          // eslint-disable-next-line @typescript-eslint/camelcase
          current_page: 1,
        },
      };
      state.ui.isLoading.getAccountFormList = true;
      ethitransService
        .channelAccountFormListSearch(payLoad)
        // .projectAccountFormListSearch(payLoad)
        .then((res: any) => {
          console.log("sharedFormList Response Check:>>> ", res.data);
          state.sharedFormList = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.getAccountFormList = false;
        });
    }
    const showInviteMemberToChannel = () => {
      state.ui.isActive.chatChannelAppInviteMember = true;
    }
    function addNewMemberToChannel() {
      // old code
      // console.log("CHECK the daya >>> ", typeof state.approvedMembersCount);
      // console.log("CHECK the main >>> ", typeof state.data.subscriptionDetails.total_channel_member);

      // if (state.approvedMembersCount >= state.data.subscriptionDetails.total_channel_member) {
      //   // if (1 === state.data.subscriptionDetails.total_channel_member) {

      //   state.ui.isActive.upgradeSubscription = true;
      // } else {
      //   state.data.invitationVerifyMsg = "";
      //   state.data.invitationVerify.newMemberList = [];
      //   state.data.invitationVerify.registeredMemberList = [];
      //   state.data.invitationVerify.pendingMemberList = [];
      //   state.data.invitationVerify.showStatus = false;
      //   state.data.inviteAsGuest = false;
      //   getUserList();
      //   $("#successModal").modal("hide");
      //   // $("#inviteMember").modal("show");
      //   showInviteMemberToChannel();
      //   state.data.invitationVerifyMsg = "";
      //   state.inviteMember = "";
      //   state.invite.users = [];
      //   state.ui.template.msg = "";
      //   state.invite.users = [];
      //   state.invite.invites = [];
      //   state.invite.filteredUsers = [];
      //   state.invite.filteredInvites = [];
      // }

      // new code to redirect to invite page
      const channelId: string = state.data.channelDetails.channel.id;
      router.push({
        name: "admin-project-invite-member",
        query: { channelId: channelId, chat: 'true' },
        params: { projectId: state.projectId },
      });
    }
    function hideAddMember() {
      // $("#inviteMember").modal("hide");
      state.errorList = [];
      state.data.inviteAsPublicUser = false;
      state.ui.isActive.chatChannelAppInviteMember = false;

    }
    function changeExistingMember() {
      // // console.log("CHECK MSG", state.existingMember);
      // state.invite.users.push(state.existingMember);
      // // console.log("CHECK MSG AFTER", state.invite.users);
      // state.existingMember = "";
      if (state.invite.users.indexOf(state.existingMember) === -1) {
        state.invite.users.push(state.existingMember);
        // console.log(state.invite.users);
      }
      state.userList = state.userList.filter(
        (e: any) => e != state.existingMember
      );
      state.existingMember = {};
    }
    function changeExistingMemberInForm() {
      if (state.invite.users.indexOf(state.existingMember) === -1) {
        state.invite.users.push(state.existingMember);
        // console.log(state.invite.users);
      }
      state.userList = state.userList.filter(
        (e: any) => e.email != state.existingMember.email
      );
      state.existingMember = {};
    }
    function changeExistingMemberInSendOption() {
      if (state.invite.users.indexOf(state.existingMember) === -1) {
        state.invite.users.push(state.existingMember);
        // console.log(state.invite.users);
      }
      state.userList = state.userList.filter(
        (e: any) => e != state.existingMember
      );
      state.existingMember = {};
    }
    function removeSelectedMember(item: any) {
      // console.log("check the sELCTIOn", item);
      state.invite.users = state.invite.users.filter((e: any) => e != item);
      allUsers.value.push(item);
      state.userList.push(item);
      // console.log("AFTER DELECTION", state.invite.users);
    }

    function changeInviteMember() {
      state.inputError = false;
      state.duplicate = false;
      state.invite.invites.forEach((invite) => {
        if (invite.first_name == '') {
          invite.fNameError = true;
        } else {
          invite.fNameError = false;
        }
        if (invite.last_name == '') {
          invite.lNameError = true;
        } else {
          invite.lNameError = false;
        }
      })
      state.inputError = state.invite.invites.some((item: any) => item.first_name == '' || item.last_name == '');
      state.duplicate = state.invite.invites.some((item: any) => item.email === state.inviteMember);
      if (state.inputError || state.duplicate) return;
      // console.log("CHECK MSG", state.inviteMember);

      if (validationService.isValidEmail(state.inviteMember)) {
        // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (state.invite.invites.length === 0) {
          state.invite.invites.push({
            'email': state.inviteMember,
            'first_name': '',
            'last_name': '',
          });
        } else {
          if (state.invite.invites.filter((item: any) => item.email != state.inviteMember)) {
            state.invite.invites.push({
              'email': state.inviteMember,
              'first_name': '',
              'last_name': '',
            });
          }

        }
        state.invite.invites = state.invite.invites.filter(
          (item, i, ar) => ar.indexOf(item) === i
        );
        state.inviteMember = "";
      }
      // console.log("CHECK MSG AFTER", state.invite.invites);
    }
    function removeSelectedUser(item: any) {
      console.log("check the sELCTIOn", item);
      state.invite.invites = state.invite.invites.filter((e: any) => e != item);
      console.log("AFTER DELECTION", state.invite.invites);
    }
    function downloadFiles() {
      alert("Just only functoin of Download Files");
    }
    function showSuccessModal() {
      $("#successModal").modal("show");
    }
    function removeSelectedImage(image: any) {
      // if (state.postLoading) return false;
      state.imageList = state.imageList.filter((e: any) => e != image);
    }

    function removeSelectedThreadImage(image: any) {
      // if (state.postLoading) return false;
      state.threadImageList = state.threadImageList.filter((e: any) => e != image);
    }
    function validateInviteForm() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (!state.invite.users.length && !state.invite.filteredInvites.length) {
        state.errorList.push("Invite member is required.");
      }
      if (state.inviteMember) {
        if (!validationService.isValidEmail(state.inviteMember)) {
          state.errorList.push("Invalid email.");
        }
      }

      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }

      console.log("CHECK BOOLEAN", state.isErrorStatus);
    }
    function inviteAllUsersChannel() {
      state.errorList = [];
      state.sendChannelId.push(channelId);
      state.isErrorStatus = false;
      state.invite.filteredUsers = [];
      state.invite.filteredInvites = [];
      state.invite.users.forEach((item: any) => {
        state.invite.filteredUsers.push(item.email);
      });
      state.invite.invites.forEach((item: any) => {
        // console.log("CHECK email", item);
        state.invite.filteredInvites.push(item);
      });
      if (state.inviteMember && state.invite.invites.length === 0) {
        if (validationService.isValidEmail(state.inviteMember)) {
          state.invite.filteredInvites.push(state.inviteMember);
        }
      }
      validateInviteForm();
      if (
        state.errorList.length != 0 ||
        state.isErrorStatus ||
        state.isLoading.inviteAllUsers
      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.channel.project.uuid,
        // eslint-disable-next-line @typescript-eslint/camelcase
        invitation_type: "channel_invitation",
        // eslint-disable-next-line @typescript-eslint/camelcase
        channels: state.sendChannelId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_type: state.data.inviteAsGuest ? "guest" : "",
        users: common.removeDuplicate(state.invite.filteredUsers),
        invites: common.removeDuplicate(state.invite.filteredInvites),
      };
      // console.log("CHECK PAYLOAD ON API CALL", payLoad);
      state.isLoading.inviteAllUsers = true;
      ethitransService
        .invitation(payLoad)
        .then((res: any) => {
          console.log("Users Invite Check:>>> ", res.data.message);
          hideAddMember();
          showSuccessModal();
          state.data.invitationVerifyMsg = "";
          state.data.invitationVerify.newMemberList = [];
          state.data.invitationVerify.registeredMemberList = [];
          state.data.invitationVerify.pendingMemberList = [];
          state.invitationMessage = res.data.message ? res.data.message : "";
          getCommunicationList(channelId);
        })
        .catch((error: any) => {
          // console.log(error);
          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            for (const [key, value] of Object.entries(res)) {
              if (Array.isArray(value)) {
                state.errorList.push(value[0]);
              }
            }
          });
        })
        .finally(() => {
          state.isLoading.inviteAllUsers = false;
        });
    }
    // function inviteAllUsers() {
    //   state.errorList = [];
    //   state.sendChannelId.push(channelId);
    //   state.isErrorStatus = false;
    //   state.invite.filteredUsers = [];
    //   state.invite.filteredInvites = [];
    //   state.invite.users.forEach((item: any) => {
    //     state.invite.filteredUsers.push(item.email);
    //   });
    //   state.invite.invites.forEach((item: any) => {
    //     // console.log("CHECK email", item);
    //     state.invite.filteredInvites.push(item);
    //   });
    //   if (state.inviteMember && state.invite.invites.length === 0) {
    //     if (validationService.isValidEmail(state.inviteMember)) {
    //       // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
    //       state.invite.filteredInvites.push(state.inviteMember);
    //     }
    //   }
    //   validateInviteForm();
    //   if (
    //     state.errorList.length != 0 ||
    //     state.isErrorStatus ||
    //     state.isLoading.inviteAllUsers
    //   )
    //     return false;
    //   const payLoad = {
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     project_id: state.channel.project.uuid,
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     invitation_type: "channel_invitation",
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     channels: state.sendChannelId,
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     user_type: state.data.inviteAsGuest ? "guest" : "",
    //     users: common.removeDuplicate(state.invite.filteredUsers),
    //     invites: common.removeDuplicate(state.invite.filteredInvites),
    //   };
    //   // console.log("CHECK PAYLOAD ON API CALL", payLoad);
    //   state.isLoading.inviteAllUsers = true;
    //   ethitransService
    //     .invitation(payLoad)
    //     .then((res: any) => {
    //       console.log("Users Invite Check:>>> ", res.data.message);
    //       hideAddMember();
    //       showSuccessModal();
    //       state.data.invitationVerifyMsg = "";
    //       state.data.invitationVerify.newMemberList = [];
    //       state.data.invitationVerify.registeredMemberList = [];
    //       state.data.invitationVerify.pendingMemberList = [];
    //       state.invitationMessage = res.data.message ? res.data.message : "";
    //       getCommunicationList(channelId);
    //     })
    //     .catch((error: any) => {
    //       // console.log(error);
    //       common.sdCatchErr(error, state.errorList).then((res: any) => {
    //         state.isErrorStatus = true;
    //         for (const [key, value] of Object.entries(res)) {
    //           if (Array.isArray(value)) {
    //             state.errorList.push(value[0]);
    //           }
    //         }
    //       });
    //     })
    //     .finally(() => {
    //       state.isLoading.inviteAllUsers = false;
    //     });
    // }
    function setPendingList(members: any) {
      console.log("Final pending member emited >>> ", members)
      if (!members.length) return false;
      state.pendingList = common.addToPendingList(members, state.pendingList);
      // members.forEach((member: any) => {
      //   state.pendingList.push(member)

      // })
      state.pendingMembersCount = state.pendingList.length;
    }
    function inviteAllUsers(data: any) {
      if (!data) return false;
      // state.isLoading.inviteAllUsers = true;
      // ethitransService
      //   .invitation(data)
      //   .then((res: any) => {
      //     console.log("Users Invite Check:>>> ", res.data.message);
      hideAddMember();
      showSuccessModal();
      // state.data.invitationVerifyMsg = "";
      // state.data.invitationVerify.newMemberList = [];
      // state.data.invitationVerify.registeredMemberList = [];
      // state.data.invitationVerify.pendingMemberList = [];
      state.invitationMessage = data ? data : "";

      getCommunicationList(channelId);
      // })
      // .catch((error: any) => {
      //   // console.log(error);
      //   common.sdCatchErr(error, state.errorList).then((res: any) => {
      //     state.isErrorStatus = true;
      //     for (const [key, value] of Object.entries(res)) {
      //       if (Array.isArray(value)) {
      //         state.errorList.push(value[0]);
      //       }
      //     }
      //   });
      // })
      // .finally(() => {
      //   state.isLoading.inviteAllUsers = false;
      // });
    }
    function verifyInvitation() {
      state.invite.filteredUsers = [];
      state.invite.filteredInvites = [];
      state.data.invitationVerifyMsg = "";
      state.data.invitationVerify.newMemberList = [];
      state.data.invitationVerify.registeredMemberList = [];
      state.data.invitationVerify.pendingMemberList = [];
      state.invite.users.forEach((item: any) => {
        // console.log("CHECK email", item);
        state.invite.filteredUsers.push(item.email);
      });
      state.invite.invites.forEach((item: any) => {
        // console.log("CHECK email", item);
        state.invite.filteredInvites.push(item);
      });
      if (state.inviteMember && state.invite.invites.length === 0) {
        if (validationService.isValidEmail(state.inviteMember)) {
          // console.log("CHECK THE INVITE MEMBER", state.inviteMember);
          state.invite.filteredInvites.push(state.inviteMember);
        }
      }
      validateInviteForm();
      if (
        state.errorList.length != 0 ||
        state.isErrorStatus ||
        state.ui.isPostLoading.verifyInvitation
      )
        return false;

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.projectId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        invitation_type: "channel_invitation",
        users: state.invite.filteredUsers,
        invites: state.invite.filteredInvites,
      };
      // if(state.invite.filteredUsers.length === 0 || state.invite.filteredInvites.length === 0) return false;
      // console.log("CHECK PAYLOAD ON API CALL", payLoad);
      // state.postLoading.inviteAllUsers = true;
      state.ui.isPostLoading.verifyInvitation = true;
      ethitransService
        .verifyInvitation(payLoad)
        .then((res: any) => {
          state.data.invitationVerify.showStatus = true;
          // state.data.invitationVerifyMsg = ''
          state.ui.isPostLoading.verifyInvitation = false;
          console.log("verifyInvitation Check:>>> ", res.data.data);
          // console.log("verifyInvitation Check:>>> ", );
          state.data.invitationVerify.newMemberList = res.data.data.new_members
            ? res.data.data.new_members
            : [];
          state.data.invitationVerify.registeredMemberList = res.data.data
            .registered_members
            ? res.data.data.registered_members
            : [];
          state.data.invitationVerify.pendingMemberList = res.data.data
            .pending_members
            ? res.data.data.pending_members
            : [];
          if (res.data.data) {
            state.data.invitationVerifyMsg = res.data.data;
          } else {
            inviteAllUsersChannel();
          }

          // hideAddMember();
          // showSuccessModal();
          // state.imvitationMessage = res.data.message ? res.data.message : "";
          // getProjectDetail();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.verifyInvitation = false;
        });
    }
    function latestEnabledTags(data: any) {
      state.data.tags.options = data;
    }
    async function masterTagList() {
      const payLoad = {
        type: "enabled",
      };
      await ethitransService
        .masterFileTagList(payLoad)
        .then((res: any) => {
          // console.log("masterTagList Check:>>> ", res.data.data);
          res.data.data.forEach((item: any) => {
            item.label = item.name;
            item.value = item.code;
          });
          state.data.tags.options = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          // common.sdCatchErr(error, state.errorList).then((res: any) => {
          //   state.isErrorStatus = true;
          //   // console.log("CHECK for Error >>>", res)
          //   for (const [key, value] of Object.entries(res)) {
          //     if (Array.isArray(value)) {
          //       state.errorList.push(value[0]);
          //     }
          //   }
          //   // if (res) {
          //   //   if (res.error) {
          //   //     console.log("CHECK for Error >>>", res.error[0])
          //   //     // state.errorList.push(res.email[0]);
          //   //     // state.errorList = [...state.errorList]
          //   //   }
          //   // }
          // });
        })
        .finally(() => {
          // state.postLoading = false;
        });
    }

   
    function addFile(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      // console.log("FILE READER CHECK >>>", reader);
      reader.onload = (e: any) => {
        // console.log("BIT BIT>>", e);
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
        if (file.size > state.data.uploadMaxSize) {
          data.isValidToUpload = false;
        } else {
          data.isvalidToUpload = true;
        }
        const fileName = file.name;
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const dotIndex = fileName.indexOf('.');
        const nameBeforeDot = fileName.substring(0, dotIndex);
        const newFileName = `${nameBeforeDot}_${timestamp}`;
        data.base64 = e.target.result;
        data.fileName = newFileName + '.' + extension

        data.extension = extension;
        data.isuploded = false;
        data.size = common.formatBytes(parseInt(file.size));
        state.imageList.unshift(data);
        if (state.imageList && state.imageList.length) {
          $("#chat-screen-content").animate(
            { scrollTop: $("#chat-screen-content")[0].scrollHeight },
            1000
          );
        }
        // }
      };
      reader.readAsDataURL(file);
      masterTagList();
    }



    function readImage(myfile: any) {
      console.log('original size: ' + common.formatBytes(parseInt(myfile.size)));

      new Compressor(myfile, {
        quality: 0.6,

        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(file: any) {
          addFile(file);

          // Send the compressed image file to server with XMLHttpRequest.
          // axios.post('/path/to/upload', formData).then(() => {
          //   console.log('Upload success');
          // });
        },
        error(err: any) {
          console.log(err.message);
        },
      });
    }
    const handleInputChatMessage = (event: any) => {
      // console.log("Check >>> ", event.target.value)
      // if (event.target.value.includes("<img")) {
      state.message = state.message.replace(/<img[^>]*>/g, "");
      // }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.addEventListener("paste", (e: any) => {
      if (e.clipboardData.files.length > 0) {
        // console.log('listended to PASTE', e.clipboardData.files)
        const fileInput: any = document.querySelector("#sendMsg");

        fileInput.files = e.clipboardData.files;
        // console.log("CHECK FILE >>>", e.clipboardData.files[0].type);
        if (
          e.clipboardData.files[0].type.startsWith("image/") ||
          e.clipboardData.files[0].type.startsWith("application/")
        ) {
          readImage(e.clipboardData.files[0]);

        }
      }
    });
    function isExtensionValid(extension: any) {
      return state.valideFiles.includes(extension);
    }
    function onDocumentChange(event: any) {
      state.item.error.videoError = false;
      state.item.error.fileError = false;
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const mimeType = file.type;
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();
        if (mimeType == "application/pdf" || (extensionLower == 'heic')) {
          addFile(file);
        }
        else if (file.type.includes("video")) {
          if (
            extension === "mp4" ||
            extension === "mov" ||
            extension === "MOV" ||
            extension === "wmv" ||
            extension === "ogg" ||
            extension === "webm" ||
            extension === "avi" ||
            extension === "x-ms-wmv" ||
            extension === "ogg"
          ) {
            addFile(file);
            // console.log("Check for Extension >>>", extension);
            state.item.error.videoError = false;
          } else {
            state.item.error.videoError = true;
            $("#chat-screen-content").animate(
              { scrollTop: $("#chat-screen-content")[0].scrollHeight },
              1000
            );
          }
          // if()
        } else {
          if (mimeType.startsWith('image/') && isExtensionValid(extensionLower)) {
            readImage(file);
          } else if (state.valideFiles.includes(extensionLower)) {
            addFile(file);
          }
          else {
            // show error message
            state.item.error.fileError = true;

          }
        }
        // console.log("CHECK DATA WHEN UPLOADED>>", file);
      }
      // $("#gallery-photo-add").val("")
      window.scrollTo(0, document.body.scrollHeight);
      state.data.active = false;
      $("#create-document-upload").val('');
    }
    function readImageInThread(file: any) {
      const data = {} as any;
      const reader = new FileReader();
      console.log("FILE READER CHECK >>>", reader);
      reader.onload = (e: any) => {
        // console.log("BIT BIT>>", e);
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
        if (file.size > state.data.uploadMaxSize) {
          data.isValidToUpload = false;
        } else {
          data.isvalidToUpload = true;
        }
        const fileName = file.name;
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const dotIndex = fileName.indexOf('.');
        const nameBeforeDot = fileName.substring(0, dotIndex);
        const newFileName = `${nameBeforeDot}_${timestamp}`;
        data.base64 = e.target.result;
        data.fileName = newFileName + '.' + extension

        data.extension = extension;
        data.isuploded = false;
        data.size = common.formatBytes(parseInt(file.size));
        state.threadImageList.push(data);

      };
      reader.readAsDataURL(file);
      masterTagList();
    }
    function onDocumentUploadThread(event: any) {
      state.item.error.videoError = false;
      const files = event.target.files || event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extensionLower = extension.toLowerCase();

        if (file.type.includes("video")) {
          if (
            extension === "mp4" ||
            extension === "mov" ||
            extension === "wmv" ||
            extension === "ogg" ||
            extension === "webm" ||
            extension === "avi" ||
            extension === "x-ms-wmv" ||
            extension === "ogg"
          ) {
            readImageInThread(file);
            console.log("Check for FILES >>>", file);
            state.item.error.videoError = false;
          } else {
            state.item.error.videoError = true;

          }
        } else {
          readImageInThread(file);
          console.log("Check for FILES  else>>>", file);
        }
        // console.log("CHECK DATA WHEN UPLOADED>>", file);
      }
      // $("#gallery-photo-add").val("")
    }
    function enableSelectedFileTag(document: any) {
      // console.log("Enable Doc >> ", document.id);
      if (document.enableStatus) return false;
      const payLoad = {
        id: document.id,
      };
      state.ui.isPostLoading.enableSelectedFileTag = true;
      document.inProgress = true;
      ethitransService
        .enableMasterFileTag(payLoad)
        .then((res: any) => {
          console.log("enable File Tag :>>> ", res.data.data);
          state.data.disabledFileTagList =
            state.data.disabledFileTagList.filter(
              (data: any) => data.id != document.id
            );
          masterTagList();
          // state.data.successFulMessage = "File Tag Enabled Successfully.";
          // state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.enableSelectedFileTag = false;
          document.inProgress = false;
        });
    }
    function checkForDisabledTags() {
      if (state.imageList && state.imageList.length) {
        masterTagListForValidation(false);
      } else {
        state.data.showAddFileTag = true;
      }
    }
    function closeReplyCommunication() {
      state.data.replyClassStatus = !state.data.replyClassStatus;
      state.data.showReplyCommunication = false;
      router.push({
        name: "admin-channel",
        params: { channelId: channelId },
      });
    }

    async function checkInternet() {
      try {
        await fetch('https://www.google.com/favicon.ico', { mode: 'no-cors', cache: 'no-cache' });
        store.dispatch('saveOnlineStatus', 1);
      } catch (error) {
        store.dispatch('saveOnlineStatus', 0);
        console.log('No internet connection');
      }
    }







    function createCommunicationList() {
      state.scrollActive = false;
      if (routerQuery.query.page) {
        const restPageCheck: any = routerQuery.query.page;
        const currentPage = parseInt(restPageCheck);
        console.log("PAGE >>> ", currentPage);
        console.log("BEFORE ARRAY CHECK >>> ", state.data.scrolledPages);
        state.data.scrolledPages = state.data.scrolledPages.filter(
          (e: any) => e != currentPage
        );
        console.log("Final CHECK >>> ", state.data.scrolledPages);
      }
      const isUploadedFile = false;

      if (state.postLoading) return false;
      state.item.error.erorList = [];
      state.item.hasError = false;
      // console.log("AAYo 2", state.postLoading);
      if (state.imageList.length === 0) {
        if (!state.message) return false;
        // console.log("AAYo", state.postLoading);
      }
      if (state.imageList.length >= 1) {
        state.isFile = true;
      }
      const messageId = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        //chnage using communication reply
        // channel_id: routerQuery.params.channelId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
        message: state.message,
        file: state.isFile,
        type: "text",
        messageId: messageId
      };

      if (!(socket && socket.connected)) {
        setSocket();
        // alert("not connected  to socket");
        // return false;
      }
      // prem bro future code required uncomment this
      state.postLoading = true;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (routerQuery.query.communication_id) {
        closeReplyCommunication();
      }
      if (routerQuery.query.page && routerQuery.query.communicationId) {
        router.push({
          name: "admin-channel",
          params: { channelId: channelId },
        });
      }
      state.isReplyMessage = false;
      if (state.data.intervalId == 0) {
        state.data.intervalId = setInterval(() => {
          checkInternet();
        }, 10000);
      }
      // message along with attachments here
      if (state.isFile == true) {
        temporaryMessge(messageId, state.message, state.imageList);
        state.fileLoadTime = 300;
        state.ui.isPostLoading.getCommunicationList = true;
        uploadAttachmentByChannel(
          state.message,
          // common.findIndex(state.imageList, item),
          // item.fileName,
          // item.base64,
          // item.extension,
          channelId,
          // item,
          state.imageList,
          messageId
        )
          .then((result: any) => {
            removeUnsentMessage(result.data.data.messageId);
            state.ui.isPostLoading.getCommunicationList = false;
            getCommunicationList(channelId);
            // got final result
            resetMessageStatusIncrementally(state.communicationList, result.data.data.messageId);
          })
          .catch((err) => {
            // got error
          })
          .finally(() => {
            state.imageList = [];
            state.postLoading = false;
            state.message = "";
            state.isFile = false;
          });

      } else {
        // const socket = getSocket();
        // socket.emit('message',payLoad);
        temporaryMessge(messageId, state.message, []);
        // normal message only without file triggers this
        ethitransService
          .createCommunication(payLoad)
          .then((res: any) => {
            clearInterval(state.data.intervalId);
            removeUnsentMessage(res.data.data.messageId);
            resetMessageStatusIncrementally(state.communicationList, res.data.data.messageId);
            // messageSendStatus
            // state.communicationList


            const textarea: any = document.getElementById("sendMsg");

            textarea.setAttribute("style", "");
            textarea.value = "";
            // state.data.typeDisabled = false;
            // $('#chat-body')[0].scrollTop = $('#chat-body')[0].scrollHeight;
          })
          .catch((error: any) => {
            // console.log(error);
            common
              .sdCatchErr(error, state.item.error.erorList)
              .then((res: any) => {
                state.item.hasError = true;
                for (const [key, value] of Object.entries(res)) {
                  if (Array.isArray(value)) {
                    state.item.error.erorList.push(value[0]);
                  }
                }
              });
          })
          .finally(() => {
            state.imageList = [];
            state.postLoading = false;
            state.data.typeDisabled = true;
            state.isFile = false;
            state.message = "";
          });
        // }
      }

      setTimeout(scrollToLast, 100);
    }

    const someEventHander = function (event: any) {
      console.log("do something");
    };
    // document.addEventListener("keydown", function (event: any) {
    //   //   // console.log("Global Enter Pressed");
    //   //   if (!enableEnterKey.value) return false;
    //   //   // console.log("ALLOWED");
    //   // if(!enableEnterKey.value){
    //   if (event.keyCode === 13 && !event.shiftKey && !event.ctrlKey) {
    //     if (state.imageList && state.imageList.length) {
    //       createCommunicationList();
    //     }
    //   }
    //   // }
    // });

    // function onEditorInput(data: any) {
    //   // if (!data) return false;
    //   // data = common.replaceURLs(data);
    //   // console.log("Check >>> ",  data)
    //   document.addEventListener("keydown", function (event: any) {
    //     if (event.keyCode === 13 && !event.shiftKey) {
    //       // console.log("TEST for Enter Before", state.message);
    //       if (data.endsWith("<p>&nbsp;</p>")) {
    //         state.message = state.message.replace(
    //           new RegExp("<p>&nbsp;</p>", "g"),
    //           ""
    //         );
    //         console.log("TEST for Enter  After", state.message);
    //         // state.message = state.message.replace(/<p>&nbsp;/g, "");
    //         // state.message = state.message.replace(\</p>\g, "");
    //       }
    //       state.message = state.message.replace(
    //         /<a/g,
    //         `<a target='_blank' rel='noopener noreferrer'`
    //       );
    //       if (state.message.includes("https://")) {
    //         // state.message = state.message.replace(/href="/g, `href="https://`);
    //       } else if (state.message.includes("http://")) {
    //         console.log("GOOD");
    //       } else {
    //         state.message = state.message.replace(/href="/g, `href="https://`);
    //       }
    //       state.data.typeDisabled = true;

    //       createCommunicationList();
    //     }
    //   });
    // }
    function isKeyPressed(event: any) {
      if (event.key === "Enter") {
        if (event.shiftKey) {
          console.log("shift enter was pressed");
        }
      }
    }
    function chatMessageEnterSetting(data: boolean) {
      // if (common.onlySpaces(state.message)) {
      //   console.log("<<Removed >>");
      //   state.message = "";
      // }
      if (state.message.startsWith("<p></p>")) {
        console.log("<<Removed >>");
        state.message = "";
      }
      if (state.message.startsWith("<p><br></p>")) {
        console.log("<<Removed >>");
        state.message = "";
      }

      // if (!state.message || !state.imageList) return false;
      createCommunicationList();
    }

    function getChannelFormList() {
      const payLoad = {
        search: {
          // project_id: '278241ec-7e7f-468f-a17b-9504773225c0',
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_name: state.searchBy,
        },
        filter: {
          type: state.filter.type,
          email: state.email,
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_by: {
          title: 1,
        },
        paging: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          per_page: state.item.paging.getChannelFormList.perPage,
          // eslint-disable-next-line @typescript-eslint/camelcase
          current_page: state.item.paging.getChannelFormList.currentPage,
        },
      };
      state.ui.isLoading.getChannelFormList = true;
      ethitransService
        .channelAccountFormListSearch(payLoad)
        .then((res: any) => {
          console.log("getChannelFormList Response Check HHHH:>>> ", res.data);
          state.formList = res.data ? res.data : {};
          if (res.data.totalPages) {
            state.item.paging.getChannelFormList.totalPages =
              res.data.totalPages;
          }
          if (res.data.totalDocs) {
            state.item.paging.getChannelFormList.totalDocs = res.data.totalDocs;
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.getChannelFormList = false;
        });
    }
    function nextPage(event: any) {
      state.item.paging.getChannelFormList.currentPage = event;
      getChannelFormList();
    }
    function channelCommunicationForm(id: any) {
      const data = state.ui.template.response;
      console.log("CHECK DATA >>>>>>>>>>", data);
      let payLoad: any = {};
      const usersArray = [] as any;
      if (state.data.clientDocumentRetrieve.edoc_id) {
        state.invite.users.forEach((item: any) => {
          usersArray.push(item);
        });
        payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          communication_id: id,
          type: "client_document",
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_id: data._id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_document_id: state.data.clientDocumentRetrieve.uuid,
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_document_name: state.data.clientDocumentRetrieve.name,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sent_to_users: usersArray,
        };
      } else {
        payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          communication_id: id,
          type: "form",
          // eslint-disable-next-line @typescript-eslint/camelcase
          // form_data: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_id: data._id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_name: data.title,
          // eslint-disable-next-line @typescript-eslint/camelcase
          form_desc: data.description,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sent_to_users: data.sentToUsers,
          // },
        };
      }
      // state.owner

      console.log("<< ChannelCommunicationForm CHECK >>", payLoad);
      state.ui.isPostLoading.createFormTemplate = true;
      ethitransService
        .channelCommunicationFormData(payLoad)
        .then((res: any) => {
          console.log("ChannelCommunicationForm Response Check:>>> ", res.data);
          state.message = "";
          // console.log("CommunicationList Response Check:>>> ", res.data.data);
          state.channel = res.data.data.channel ? res.data.data.channel : {};
          state.data.projectMember = res.data.data.project_member
            ? res.data.data.project_member
            : {};
          res.data.data.messages.data.forEach((item: any) => {
            if (item.form_data != null) {
              console.log("check msg :>>", item.form_data);
              if (item.form_data && item.form_data.sent_to_users) {
                console.log(
                  "Final :>>",
                  JSON.parse(item.form_data.sent_to_users)
                );
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.form_data.sent_to_users = JSON.parse(
                  item.form_data.sent_to_users
                );
              }
            }
          });
          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((data: any) => {
              data.allowEdit = false;
              if (data.attachments && data.attachments.length) {
                data.attachments.forEach((attachment: any) => {
                  attachment.convertedFileSize = common.formatBytes(
                    parseInt(
                      attachment.file_size
                      // parseInt(data.file_size.split(" ")[0])
                    )
                  );
                });
              }
            });
          }
          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((item: any) => {
              if (item.user && item.user.email) {
                if (
                  item.user.email === localStorage.getItem("current_user_email")
                ) {
                  // console.log("email", item.user.email);
                  // console.log("active email", state.data.activeUserEmail);
                  item.editStatus = true;
                } else {
                  item.editStatus = false;
                }
              }
            });
          }
          res.data.data.messages.data.forEach((data: any) => {
            if (data.attachments) {
              data.attachments.forEach((video: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                if (video.file_type === "mov" || video.file_type === "MOV") {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  video.file_type = "mp4";
                }
              });

              // console.log("Data Check >>> ", data);
            }
          });
          updateCommunicationList(res.data.data.messages.data
            ? res.data.data.messages.data
            : [])
          let admin: any = {};

          if (
            res.data.data.project_role.level === 1 &&
            res.data.data.project_role.code === "owner"
          ) {
            admin = res.data.data.project_role;
          } else {
            if (
              res.data.data.project_role.level === 2 &&
              res.data.data.project_role.code === "co-owner"
            ) {
              admin = res.data.data.project_role;
            }
          }
          console.log("ADMIN >>>> ", admin);
          res.data.data.channel.members.forEach((member: any) => {
            member.isChangeRole = false;
            if (admin && admin.level && admin.code) {
              if (member.user && member.user.email) {
                if (member.user.email !== state.email) {
                  member.isChangeRole = true;
                  // console.log("CHECK MEMBER >>> ", member.user);
                }
              }
            }
          });
          state.memberList = res.data.data.channel.members
            ? res.data.data.channel.members
            : [];
          // scrollAtButton();
          scrollToLast();
          $("#templateupload").modal("hide");
          if (state.data.clientDocumentRetrieve.edoc_id) {
            state.data.clientDocumentRetrieve = {};
            state.invite.filteredUsers = [];
            state.invite.users = [];
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.createFormTemplate = false;
        });
    }
    function formMessage(msg: string, users: any) {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
        message: msg,
        usersList: users,
        type: "form",
      };
      state.postLoading = true;
      ethitransService
        .createCommunication(payLoad)
        .then((res: any) => {
          console.log("FORM MSG HERE >>> ", res.data.data);
          // console.log(
          //   "<<<Create Communication Response Check:>>> ",
          //   res.data.data
          // );

          state.message = "";
          state.data.scrollBottom = true;
          state.data.scrollTop = false;

          // console.log("CommunicationList Response Check:>>> ", res.data.data);
          state.channel = res.data.data.channel ? res.data.data.channel : {};
          state.data.projectMember = res.data.data.project_member
            ? res.data.data.project_member
            : {};
          res.data.data.messages.data.forEach((item: any) => {
            if (item.form_data != null) {
              console.log("check msg :>>", item.form_data);
              if (item.form_data && item.form_data.sent_to_users) {
                console.log(
                  "Final :>>",
                  JSON.parse(item.form_data.sent_to_users)
                );
                // eslint-disable-next-line @typescript-eslint/camelcase
                item.form_data.sent_to_users = JSON.parse(
                  item.form_data.sent_to_users
                );
              }
            }
          });
          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((data: any) => {
              data.allowEdit = false;
              if (data.attachments && data.attachments.length) {
                data.attachments.forEach((attachment: any) => {
                  attachment.convertedFileSize = common.formatBytes(
                    parseInt(
                      attachment.file_size
                      // parseInt(data.file_size.split(" ")[0])
                    )
                  );
                });
              }
            });
          }
          if (
            res.data.data.messages.data &&
            res.data.data.messages.data.length
          ) {
            res.data.data.messages.data.forEach((item: any) => {
              if (item.user && item.user.email) {
                if (
                  item.user.email === localStorage.getItem("current_user_email")
                ) {
                  // console.log("email", item.user.email);
                  // console.log("active email", state.data.activeUserEmail);
                  item.editStatus = true;
                } else {
                  item.editStatus = false;
                }
              }
            });
          }
          res.data.data.messages.data.forEach((data: any) => {
            if (data.attachments) {
              data.attachments.forEach((video: any) => {
                // eslint-disable-next-line @typescript-eslint/camelcase
                if (video.file_type === "mov" || video.file_type === "MOV") {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  video.file_type = "mp4";
                }
              });

              // console.log("Data Check >>> ", data);
            }
          });
          updateCommunicationList(res.data.data.messages.data
            ? res.data.data.messages.data
            : [])
          let admin: any = {};

          if (
            res.data.data.project_role.level === 1 &&
            res.data.data.project_role.code === "owner"
          ) {
            admin = res.data.data.project_role;
          } else {
            if (
              res.data.data.project_role.level === 2 &&
              res.data.data.project_role.code === "co-owner"
            ) {
              admin = res.data.data.project_role;
            }
          }

          res.data.data.channel.members.forEach((member: any) => {
            member.isChangeRole = false;
            if (admin && admin.level && admin.code) {
              if (member.user && member.user.email) {
                if (member.user.email !== state.email) {
                  member.isChangeRole = true;
                  // console.log("CHECK MEMBER >>> ", member.user);
                }
              }
            }
          });
          state.memberList = res.data.data.channel.members
            ? res.data.data.channel.members
            : [];
          // scrollAtButton();
          // scrollToLast();
          channelCommunicationForm(res.data.data.communication.id);
          // $("#chat-body").animate({ scrollTop: $("#chat-body")[0].scrollHeight}, 1000);

          // $('#chat-body')[0].scrollTop = $('#chat-body')[0].scrollHeight;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.postLoading = false;
        });
    }

    function createNewFormValiation() {
      state.item.error.erorListNewForm = [];
      state.item.hasError = false;
      console.log("data check", state.newFormData.title);
      console.log("data Array List", state.newFormData.elements);

      if (!state.newFormData.title) {
        state.item.error.erorListNewForm.push("Form name is required.");
      }
      if (!state.newFormData.privacy.value) {
        state.item.error.erorListNewForm.push("Please select the privacy.");
      }
      if (
        !state.newFormData.elements ||
        state.newFormData.elements.length === 0
      ) {
        state.item.error.erorListNewForm.push(
          "Please select At least 1 element from the left side Elements."
        );
      }

      if (
        !state.item.error.erorListNewForm &&
        !state.item.error.erorListNewForm.length
      ) {
        state.item.hasError = false;
      } else if (state.item.error.erorListNewForm.length != 0) {
        state.item.hasError = true;
      }
      console.log("Error List", state.item.error.erorListNewForm);
      console.log("Error Has error", state.item.hasError);
    }
    function showUserModal() {
      // console.log("Hello");
      state.invite.users = [];
      state.ui.template.msg = "";
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;
      createNewFormValiation();
      if (
        state.item.error.erorListNewForm &&
        state.item.error.erorListNewForm.length &&
        state.item.hasError &&
        state.newFormData.privacy.value.length
      )
        return false;
      getUserList();
      // if (
      //   (state.item.error.erorListNewForm &&
      //   state.item.error.erorListNewForm.length != 0) &&
      //   state.item.hasError &&
      //   !state.newFormData.privacy
      // )
      //   return false
      $("#senduserdModal").modal("show");
    }
    function closeInvitationModal() {
      $("#successModal").modal("hide");
    }
    function showNewFormConformation() {
      createNewFormValiation();
      if (
        state.item.error.erorListNewForm &&
        state.item.error.erorListNewForm.length &&
        state.item.hasError
      )
        return false;
      $("#confirmNewForm").modal("show");
    }
    function hideNewFormConformation() {
      $("#confirmNewForm").modal("hide");
    }
    function showNewTitleInCreateFormEdit(data: any) {
      // editSelectedElementOfCreateNewForm
      console.log("Data Selected >>", data);
      state.selectedElement.type = data;
      // console.log("Title Data Check: >>", state.selectedElement.type);
      // console.log("edit Title:>>", state.selectedFormForEdit.title);
      // console.log("edit Description:>>", state.selectedFormForEdit.description);
      state.selectedElement.title = state.newFormData.title;
      state.selectedElement.description = state.newFormData.description;
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#editSelectedElementOfCreateNewForm").modal("show");
    }
    function showNewElementEdit(data: any, type: any) {
      console.log("EDIT SECTION CHECK >>> ", data);
      const formIndex = common.findIndex(state.newFormData.elements, data);
      console.log("Selected Form Index", formIndex);
      state.selectedElement.element = data;
      state.selectedElement.type = type;
      state.selectedElement.index = common.findIndex(
        state.newFormData.elements,
        data
      );
      state.selectedElement.title = data.title;
      state.selectedElement.description = data.description;
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#editSelectedElementOfCreateNewForm").modal("show");
    }
    function hideEditCreateNewForm() {
      $("#editSelectedElementOfCreateNewForm").modal("hide");
    }
    function updateEditNewForm() {
      console.log("IN");
      console.log("Check:>>>", state.selectedElement.type);
      if (state.selectedElement.type === "title") {
        updateEditFormElementsValidation("title");
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // console.log("<<< Title Change >>>");
        state.newFormData.title = state.selectedElement.title;
        state.newFormData.description = state.selectedElement.description;
      } else if (
        state.selectedElement.type === "textbox" ||
        state.selectedElement.type === "section"
      ) {
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // state.selectedFormForEdit.title = state.selectedElement.title;
        state.newFormData.elements[state.selectedElement.index].title =
          state.selectedElement.title;
        state.newFormData.elements[state.selectedElement.index].description =
          state.selectedElement.description;
        // console.log("<<< Title Change >>>");
      } else if (state.selectedElement.type === "textArea") {
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // state.selectedFormForEdit.title = state.selectedElement.title;
        // console.log("<<< Title Change >>>");
        state.newFormData.elements[state.selectedElement.index].title =
          state.selectedElement.title;
      } else if (
        state.selectedElement.type === "radioButton" ||
        state.selectedElement.type === "single_select" ||
        state.selectedElement.type === "checkBox"
      ) {
        updateEditFormElementsValidation(state.selectedElement.type);
        if (
          state.item.error.erorList &&
          state.item.error.erorList.length &&
          state.item.hasError
        )
          return false;
        // console.log("<<< Title Change >>>");

        state.newFormData.elements[state.selectedElement.index] =
          state.selectedElement.element;
      }
      state.item.error.erorList = [];
      state.item.hasError = false;
      $("#editSelectedElementOfCreateNewForm").modal("hide");
    }
    function showNewElementDelete(data: any, type: any) {
      $("#deleteSelectedElementInCreateNewForm").modal("show");
      state.selectedElement.element = data;
      state.selectedElement.type = type;
    }
    function deleteSelectedElementOfCreateForm() {
      state.newFormData.elements = state.newFormData.elements.filter(
        (item: any) => item != state.selectedElement.element
      );
      $("#deleteSelectedElementInCreateNewForm").modal("hide");
    }
    function hideDeleteSelectedElementInCreateNew() {
      $("#deleteSelectedElementInCreateNewForm").modal("hide");
    }
    function createNewFormEmailValiation() {
      console.log("ALL  IN");
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;

      console.log("EMAIL CHECK", state.invite.users);

      if (state.invite.users && !state.invite.users.length) {
        state.item.error.erorListSendForm.push("Atleast 1 Email is required.");
      }

      if (
        !state.item.error.erorListSendForm &&
        !state.item.error.erorListSendForm.length
      ) {
        state.item.hasError = false;
      } else if (state.item.error.erorListSendForm.length != 0) {
        state.item.hasError = true;
      }
      console.log("Error List", state.item.error.erorListSendForm);
      console.log("Error Has error", state.item.hasError);
    }

    function createNewForm() {
      // erorListSendForm
      // console.log("owner Check check:>>>", state.owner)
      state.item.error.erorListNewForm = [];
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;

      // console.log("Before");
      // createNewFormEmailValiation();

      if (state.ui.isPostLoading.createNewForm) return false;
      console.log("After");
      const usersArray = [] as any;
      state.invite.users.forEach((item: any) => {
        usersArray.push(item);
      });
      const payLoad = {
        title: state.newFormData.title,
        description: state.newFormData.description,
        elements: state.newFormData.elements,
        sentToUsers: usersArray,
        privacy: {
          code: state.newFormData.privacy.code,
          name: state.newFormData.privacy.value,
        },
        owner: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_by: {
            name: state.owner.created_by.full_name,
            email: state.owner.created_by.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
        },
      };
      // console.log("New Form PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.createNewForm = true;
      ethitransService
        .accountformSave(payLoad)
        .then((res: any) => {
          console.log("createNewForm Response Check:>>> ", res.data);
          state.ui.template.response = res.data;
          if (state.formList.docs && state.formList.docs.length) {
            state.formList.docs.unshift(res.data);
          }
          $("#senduserdModal").modal("hide");
          $("#innerchannelModal").modal("hide");
          if (state.ui.template.msg || (usersArray && usersArray.length)) {
            formMessage(state.ui.template.msg, usersArray);
            $("#templateupload").modal("hide");
          }
          hideNewFormConformation();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.createNewForm = false;
        });
    }
    function closeChannel() {
      if (state.data.subscriptionDetails.archive_channel === 0) {
        // if (1 === state.data.subscriptionDetails.total_channel_member) {

        state.ui.isActive.upgradeSubscription = true;
      } else {
        state.item.error.erorList = [];
        state.item.hasError = false;
        if (state.ui.isPostLoading.closeChannel || !channelId) return false;
        const payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
        };
        // console.log("New Form PAYLOAD CHECK ", payLoad);
        state.ui.isPostLoading.closeChannel = true;
        ethitransService
          .closeChannel(payLoad)
          .then((res: any) => {
            console.log("closeChannel Response Check:>>> ", res.data);
            state.channel = res.data.data;
          })
          .catch((error: any) => {
            // console.log(error);
            common
              .sdCatchErr(error, state.item.error.erorList)
              .then((res: any) => {
                state.item.hasError = true;
                for (const [key, value] of Object.entries(res)) {
                  if (Array.isArray(value)) {
                    state.item.error.erorList.push(value[0]);
                  }
                }
              });
          })
          .finally(() => {
            state.ui.isPostLoading.closeChannel = false;
          });
      }
    }
    function downloadProjectFiles() {



      if (state.data.subscriptionDetails.export_channel_pdf === 0) {
        // if (1 === state.data.subscriptionDetails.total_channel_member) {

        state.ui.isActive.upgradeSubscription = true;
      } else {
        state.item.error.erorList = [];
        state.item.hasError = false;
        if (state.ui.isPostLoading.downloadProjectFiles || !channelId)
          return false;
        const payLoad = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
        };
        // console.log("New Form PAYLOAD CHECK ", payLoad);
        state.ui.isPostLoading.downloadProjectFiles = true;
        ethitransService
          .projectFileDownload(payLoad)
          .then((res: any) => {
            // console.log("downloadProjectFiles Response Check:>>> ", res.data.data);
            window.open(res.data.data, "_blank");
          })
          .catch((error: any) => {
            // console.log(error);
            common
              .sdCatchErr(error, state.item.error.erorList)
              .then((res: any) => {
                state.item.hasError = true;
                for (const [key, value] of Object.entries(res)) {
                  if (Array.isArray(value)) {
                    state.item.error.erorList.push(value[0]);
                  }
                }
              });
          })
          .finally(() => {
            state.ui.isPostLoading.downloadProjectFiles = false;
          });
      }
    }
    function sendCreateNewForm() {
      createNewFormEmailValiation();
      if (state.item.error.erorListSendForm.length != 0 || state.item.hasError)
        return false;
      createNewForm();
    }
    function sendTemplateFile() {
      createNewFormEmailValiation();
      if (
        state.ui.isPostLoading.createFormTemplate ||
        state.item.error.erorListSendForm.length != 0 ||
        state.item.hasError
      )
        return false;
      const usersArray = [] as any;
      state.invite.users.forEach((item: any) => {
        usersArray.push(item);
      });

      const payLoad = {
        title: state.formData.title,
        description: state.formData.description,
        message: state.ui.template.msg,
        // eslint-disable-next-line @typescript-eslint/camelcase
        elements: state.formData.elements,
        sentToUsers: usersArray,
        privacy: {
          code: state.newFormData.privacy.code,
          name: state.newFormData.privacy.value,
        },
        owner: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_by: {
            name: state.owner.created_by.full_name,
            email: state.owner.created_by.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
        },
      };

      // const payLoad = {
      //   title: state.formData.title,
      //   description: state.formData.description,
      //   message: state.ui.template.msg,
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   elements: state.formData.elements,
      //   owner: state.owner,
      //   privacy: {
      //     code: "channel",
      //     name: state.newFormData.privacy,
      //   },
      //   sentToUsers: usersArray,
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   master_form_id: state.formData.master_form_id,
      // };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.createFormTemplate = true;
      ethitransService
        .accountformSave(payLoad)
        .then((res: any) => {
          console.log("UserList Response Check:>>> ", res.data);
          state.ui.template.response = res.data;
          if (state.formList.docs && state.formList.docs.length) {
            state.formList.docs.unshift(res.data);
          }
          $("#mentionuser").modal("hide");
          $("#exampleModal").modal("hide");
          $("#editExampleModal").modal("hide");
          // channelCommunicationForm(res.data, state.ui.template.msg)
          // channelCommunicationForm(state.ui.template.msg)
          if (state.ui.template.msg || (usersArray && usersArray.length)) {
            formMessage(state.ui.template.msg, usersArray);
            $("#templateupload").modal("hide");
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.createFormTemplate = false;
        });
    }
    function editNewFormValiation() {
      state.item.error.erorListSaveCustomSelectedForm = [];
      state.item.hasError = false;
      console.log("data check", state.newFormData.title);
      console.log("data Array List", state.selectedFormForEdit.elements);
      if (!state.selectedFormForEdit.title) {
        state.item.error.erorListSaveCustomSelectedForm.push(
          "Form name is required."
        );
      }
      if (!state.newFormData.privacy.value) {
        state.item.error.erorListSaveCustomSelectedForm.push(
          "Please select the privacy."
        );
      }
      if (
        !state.selectedFormForEdit.elements &&
        state.selectedFormForEdit.elements.length === 0
      ) {
        state.item.error.erorListSaveCustomSelectedForm.push(
          "Please select At least 1 element from the left side Elements."
        );
      }

      if (
        !state.item.error.erorListSaveCustomSelectedForm &&
        !state.item.error.erorListSaveCustomSelectedForm.length
      ) {
        state.item.hasError = false;
      } else if (state.item.error.erorListSaveCustomSelectedForm.length != 0) {
        state.item.hasError = true;
      }
      console.log(
        "Error List",
        state.item.error.erorListSaveCustomSelectedForm
      );
      console.log("Error Has error", state.item.hasError);
    }
    function showCustomFormConformation() {
      editNewFormValiation();
      if (
        (state.item.error.erorListSaveCustomSelectedForm &&
          state.item.error.erorListSaveCustomSelectedForm.length) ||
        state.item.hasError ||
        !state.newFormData.privacy.value
      )
        return false;
      $("#confirmEditForm").modal("show");
    }
    function hideConfirmEditForm() {
      $("#confirmEditForm").modal("hide");
    }
    function saveCustomSelectedForm() {
      // console.log("Save the custom Edit form 1", state.selectedFormForEdit);
      // console.log("Save the custom Edit form", state.selectedFormForEdit._id);
      // editNewFormValiation();
      if (state.ui.isPostLoading.createFormTemplate) return false;
      // console.log("saveCustomSelectedForm CHECK");
      const usersArray = [] as any;
      state.invite.users.forEach((item: any) => {
        usersArray.push(item);
      });
      const payLoad = {
        title: state.selectedFormForEdit.title,
        description: state.selectedFormForEdit.description,
        message: state.ui.template.msg,
        // eslint-disable-next-line @typescript-eslint/camelcase
        elements: state.selectedFormForEdit.elements,
        sentToUsers: usersArray,
        privacy: {
          code: state.newFormData.privacy.code,
          name: state.newFormData.privacy.value,
        },
        owner: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_by: {
            name: state.owner.created_by.full_name,
            email: state.owner.created_by.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
        },
      };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.ui.isPostLoading.createFormTemplate = true;
      ethitransService
        .accountformSave(payLoad)
        .then((res: any) => {
          console.log("saveCustomSelectedForm Response Check:>>> ", res.data);
          state.ui.template.response = res.data;
          if (state.formList.docs && state.formList.docs.length) {
            state.formList.docs.unshift(res.data);
          }
          // state.ui.template.response = res.data;
          $("#sendformModal").modal("hide");
          $("#createnewformModal").modal("hide");
          if (state.ui.template.msg || (usersArray && usersArray.length)) {
            formMessage(state.ui.template.msg, usersArray);
            $("#templateupload").modal("hide");
          }
          hideConfirmEditForm();
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.createFormTemplate = false;
        });
    }
    function emailValidationCustomEdit() {
      createNewFormEmailValiation();
      if (
        state.ui.isPostLoading.createFormTemplate ||
        state.item.error.erorListSendForm.length != 0 ||
        state.item.hasError
      )
        return false;
      saveCustomSelectedForm();
    }
    function showSendTemplate() {
      // console.log("Show send Template");
      $("#templateupload").modal("show");
      getChannelFormList();
    }
    function showSendFormModal() {
      // console.log("Show send Template");
      getUserList();
      state.invite.users = [];
      state.ui.template.msg = "";
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;
      $("#sendformModal").modal("show");
    }
    function editCommunicationData(event: any) {
      // console.log("YOYO DATA CHECK >>>>>", event);
      const data = state.communicationList.filter(
        (item: any) => item.id === event.id
      );
      // eslint-disable-next-line @typescript-eslint/camelcase
      data.is_edited = 1;
      const indexId: number = common.findIndex(
        state.communicationList,
        data[0]
      );
      // console.log("YOYO AFTER >>>>>", data[0]);
      // console.log("YOYO ID >>>>>", indexId);
      // state.communicationList[indexId] = event;
      console.log("p10");
      getCommunicationList(channelId);
      // state.communicationList
    }
    function getSharedFiles() {
      state.item.error.erorList = [];
      state.item.hasError = false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
      };
      state.ui.isLoading.getSharedFiles = true;
      ethitransService
        .sharedFiles(payLoad)
        .then((res: any) => {
          res.data.data.attachments.forEach((data: any) => {
            // eslint-disable-next-line @typescript-eslint/camelcase
            data.created_at = common.localDateAndTime(data.created_at);
          });
          res.data.data.replyAttachments.forEach((data: any) => {
            // eslint-disable-next-line @typescript-eslint/camelcase
            data.created_at = common.localDateAndTime(data.created_at);
          });
          state.data.sharedFiles = res.data.data ? res.data.data : {};
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isLoading.getSharedFiles = false;
        });
    }
    function detailsModal() {
      getAccountFormList();
      getSharedFiles();
    }
    function downloadEdocPdf() {
      // console.log("DATA CHECK", state.item.selectedUserFormDetails);
      if (state.item.selectedUserFormDetails.edoc_pdf_path) {
        window.open(state.item.selectedUserFormDetails.edoc_pdf_path);
      } else if (state.item.selectedUserFormDetails.client_doc_pdf_path) {
        window.open(state.item.selectedUserFormDetails.client_doc_pdf_path);
      }
    }
    function clearSelectedSignature(form: any) {
      // Clears the canvas
      form.signaturePad.clear();
    }
    // function reloadPage() {
    //   // The last "domLoading" Time //
    //   // eslint-disable-next-line no-var
    //   var currentDocumentTimestamp = new Date(
    //     performance.timing.domLoading
    //   ).getTime();
    //   // Current Time //
    //   // eslint-disable-next-line no-var
    //   var now = Date.now();
    //   // Ten Seconds //
    //   // eslint-disable-next-line no-var
    //   var tenSec = 10 * 1000;
    //   // Plus Ten Seconds //
    //   // eslint-disable-next-line no-var
    //   var plusTenSec = currentDocumentTimestamp + tenSec;
    //   if (now > plusTenSec) {
    //     location.reload();
    //     // eslint-disable-next-line no-empty
    //   } else {
    //   }
    // }
    function getUserListByChannel() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
      };
      // console.log("PAYLOAD CHECK ", payLoad);
      state.isLoading.getUserListByChannel = true;
      ethitransService
        .channelMemberList(payLoad)
        .then((res: any) => {
          console.log("getUserListByChannel Check:00000>>> ", res.data.data);
          state.userList = res.data.data ? res.data.data : [];
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.isLoading.getUserListByChannel = false;
        });
    }
    function clientDocumentList() {
      const payLoad = {
        type: "ready",
      };
      state.ui.isLoading.clientDocumentList = true;
      ethitransService
        .clientDocumentList(payLoad)
        .then((res: any) => {
          // console.log("clientDocumentList :>>> ", res.data.data);
          if (res.data.data && res.data.data.length) {
            res.data.data.forEach((item: any) => {
              item.convertedFileSize = common.formatBytes(
                parseInt(item.file_size)
              );
            });
            state.data.clientDocuments = res.data.data;
          } else {
            state.data.clientDocuments = [];
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isLoading.clientDocumentList = false;
        });
    }
    // function getUserListByProjectChannel() {
    //   const payLoad = {
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     project_id: state.projectId,
    //     // eslint-disable-next-line @typescript-eslint/camelcase
    //     channel_id: channelId,
    //     // channel_id: state.data.channel.uuid,
    //   };
    //   // console.log("PAYLOAD CHECK ", payLoad);
    //   state.ui.isPostLoading.getUserList = true;
    //   ethitransService
    //     .userList(payLoad)
    //     .then((res: any) => {
    //       // console.log("UserList Response Check:>>> ", res.data.data);
    //       state.data.userList = res.data.data ? res.data.data : [];
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       state.ui.isPostLoading.getUserList = false;
    //     });
    // }
    function sendOptionBack() {
      $("#sendoption").modal("hide");
    }
    function validateSendDigitizedForm() {
      // console.log("Validate Send Digitize Form");
      state.item.error.erorListSendForm = [];
      state.item.hasError = false;

      // console.log("EMAIL CHECK", state.invite.users);

      if (state.invite.users && !state.invite.users.length) {
        state.item.error.erorListSendForm.push("Atleast 1 Email is required.");
      }

      if (
        !state.item.error.erorListSendForm &&
        !state.item.error.erorListSendForm.length
      ) {
        state.item.hasError = false;
      } else if (state.item.error.erorListSendForm.length != 0) {
        state.item.hasError = true;
      }
    }
    function sendDigitizedForm() {
      validateSendDigitizedForm();
      if (
        state.ui.isPostLoading.createFormTemplate ||
        state.item.error.erorListSendForm.length != 0 ||
        state.item.hasError
      )
        return false;
      const usersArray = [] as any;
      state.invite.users.forEach((item: any) => {
        usersArray.push(item);
      });

      const payLoad = {
        title: state.data.clientDocumentRetrieve.name,
        description: state.formData.description,
        // eslint-disable-next-line @typescript-eslint/camelcase
        edoc_id: state.data.clientDocumentRetrieve.edoc_id,
        // message: state.data.additionalMessage,
        elements: state.formData.elements,
        sentToUsers: usersArray,
        privacy: {
          code: state.newFormData.privacy.code,
          name: state.newFormData.privacy.value,
        },
        owner: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_by: {
            // name: state.owner.created_by.full_name,
            name: localStorage.getItem("current_user_details"),
            email: localStorage.getItem("current_user_email"),
            // email: state.owner.created_by.email,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          project_id: state.projectId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          channel_id: channelId,
          project: {
            //added
            id: state.channel.project.id,
            uuid: state.channel.project.uuid,
            name: state.channel.project.name,
            description: state.channel.project.description,
          },
          channel: {
            //added
            id: state.channel.id,
            uuid: state.channel.uuid,
            name: state.channel.name,
            description: state.channel.description,
          },
        },
      };
      console.log("CHECK THE Request", payLoad);
      state.ui.isPostLoading.sendDigitizedForm = true;
      ethitransService
        .accountformSave(payLoad)
        .then((res: any) => {
          console.log("UserList Response Check:>>> ", res.data);
          state.ui.template.response = res.data;
          // if (state.formList.docs && state.formList.docs.length) {
          //   state.formList.docs.unshift(res.data);
          // }
          // $("#mentionuser").modal("hide");
          // // channelCommunicationForm(res.data, state.ui.template.msg)
          // // channelCommunicationForm(state.ui.template.msg)
          if (
            state.data.additionalMessage ||
            (usersArray && usersArray.length)
          ) {
            formMessage(state.data.additionalMessage, usersArray);
            $("#showdigitizedtemplate").modal("hide");
            $("#sendoption").modal("hide");
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.sendDigitizedForm = false;
        });
    }
    function hideDigitizedtemplate() {
      $("#showdigitizedtemplate").modal("hide");
    }
    function hideTemplateupload() {
      $("#templateupload").modal("hide");
    }

    function clientDocumentRetrieve(uuid: any) {
      // console.log("Get Account form Response:>>>", formId);
      const payLoad = {
        id: uuid,
      };
      state.ui.isPostLoading.clientDocumentRetrieve = true;
      ethitransService
        .clientDocumentRetrieve(payLoad)
        .then((res: any) => {
          console.log(
            "<<<clientDocumentRetrieve Check:>>> ",
            res.data.data

            // sendoption
          );
          if (res.data.data) {
            state.data.clientDocumentRetrieve = res.data.data;
          }
          getUserListByChannel();
          $("#sendoption").modal("show");
          state.invite.users = [];
          state.data.additionalMessage = "";
          state.data.privacy = {
            name: "Private to this channel",
            value: "private to this channel",
            code: "channel",
          };
          // getUserListByProjectChannel()
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.ui.isPostLoading.clientDocumentRetrieve = false;
        });
    }
    function selectDigitizeForm(selectedDocument: any) {
      if (!selectedDocument.uuid) return false;
      clientDocumentRetrieve(selectedDocument.uuid);
      // console.log("Selected Doc", selectedDocument)
      // router.push({
      //   name: "admin-channel-create-form",
      //   query: { formId: selectedDocument.uuid, mode: "send", type: 'pdf' },
      // });
    }
    function showDigitizedTemplate() {
      $("#showdigitizedtemplate").modal("show");
      clientDocumentList();
    }
    function resize(e: any) {
      // console.log(e.data);
      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;

      if (!state.message) {
        e.target.style.height = "auto";
      }
      // $("#chat-screen-content").animate(
      // { scrollTop: $("#chat-screen-content")[0].scrollHeight },
      // 1000
      // );
    }
    function resizeFormTextArea(e: any) {
      // console.log("CHECK INPUT",e.data);
      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;

      if (!e.data) {
        e.target.style.height = "auto";
      }
      // $("#chat-screen-content").animate(
      // { scrollTop: $("#chat-screen-content")[0].scrollHeight },
      // 1000
      // );
    }

    function channelRecentlyVisited(channelId: any) {
      state.item.error.erorList = [];
      state.item.hasError = false;
      // console.log("Get Account form Response:>>>", formId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
      };
      state.ui.isPostLoading.channelRecentlyVisited = true;
      ethitransService
        .channelRecentlyVisited(payLoad)
        .then((res: any) => {
          // console.log(
          //   "<<<channelRecentlyVisited Check:>>> ",
          //   res.data.data
          //   // sendoption
          // );
          // if (res.data.data) {
          //   state.data.channelRecentlyVisited = res.data.data;
          // }
          // getUserListByChannel();
          // $("#sendoption").modal("show");
          // state.invite.users = [];
          // state.data.additionalMessage = "";
          // state.data.privacy = {
          //   name: "Private to this channel",
          //   value: "private to this channel",
          //   code: "channel",
          // };
          // // getUserListByProjectChannel()
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.channelRecentlyVisited = false;
        });
    }
    function hideResendInvitationConfirmation() {
      $("#resendinvitationpendingmember").modal("hide");
    }
    function showResendInvitationConfirmation(memberSelected: any) {
      state.data.selectedPendingMember = {};
      state.data.selectedPendingMember = memberSelected;
      state.ui.errors.errorList.resendInvitation = [];
      state.ui.errors.hasError = false;
      $("#resendinvitationpendingmember").modal("show");
    }
    function hidePendingMemberRemove() {
      $("#removependingmember").modal("hide");
    }
    function selectPendingMemberForDeletes(member: any) {
      state.data.selectedPendingMember = {};
      state.data.selectedPendingMember = member;
      state.ui.errors.errorList.resendInvitation = [];
      state.ui.errors.hasError = false;
      $("#removependingmember").modal("show");
    }
    function deletePendingInvitation() {
      const member: any = state.data.selectedPendingMember;
      state.ui.errors.errorList.resendInvitation = [];
      state.ui.errors.hasError = false;
      // console.log("Delete Check >>> ", member)
      if (!member.uuid) return false;
      const payLoad = {
        uuid: member.uuid,
      };
      state.ui.isPostLoading.deletePendingInvitation = true;
      // member.delete = true;
      ethitransService
        .deletePendingInvitation(payLoad)
        .then((res: any) => {
          hidePendingMemberRemove();
          // console.log(
          //   "WOWO projectAccountFormListSearch Response : >>> ",
          //   res.data
          // );
          // state.templateList = res.data.docs ? res.data.docs : [];
          // member.delete = false;
          state.pendingList = state.pendingList.filter((e: any) => e != member);
          state.pendingMembersCount = state.pendingList.length;
          // channelPendingMemberRemapping(state.channelList, state.data.channelIndex, state.pendingList)
          // eslint-disable-next-line @typescript-eslint/camelcase
          // state.channelList[state.data.channelIndex].pending_members = state.pendingList
          // // eslint-disable-next-line @typescript-eslint/camelcase
          // state.channelList[state.data.channelIndex].pending_members_count = state.pendingList.length
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.resendInvitation)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.resendInvitation.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.deletePendingInvitation = false;
          // member.delete = false;
          // state.ui.postLoading.getAccountFormList = false;
        });
    }

    function reloadPage() {
      // The last "domLoading" Time //
      // eslint-disable-next-line no-var
      var currentDocumentTimestamp = new Date(
        performance.timing.domLoading
      ).getTime();
      console.log('aaaa', currentDocumentTimestamp)
      // Current Time //
      // eslint-disable-next-line no-var
      var now = Date.now();
      // Ten Seconds //
      // eslint-disable-next-line no-var
      var tenSec = 10 * 1000;
      // Plus Ten Seconds //
      // eslint-disable-next-line no-var
      var plusTenSec = currentDocumentTimestamp + tenSec;
      if (now > plusTenSec) {
        location.reload();
        // eslint-disable-next-line no-empty
      } else {
      }
    }



    function signDocument(uuid: any) {
      // state.ui.isLoading.clientDocumentList = true;
      // state.ui.isPostLoading.clickToRedirect=true;
      ethitransService
        .envelopeSign(uuid)
        .then((res: any) => {
          // window.location.href = res.data.data.url;
          store.dispatch("openDocusignDocument", res.data.data.url);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isPostLoading.clickToRedirect=false;
        });
    }

    function resendInvitation() {
      const memberSelected: any = state.data.selectedPendingMember;
      state.ui.errors.errorList.resendInvitation = [];
      state.ui.errors.hasError = false;
      // console.log("CHECK THE SELECTED >>> ", memberSelected);

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        invitation_id: memberSelected.uuid,
      };
      // memberSelected.resend = true;
      state.ui.isPostLoading.resendInvitation = true;
      ethitransService
        .invitationResend(payLoad)
        .then((res: any) => {
          console.log("invitationResend:>>> ", res.data.data);
          hideResendInvitationConfirmation();
          // state.roleList = filteredRoles ? filteredRoles : [];
        })
        .catch((error: any) => {
          console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.resendInvitation)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.resendInvitation.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          // memberSelected.resend = false;
          state.ui.isPostLoading.resendInvitation = false;
          // state.isLoading.getProjectRoleList = false;
        });
    }

    watch(
      () => routerQuery.params,
      (newvalue, oldvalue) => {
        if (newvalue.channelId !== oldvalue.channelId) {
          getCommunicationList(channelId);
        }
      }
    );

    watch(
      () => routerQuery.params.channelId,
      (newvalue, oldvalue) => {
        if (newvalue) {
          state.messageStatus = true;
          getCommunicationList(channelId);
          location.reload();
        }
      }
    );


    function getReadyImageListToUpload(data: any) {
      state.imageList = data;
    }

    function getReadyThreadImageListToUpload(data: any) {
      state.threadImageList = data;
    }

    function onEditChatInput(data: any) {
      if (!data) return false;
      // data = common.replaceURLs(data);
      console.log("Check >>> ", data);
      document.addEventListener("keydown", function (event: any) {
        if (event.keyCode === 13 && !event.shiftKey) {
          if (
            state.communicationList[
              state.data.selectedChatIndex
            ].message.endsWith("<p>&nbsp;</p>")
          ) {
            state.communicationList[state.data.selectedChatIndex].message =
              state.communicationList[
                state.data.selectedChatIndex
              ].message.replace(new RegExp("<p>&nbsp;</p>", "g"), "");
            console.log("TEST for Enter  After", data);
          }

          state.data.typeEditDisabled = true;
          sendEditedCommunication(
            state.communicationList[state.data.selectedChatIndex]
          );
        }
      });
    }
    function onThreadReplyInput(data: any) {
      if (!data) return false;
      // data = common.replaceURLs(data);
      console.log("Check >>> ", data);
      document.addEventListener("keyup", function (event: any) {
        if (event.keyCode === 13 && !event.shiftKey) {
          if (state.data.replyMessage.endsWith("<p>&nbsp;</p>")) {
            state.data.replyMessage = state.data.replyMessage.replace(
              new RegExp("<p>&nbsp;</p>", "g"),
              ""
            );
            console.log("Reply for Enter  After", data);
          }

          state.data.typeEditDisabled = true;
          replyMessage();
        }
      });
    }

    // window.addEventListener('scroll',()=>{
    // // console.log(window.scrollY) //scrolled from top
    // // console.log(window.innerHeight) //visible part of screen
    // if(window.scrollY + window.innerHeight >=
    // document.documentElement.scrollHeight){
    // // state.data.page = state.data.page + 1
    // getCommunicationList(channelId);
    // }

    // })
    // window.addEventListener('scroll',()=>{

    //     const myScrollFunc = function () {
    //         const y = window.scrollY;
    //         if (y >= 100) {
    //            console.log("SHOW");
    //         } else {
    //           console.log("HIDE");
    //         }
    //     };

    //     window.addEventListener("scroll", myScrollFunc);
    // });

    function scrollCheck() {
      // const myID: any = document.getElementById("chat-body-content");
      //   const scrollTop = myID.scrollTop;
      //   if(scrollTop + myID.innerHeight >= myID.scrollHeight){
      //   }
      // const element: any = scrollCompoment.value
      // if(element.getBoundingClientRect().bottom < window.innerHeight){
      //   getCommunicationList(channelId);
      // }
      // const objDiv: any = window.document.getElementById("chat-body-content");
      //   objDiv.addEventListener('scroll', function (e: any) {
      //     console.log("CHECK THE >>> ", objDiv.scrollTop)
      //     if(objDiv.scrollTop === objDiv.scrollHeight){
      //     }
      //   })
    }

    watch(
      // () => watchTour,
      () => store.state.takAtour,
      (newvalue, oldvalue) => {
        if (newvalue === true) {
          // console.log("check >>>", newvalue)
          toggleTourGuide();
        }
      }
    );

    function handleThreadScroll(event: any) {
      state.scrollThreadActive = true;
      if (scrollTimeoutThread.value) clearTimeout(scrollTimeoutThread.value);

      // Set a new timeout to detect when scrolling stops
      scrollTimeoutThread.value = setTimeout(() => {
        // Triggered when scroll has stopped

        const scrolledIds: any = getVisibleDivIdForReplyThread();
        // const matchIds: any = getIdsByEmailMismatch(state.data.selectedReplyUser.responses)
        // const finalIds: any = scrolledIds.filter((element: any) => matchIds.includes(element));
        // console.log("Scrolled Ids >>> ", scrolledIds)
        // if (scrolledIds && scrolledIds.length === 0) return false;
        // console.log("Thread IDS >>> ", scrolledIds)
        // channelMessageReplySeenByMember(scrolledIds);

        // new code test
        if (scrolledIds.length > 0) {
          const ids = state.data.selectedReplyUser.responses.map(function (item: any) {
            return item.id;
          });

          const uniqueIds = ids.filter(function (id: any, index: any, self: any) {
            return self.indexOf(id) === index;
          });

          // check the ids is of last page or not
          if (hasCommonNumber(scrolledIds, uniqueIds)) {
            console.log("TRUE HERE")
          } else {
            console.log("False HERE")
          }

          channelMessageReplySeenByMember(scrolledIds);
        } else {
          console.log("No valid msg ids found")
        }
        // console.log("CHECK FOR Thread IDS >>> ", getVisibleDivIdForReplyThread())

        // getVisibleDivIdForReplyThread();
      }, 200); // Adjust delay as needed (200ms is often a good balance)
    }



    function handleScroll(event: any) {
      state.scrollActive = true;
      // Clear any existing timeout
      if (scrollTimeout.value) clearTimeout(scrollTimeout.value);

      // Set a new timeout to detect when scrolling stops
      scrollTimeout.value = setTimeout(() => {
        // Triggered when scroll has stopped

        onScrollStopped();
      }, 200); // Adjust delay as needed (200ms is often a good balance)
      if (state.messageStatus) {
        return;
      }


      if (routerQuery.query.page) {

        const pageNum: any = routerQuery.query.page;
        state.data.page = parseInt(pageNum);
        // payLoad.page = parseInt(pageNum);
      }

      if (event.target.scrollHeight === (event.target.clientHeight + event.target.scrollTop) && !state.data.chatScroll.scrollToTop) {
        if (state.ui.isPostLoading.loadLatestMessages) return false;

        // console.log("<<< CHECK THE LOAD ON Down >>>")
        // AT BOTTOM HIT API
        // state.data.chatScroll.scrollToBottom = true;
        if (state.data.currentPage < state.data.lastPage) {

          state.data.page++;
          if (state.data.search) {
            searchByInChatMessage(channelId, state.data.page)
          } else {

            loadLatestMessages("");
          }
          // Calculate the maximum scroll height
          // const maxScroll = event.target.scrollHeight - event.target.clientHeight;

          // // Set the scroll position to slightly below the top
          // const scrollBelowTop = 25; // Adjust this value as needed
          // event.target.scrollTop = maxScroll - (maxScroll - scrollBelowTop);


        }
      } else if (event.target.scrollTop === 0 && !state.data.chatScroll.scrollToBottom) {
        if (state.ui.isPostLoading.loadLatestMessages) return false;
        if (state.data.search.length > 0 && (state.data.page == 1)) {
          return false;
        }
        // console.log("<<< CHECK THE LOAD ON UP >>>")


        //   const objDiv: any = document.getElementById("chat-body-content");
        // // objDiv.scrollTop = objDiv.scrollHeight - 3;
        // if(state.ui.isPostLoading.loadInjectChatMessages){
        //   //  reduce the page by 2
        //   console.log("CHECK THE REDUCE")
        //   state.data.page--
        //   state.data.page--
        //   loadLatestMessages("");
        // }
        // AT TOP HIT API
        state.data.chatScroll.scrollToTop = true;
        if (state.data.loadedPage > 0) {
          state.data.page = state.data.loadedPage;
        }
        // if (state.data.page < state.data.lastPage) {
        // console.log("Page check Before >>>", state.data.page)
        if (state.data.page > 1) {
          state.data.page--;
          // console.log("Page check After>>>", state.data.page);
          // Load the latest messages
          if (state.data.search) {
            searchByInChatMessage(channelId, state.data.page)
          } else {

            loadLatestMessages("");
          }

          state.data.loadedPage = 0;

          // Calculate the maximum scroll height
          // const chatBodyContent: any = document.getElementById('chat-body-content');
          // console.log("SCROLL HEIGHT >>> ", chatBodyContent.scrollHeight);
          // console.log("Client HEIGHT >>> ", chatBodyContent.clientHeight)
          // state.data.latestScrollHeight = chatBodyContent.scrollHeight;

          // // Set the scroll position to a specific number, e.g., 100 pixels
          // const { scrollTop, scrollHeight, clientHeight } = chatBodyContent;
          // const scrollBottom = scrollTop + clientHeight;
          // // chatBodyContent.scrollTop = event.target.scrollHeight - event.target.clientHeight;
          // chatBodyContent.scrollTop = scrollBottom;

          //const maxScroll = event.target.scrollHeight - event.target.clientHeight;

          // Set the scroll position to slightly below the top
          // const scrollBelowTop = 300; // Adjust this value as needed
          // event.target.scrollTop = maxScroll - (maxScroll - scrollBelowTop);


          // console.log("Scrollbar positioned slightly below the top", scrollTop);
        }


        // state.data.page++;
        // console.log("Page check AFTER >>>", state.data.page)

        // loadLatestMessages("");
        // }
      }
      // return false;


      // old scroll up and inject message code
      // if (
      //   event.target.clientHeight + event.target.scrollTop >=
      //   event.target.scrollHeight && !routerQuery.query.from
      // ) {

      //   state.data.scrollBottom = true;
      //   state.data.isCommunicationFile = "";
      //   if (routerQuery.query.page && routerQuery.query.communicationId) {

      //     router.push({
      //       name: "admin-channel",
      //       params: { channelId: channelId },
      //     });
      //   }
      //   if (state.data.page > 1) {
      //     //  console.log("<<<< button state before :>>>>",state.data.page);
      //     // //  state.data.page--;
      //     //  console.log("<<<< button state after :>>>>",state.data.page);
      //     //  console.log("<<<< button last page number >>>>>", state.data.lastPage);

      //     if (state.data.page <= state.data.lastPage) {
      //       state.data.page--;
      //       if (state.data.page != 0) {
      //         if (state.data.scrolledPages.includes(state.data.page))
      //           return false;
      //         getCommunicationList(channelId);
      //         //  state.isReplyMessage=false;
      //       }
      //     }
      //   }
      //   // console.log("BOTTOM SCROLL");
      //   // state.data.scrollTop = false;
      // } else {
      //   if (routerQuery.query.page && routerQuery.query.communicationId) {
      //     router.push({
      //       name: "admin-channel",
      //       params: { channelId: channelId },
      //     });
      //   }
      //   if (event.target.scrollTop === 0) {
      //     state.data.isCommunicationFile = "";

      //     // state.data.scrollTop = false;
      //     // console.log("<<<< CHECK SCROLL >>>>>", window.scrollY);
      //     state.data.scrollTop = true;
      //     state.firstScroll = true;
      //     console.log("<<<< Page BEFORE number :>>>>", state.data.page);
      //     console.log("<<<< last page CHeck >>>>>", state.data.lastPage);
      //     if (state.data.page < state.data.lastPage) {
      //       state.data.page++;
      //       console.log(state.data.scrolledPages.includes(state.data.page));
      //       console.log("state.data.scrolledPages", state.data.scrolledPages);
      //       console.log("state.data.page", state.data.page);
      //       if (state.data.scrolledPages.includes(state.data.page))
      //         return false;
      //       // state.data.page++;
      //       console.log("<<<< After check number :>>>>", state.data.page);
      //       console.log("p6");
      //       // if(state.data.scrolledPages.includes(state.data.page)) return false;
      //       getCommunicationList(channelId);
      //       // state.isReplyMessage=false;
      //     }
      //     //  console.log("<<<< PAGE after  >>>>>", state.data.page);

      //     //  resetheight()
      //     // scrollToLast()
      //   }
      //   // if(state.data.scrolledPages.includes(state.data.page)){
      //   // }
      // }
      // state.data.scrolledPages.push(state.data.page);
      // state.data.scrolledPages = state.data.scrolledPages.filter(
      //   (x: any, i: any, a: any) => a.indexOf(x) == i
      // );
      // if (pageQuery.value != 0) {
      //   console.log("TEST ON Value not");
      //   const pageNum: any = routerQuery.query.page;
      //   const currentPage = parseInt(pageNum);
      //   state.data.scrolledPages = state.data.scrolledPages.filter(
      //     (e: any) => e != currentPage
      //   );
      // }
    }

    function goToChatChannel(data: any) {
      alert("CHECK")
      state.data.goToAttachmentRow = true;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        communication_id: data.communication_id ? data.communication_id : "",
      };
      // state.postLoading = true;
      ethitransService
        .communicationPage(payLoad)
        .then((res: any) => {
          state.data.pageNumber = res.data.data;
        })
        .catch((error: any) => {
          // console.log(error);
        })
        .finally(() => {
          // createCommunicationList();
          // state.postLoading = false;
          if (data.response_type) {
            // router.push({
            //   name: "admin-channel",
            //   params: { channelId: channelId },
            //   // eslint-disable-next-line @typescript-eslint/camelcase
            //   query: {
            //     communicationId: data.communication_id,
            //     page: state.data.pageNumber,
            //     reply: data.id,
            //     from: "file"
            //   },
            // });
            state.data.page = state.data.pageNumber;
            state.data.isCommunicationFile = data.communication_id;
            loadLatestMessages("");

            setTimeout(() => {
              scrollToLiById(data.communication_id)
            }, 3000);
          } else {

            // router.push({
            //   name: "admin-channel",
            //   params: { channelId: channelId },
            //   // eslint-disable-next-line @typescript-eslint/camelcase
            //   query: {
            //     communicationId: data.communication_id,
            //     page: state.data.pageNumber,
            //     from: "file"
            //   },
            // });
            state.data.page = state.data.pageNumber;
            state.data.isCommunicationFile = data.communication_id;
            loadLatestMessages("");
            setTimeout(() => {
              scrollToLiById(data.communication_id)
            }, 3000);
          }
        });
      $(".chat-screen").removeClass("toggled");
    }
    // window.document.getElementById("chat-body-content").addEventListener("scroll", scrollCheck);

    function previous() {
      if (state.currentIndex > 0) {
        state.currentIndex--;
        state.isPreV = true;
      }
      if (state.currentIndex == 0) {
        state.isPreV = false;
      }
      if (state.currentIndex < state.slides.length - 1) {
        state.isNext = true;
      } else {
        state.isNext = false;
      }
    }
    function next() {
      if (state.currentIndex < state.slides.length - 1) {
        state.currentIndex++;
      }
      if (state.currentIndex == state.slides.length - 1) {
        state.isNext = false;
      }
      if (state.currentIndex > 0) {
        state.isPreV = true;
      } else {
        state.isPreV = false;
      }
    }


    function validationPublicUser() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (!state.inviteAsPublic && !state.inviteAsPublic.length) {
        state.errorList.push("At least 1 user is required.");
      } else {
        state.inviteAsPublic.forEach((item: any) => {
          if (item.email && !validationService.isValidEmail(item.email)) {
            state.errorList.push("Invalid email.");
          }
          if (!item.email) {
            state.errorList.push("Email must be required.");
          }
          //  if (!item.name) {
          //   state.errorList.push("Name must be required.");
          // }
          if (item.smsNotification && !item.phone) {
            state.errorList.push("Mobile number must be required.");
          }
          //  if(!item.smsNotification && !item.emailNotification) {
          //   state.errorList.push("Email or SMS one method must be required.");
          // }
        });
      }
      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }

      // console.log("CHECK BOOLEAN", state.isErrorStatus);
    }

    function inviteAllPublicUser() {
      validationPublicUser();
      if (state.errorList.length != 0 || state.isErrorStatus) return false;
      const inviteChannels = [];
      inviteChannels.push(channelId);
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.channel.project.uuid,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channels: inviteChannels,
        users: common.removeDuplicate(state.invite.filteredUsers),
        invites: state.inviteAsPublic,
      };
      // if(state.invite.filteredUsers.length === 0 || state.invite.filteredInvites.length === 0) return false;
      console.log("CHECK PAYLOAD ON API CALL", payLoad);
      state.isLoading.inviteAllUsers = true;
      ethitransService
        .invitationPublicUser(payLoad)
        .then((res: any) => {
          hideAddMember();
          showSuccessModal();
          state.data.invitationVerifyMsg = "";
          state.data.invitationVerify.newMemberList = [];
          state.data.invitationVerify.registeredMemberList = [];
          state.data.invitationVerify.pendingMemberList = [];
          state.invitationMessage = res.data.message ? res.data.message : "";
          getCommunicationList(channelId);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          state.isLoading.inviteAllUsers = false;

          state.data.inviteAsPublicUser = false;
        });
    }
    function deletePublicMember(item: any) {
      state.inviteAsPublic = state.inviteAsPublic.filter((e: any) => e != item);
    }

    function changeInvitePublicMember() {
      validationPublicUser();
      if (state.errorList.length != 0 || state.isErrorStatus) return false;
      state.inviteAsPublic.push({
        name: "",
        email: "",
        phone: "",
        smsNotification: false,
        mailNotification: true,
      });

    }
    function toggleZoom() {
      state.isZoomed = !state.isZoomed

    }
    function clickImage(communication: any, imageIndex: any) {
      imgsRef.value = [];
      state.allSliderImage = [];
      communication.forEach((file: any) => {
        state.allSliderImage.push(file.file_link);

        // if (file.file_type == 'png' || file.file_type == 'jpg' || file.file_type == 'jpeg') {
        //   state.allSliderImage.push(file.file_link);
        // }
      });
      imgsRef.value = state.allSliderImage;
      setTimeout(() => {
        indexRef.value = imageIndex;
        visibleRef.value = true;
      }, 20);

    }
    
 
  function clickChatImage(image: any, index: any) {

  const ext = (image.fileName || image.name).split('.').pop()?.toLowerCase();

  // Check if the file is a PDF
  // if (ext === 'pdf') {
  //   imgsRef.value = []; 
  //   const pdfUrl = image.base64 || image.data; 
  //   console.log("The pdf url is>>", pdfUrl)
  //   indexRef.value = index;
  //     visiblePdf.value = true;
  //     imgsRef.value = [pdfUrl];
  //   $("#pdfview").modal("show");
  //   // setTimeout(() => {
  //   //   indexRef.value = index;
  //   //   visiblePdf.value = true;
  //   //   imgsRef.value = [pdfUrl]; 
  //   // }, );
  // } 
  if (ext === 'pdf') {
  imgsRef.value = [];

  let blobUrl = '';

  if (image.base64) {
    // Convert base64 string to Blob URL
    const base64Data = image.base64.split(',')[1]; // remove "data:application/pdf;base64,"
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    blobUrl = URL.createObjectURL(blob);
  } else if (image.data && image.data.startsWith('blob:')) {
    // Already a blob URL
    blobUrl = image.data;
  } else {
    // Fallback for URLs
    blobUrl = image.data || image.url || '';
  }

  console.log("The pdf blob url is >>", blobUrl);

  indexRef.value = index;
  visiblePdf.value = true;
  imgsRef.value = [blobUrl];

  // Show Bootstrap modal
  $("#pdfview").modal("show");
}
  else if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(ext)) {
    imgsRef.value = []; 
    const imageUrl = image.base64 || image.data; 
    setTimeout(() => {
      indexRef.value = index;
      visibleRef.value = true;
      imgsRef.value = [imageUrl]; 
    }, 20);
  } else {
    console.log("Unsupported file type.");
  }
}

    function isImgUrl(url: any) {
      return /\.(jpg|jpeg|png|webp|avif|gif)$/.test(url)
    }




    function imageSliderNext(index: any, allSliderImage: any) {
      return new Promise((resolve, reject) => {
        const newIndex = index + 1;
        if (isImgUrl(state.allSliderImage[newIndex])) {
          return resolve(newIndex);
        } else {
          if (state.allSliderImage[newIndex + 1]) {
            imageSliderNext(newIndex + 1, state.allSliderImage);
          } else {
            return resolve(-1);
          }

        }
      });

    }


    function imageSliderPre(index: any, allSliderImage: any) {
      return new Promise((resolve, reject) => {
        const newIndex = index - 1;
        if (isImgUrl(state.allSliderImage[newIndex])) {
          return resolve(newIndex);
        } else {
          if (state.allSliderImage[newIndex - 1]) {
            imageSliderPre(newIndex - 1, state.allSliderImage);
          } else {
            return resolve(-1);
          }
        }
      });

    }



    function handlePrev(oldIndex: any, newIndex: any) {
      if (isImgUrl(state.allSliderImage[newIndex])) {
        indexRef.value = newIndex;
        visibleRef.value = true;
      } else {
        const tempIndex = newIndex - 1;
        if (state.allSliderImage[tempIndex]) {
          if (isImgUrl(state.allSliderImage[tempIndex])) {
            indexRef.value = tempIndex;
            visibleRef.value = true;
          } else {
            imageSliderPre(tempIndex, state.allSliderImage).then((index: any) => {
              if (index != -1) {
                indexRef.value = index;
                visibleRef.value = true;
              } else {
                visibleRef.value = false;
              }
            });
          }
        } else {
          visibleRef.value = false;
        }

      }
    }



    function handleNext(oldIndex: any, newIndex: any) {

      if (isImgUrl(state.allSliderImage[newIndex])) {
        indexRef.value = newIndex;
        visibleRef.value = true;
      } else {
        const tempIndex = newIndex + 1;
        if (state.allSliderImage[tempIndex]) {
          if (isImgUrl(state.allSliderImage[tempIndex])) {
            indexRef.value = newIndex + 1;
            visibleRef.value = true;
          } else {
            imageSliderNext(tempIndex, state.allSliderImage).then((index: any) => {
              if (index != -1) {
                indexRef.value = index;
                visibleRef.value = true;
              } else {
                visibleRef.value = false;
              }
            });
          }
        } else {
          visibleRef.value = false;

        }

      }
    }


    function sendMessageFromEnvelope(envlopeId: any) {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        envelope_id: envlopeId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: channelId,
        event: "send"
      };
      // state.postLoading = true;
      ethitransService
        .sendMessageFromEnvelope(payLoad)
        .then((res: any) => {
          // state.data.pageNumber = res.data.data;
        })
        .catch((error: any) => {
          // console.log(error);
        })
        .finally(() => {
          router.push({
            name: "admin-channel",
            params: { channelId: channelId },
          });
        });
    }

    function sendMessageSignFromEnvelope(envlopeId: any) {
      const payLoad = {
        id: envlopeId,
        event: "signing_complete"
      };
      // state.postLoading = true;
      ethitransService
        .sendMessageSignFromEnvelope(payLoad)
        .then((res: any) => {
          // state.data.pageNumber = res.data.data;
        })
        .catch((error: any) => {
          // console.log(error);
        })
        .finally(() => {
          router.push({
            name: "admin-channel",
            params: { channelId: channelId },
          });
        });
    }


    function handleHide() {
      visibleRef.value = false;

    }

    function docusignShareDocumentDetails(uuid: any) {
      ethitransService
        .docusignShareDocumentDetails(uuid)
        .then((res: any) => {
          state.data.docusignShareDocumentDetail = res.data.data;
          state.data.docusignShareDocumentData = res.data.data.recipients.find((e: any) => e.email == state.email);

        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.isPostLoading.uploadClientDocument = false;
        });
    }

    function showDocusignListModel() {
      state.data.showDocusignDocumentListModel = !state.data.showDocusignDocumentListModel;
      $("#showDocusignListModel").modal("show");
    }
    function closeDocusignListModel() {
      state.data.showDocusignDocumentListModel = !state.data.showDocusignDocumentListModel;
      $("#showDocusignListModel").modal("hide");
    }


    function truncate(data: any, limit: any) {
      if (data.length > limit) {
        return data.substring(0, limit) + "...";
      }
      return data;
    }

    function showDocusignShareDocumentModel(uuid: any, communication: any) {
      // state.data.docusignShareDocumentData = communication;
      docusignShareDocumentDetails(uuid);
      state.data.showShareDocusignDocumentModel = !state.data.showShareDocusignDocumentModel;
      $("#showDocusignShareDocument").modal("show");
    }
    function closeDocusignShareDocumentModel() {
      state.data.showShareDocusignDocumentModel = !state.data.showShareDocusignDocumentModel;
      state.data.docusignShareDocumentDetail = {};
      state.data.docusignShareDocumentData = {};
      $("#showDocusignShareDocument").modal("hide");
    }

    function getSendMessage(payLoad: any) {
      ethitransService
        .createCommunication(payLoad)
        .then((res: any) => {
          clearInterval(state.data.intervalId);
          removeUnsentMessage(res.data.data.messageId);
          resetMessageStatusIncrementally(state.communicationList, res.data.data.messageId);
          // messageSendStatus
          // state.communicationList


          const textarea: any = document.getElementById("sendMsg");

          textarea.setAttribute("style", "");
          textarea.value = "";
          // state.data.typeDisabled = false;
          // $('#chat-body')[0].scrollTop = $('#chat-body')[0].scrollHeight;
        })
        .catch((error: any) => {
          // console.log(error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {

        });
    }
    
    function getPendingMessage() {
      sendPendingMessage();
      if (state.ui.isPostLoading.getCommunicationList) {
        return false;
      }
      const payLoad = {
        'channel_id': channelId,
        "search": "",
        "user": localStorage.getItem("current_user_role"),
        'lastMessageId': state.data.latestMessageId
      };
      ethitransService
        // .createCommunicationList(payLoad)
        .getPendingMessages(payLoad)
        .then(async (res: any) => {
          const messages = res.data.data.messages;
          messages.forEach((element: any) => {
            addMessage(element);
          });
        })
        .catch((error: any) => {
          state.data.scrollTop = false;
          // console.log("message", error);
          common
            .sdCatchErr(error, state.item.error.erorList)
            .then((res: any) => {
              state.item.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.item.error.erorList.push(value[0]);
                }
              }
            });
        });


    }



    watch(
      () => state.data.inviteAsPublicUser,
      () => {
        if (!state.data.inviteAsPublicUser) return false;
        // console.log("Guest Invitation Selected");
        state.existingMember = "";
        state.invite.users = [];
        state.inviteAsPublic = [];
        state.invite.invites = [];
        state.inviteAsPublic.push({
          name: "",
          email: "",
          phone: "",
          smsNotification: false,
          mailNotification: true,
        });
      }
    );

    const docuSingSignData = computed(() => store.state.saveSignedData ? store.state.saveSignedData : {});
    watch(
      () => store.state.language,
      (newvalue, oldvalue) => {
        // getCommunicationList(channelId);
        // this.$forceUpdate();
        location.reload();
      }
    );
    watch(
      () => store.state.socketStatus,
      (newvalue, oldvalue) => {
        if (newvalue == 1) {
          setSocket();
          getPendingMessage();
        } else {
          //alert('Socket disconnect');
        }
      }
    );
    watch(
      () => store.state.saveSignedData,
      (newvalue, oldvalue) => {
        if (newvalue) {
          state.ui.isLoading.docuSignIframe = true;
          state.data.docuSignSignUrl = store.state.saveSignedData;
        } else {
          state.ui.isLoading.docuSignIframe = false;
        }
      }
    );

    watch(
      () => store.state.openSignDocument,
      (newvalue, oldvalue) => {
        if (newvalue) {
          state.ui.isLoading.docuSignIframeOpen = true;
          state.data.docuSignSignOpenUrl = store.state.openSignDocument;
        } else {
          state.ui.isLoading.docuSignIframeOpen = false;
        }
      }
    );
    function hideUpdateSubscriptionModal() {
      state.ui.isActive.upgradeSubscription = !state.ui.isActive.upgradeSubscription;
    }

    function createPrivateChannelValidation() {
      state.errorList = [];
      state.isErrorStatus = false;

      if (!state.invite.filteredUsers.length) {
        state.errorList.push("Invite member is required.");
      }
      if (state.inviteMember) {
        if (!validationService.isValidEmail(state.inviteMember)) {
          state.errorList.push("Invalid email.");
        }
      }

      if (state.invite.invites.some((item: any) => item.first_name == '' || item.last_name == '')) {
        state.invite.invites.forEach((invite) => {
          if (invite.first_name == '') {
            invite.fNameError = true;
          } else {
            invite.fNameError = false;
          }
          if (invite.last_name == '') {
            invite.lNameError = true;
          } else {
            invite.lNameError = false;
          }
        })
        state.errorList.push("First Name Last name is requred all invitation.");
      }

      if (!state.errorList && !state.errorList.length) {
        state.isErrorStatus = false;
      } else if (state.errorList.length != 0) {
        state.isErrorStatus = true;
      }

      // console.log("CHECK BOOLEAN", state.isErrorStatus);
    }

    function createPrivateChannel() {
      state.errorList = []
      allUsers.value = [];
      state.invite.users = [];
      state.invite.invites = [];
      state.userList.forEach((memb: any) => {
        if (memb.user.email != localStorage.getItem("current_user_email")) {
          allUsers.value.push({
            'id': memb.user.uuid,
            'label': memb.user.email,
            'email': memb.user.email,
            'full_name': memb.user.full_name,
          })
        }

      })
      $("#createPrivateChannel").modal("show");
    }

    function addMemberInPrivateChannel(data: any) {
      state.errorList = []
      allUsers.value = [];
      state.invite.users = [];
      state.invite.invites = [];
      // state.userList.forEach((memb: any) => {
      //   if (memb.user.email != localStorage.getItem("current_user_email")) {
      //     allUsers.value.push({
      //       'id': memb.user.uuid,
      //       'label': memb.user.email,
      //       'email': memb.user.email,
      //       'full_name': memb.user.full_name,
      //     })
      //   }

      // })
      getUserList();
      $("#createPrivateChannel").modal("show");
    }

    function hidePrivateChannel() {
      allUsers.value = [];
      state.invite.users = [];
      state.invite.invites = [];
      state.errorList = []
      $("#createPrivateChannel").modal("hide");
    }

    function createPrivateChannelGroup() {
      state.invite.filteredUsers = [];

      state.invite.users.forEach((item: any) => {
        // console.log("CHECK email", item);
        state.invite.filteredUsers.push(item);
      });
      state.invite.invites.forEach((item: any) => {
        // console.log("CHECK email", item);
        state.invite.filteredUsers.push(item);
      });
      createPrivateChannelValidation();
      if (
        state.errorList.length != 0 ||
        state.isErrorStatus ||
        state.isLoading.inviteAllUsers
      )
        return false;

      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        project_id: state.projectId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.channel.uuid,
        users: common.removeDuplicate(state.invite.filteredUsers),
      };
      // if(state.invite.filteredUsers.length === 0 || state.invite.filteredInvites.length === 0) return false;
      console.log("CHECK PAYLOAD ON API CALL", payLoad);
      state.isLoading.inviteAllUsers = true;
      ethitransService
        .createPrivateChannelGroup(payLoad)
        .then((res: any) => {
          hidePrivateChannel();
          state.data.successFulMessage = "Private Channel Create Successful";
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;

        })
        .catch((error: any) => {
          // console.log(error);

          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            if (res) {
              if (res.name) {
                state.errorList.push(res.name[0]);
              } else if (res.description) {
                state.errorList.push(res.description[0]);
              } else {
                console.log("CHECK for Error >>>", res);
                state.errorList = res.error;
              }
            }
          });
        })
        .finally(() => {
          state.isLoading.inviteAllUsers = false;
          state.invite.filteredUsers = [];
        });
    }

    function closeSuccessCustomModal() {
      state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
      $("#SuccessfulModalCustom").modal("hide");
    }
    function exportCommunicationPdfList() {

      ethitransService
        .exportCommunicationPdfList(state.channel.uuid)
        .then((res: any) => {
          console.log('res', res);
          state.data.exportPdfs = res.data.data;
        })
        .catch((error: any) => {
          // console.log(error);

          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            if (res) {
              if (res.name) {
                state.errorList.push(res.name[0]);
              } else if (res.description) {
                state.errorList.push(res.description[0]);
              } else {
                console.log("CHECK for Error >>>", res);
                state.errorList = res.error;
              }
            }
          });
        })
        .finally(() => {
          // state.invite.filteredUsers = [];
        });
    }

    function exportCommunicationPdf() {
      state.data.isExport = true;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        channel_id: state.channel.uuid,
        user: localStorage.getItem("current_user_role"),
      };
      ethitransService
        .exportCommunicationPdf(payLoad)
        .then((res: any) => {
          state.data.successFulMessage = "You will be notified once the PDF is generated and ready to use.";
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;

        })
        .catch((error: any) => {
          // console.log(error);

          common.sdCatchErr(error, state.errorList).then((res: any) => {
            state.isErrorStatus = true;
            if (res) {
              if (res.name) {
                state.errorList.push(res.name[0]);
              } else if (res.description) {
                state.errorList.push(res.description[0]);
              } else {
                console.log("CHECK for Error >>>", res);
                state.errorList = res.error;
              }
            }
          });
        })
        .finally(() => {
          exportCommunicationPdfList();
          state.data.isExport = false;
        });
    }


    function stopRecording() {
      let timeoutId: number | undefined; // Define the type explicitly
      recognition.stop();
      clearTimeout(timeoutId); // Clear the timeout
      isRecording.value = false;
    }

    function startRecording() {
      isRecording.value = true;
      recognition = new window.webkitSpeechRecognition();
      recognition.lang = 'en-US';
      recognition.continuous = true;
      recognition.interimResults = false;
      recognition.start();

      let timeoutId: number | undefined; // Define the type explicitly

      recognition.onresult = (event: any) => {
        const transcript = event.results[event.results.length - 1][0].transcript;
        transcription.value = transcript;
        state.message = state.message + transcript;

        // Reset the timeout whenever there's a new result
        clearTimeout(timeoutId);
        timeoutId = setTimeout(stopRecording, 30000); // 5 seconds timeout
      };

      recognition.onend = () => {
        isRecording.value = false;
      };

      // Set a timeout to stop recording after 30 seconds of inactivity
      timeoutId = setTimeout(stopRecording, 30000); // 30 seconds timeout
    }


    function stopReplyRecording() {
      let timeoutId: number | undefined; // Define the type explicitly
      recognition.stop();
      clearTimeout(timeoutId); // Clear the timeout
      isReplyRecording.value = false;
    }

    function startReplyRecording() {
      isReplyRecording.value = true;
      recognition = new window.webkitSpeechRecognition();
      recognition.lang = 'en-US';
      recognition.continuous = true;
      recognition.interimResults = false;
      recognition.start();

      let timeoutId: number | undefined; // Define the type explicitly

      recognition.onresult = (event: any) => {
        const transcript = event.results[event.results.length - 1][0].transcript;
        transcription.value = transcript;
        state.data.replyMessage = state.data.replyMessage + transcript;

        // Reset the timeout whenever there's a new result
        clearTimeout(timeoutId);
        timeoutId = setTimeout(stopRecording, 30000); // 5 seconds timeout
      };

      recognition.onend = () => {
        isReplyRecording.value = false;
      };

      // Set a timeout to stop recording after 30 seconds of inactivity
      timeoutId = setTimeout(stopRecording, 30000); // 30 seconds timeout
    }
    function modifyUserConversationHistoryDate(member: any) {
      state.data.selectedPendingMember = {};
      state.data.selectedPendingMember = member;
      $("#modifyChannelHistoryBymember").modal("show");
    }
    function hideHistoryMember() {
      $("#modifyChannelHistoryBymember").modal("hide");
    }
    function modifyHistoryDateForMember() {
      state.ui.errors.errorList.modifyHistoryDateForMember = [];
      state.ui.errors.hasError = false;
      if (state.ui.isPostLoading.modifyHistoryDateForMember) return false;
      let payLoad: any = {};
      payLoad = {

        id: state.data.selectedPendingMember.channel.uuid,
        email: state.data.selectedPendingMember.email,
        // eslint-disable-next-line @typescript-eslint/camelcase
        contact_number: state.data.selectedPendingMember.contact_number,
        // eslint-disable-next-line @typescript-eslint/camelcase
        message_share: {
          type: selectedValueForPending.value,
        }
      };
      if (selectedValueForPending.value === "4") {
        payLoad.message_share.date = selectedDateForMember.value;
      }
      console.log("PAYLOAD CHECK >>> ", payLoad)
      state.ui.isPostLoading.modifyHistoryDateForMember = true;
      ethitransService
        .convHistoryForUser(payLoad)
        .then((res: any) => {
          state.data.successFulMessage = "Chat History Date Modified Successful";
          state.data.showSuccesCustomModal = !state.data.showSuccesCustomModal;
          // state.ui.successMessage = 'Project details updated successfully!';
          state.ui.isPostLoading.modifyHistoryDateForMember = false;
          hideHistoryMember()
        })
        .catch((error: any) => {
          // state.ui.isPostLoading.modifyHistoryDateForMember = false;
          // console.log(error);
          common
            .sdCatchErr(error, state.ui.errors.errorList.modifyHistoryDateForMember)
            .then((res: any) => {
              state.ui.errors.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.errors.errorList.modifyHistoryDateForMember.push(value[0]);
                }
              }
            });
        })

      // .finally(() => {
      //   state.ui.isPostLoading.modifyHistoryDateForMember = false;
      // });
    }


    const checkScrollPosition = () => {
      if (chatBody.value) {
        console.log("Current scroll position:", chatBody.value.scrollTop);
      }
    };


    //     function getCommonElements(arr1, arr2) {
    //     return arr1.filter(value => arr2.includes(value));
    // }
    onMounted(() => {
      document.querySelectorAll('.tooltip').forEach(element => element.remove());
      const bodyClass = 'no-scroll-body';
      // Check if the <body> element already has the class
      if (!document.body.classList.contains(bodyClass)) {
        // Add the class to the <body> element
        document.body.classList.add(bodyClass);
      }
      initializeSocket(localStorage.current_user_token);


      state.data.invitationVerifyMsg = "";
      state.data.invitationVerify.newMemberList = [];
      state.data.invitationVerify.registeredMemberList = [];
      state.data.invitationVerify.pendingMemberList = [];

      // const liElements = document.querySelectorAll('li');
      // console.log("Check the ITEMS >>> ", liElements)
      // if (chatScreenContent.value) {
      // document.addEventListener('scroll', handleScroll);
      // }
      if (routerQuery.query.view) {
        signDocument(routerQuery.query.view);
      }
      // channelRecentlyVisited(channelId);
      state.email = localStorage.getItem("current_user_email");
      const userData: any = localStorage.getItem(
        "current_user_info"
      );
      state.activeUserDetails = JSON.parse(userData)
      state.data.imageBaseUrl = config.imageBaseUrl;
      state.ui.isLoading.communicationList = true;
      getCommunicationList(channelId);



      // id="1265"



      if (pageQuery.value != 0) {
        state.data.page = pageQuery.value;
      }

      watch(
        () => state.data.search,
        () => {
          if (state.data.search.length >= 1) {
            state.data.clearBtn = true;
          } else {
            state.data.clearBtn = false;
          }
        }
      );

      if (chatBody.value) {
        chatBody.value.addEventListener("scroll", checkScrollPosition);
      }

      window.addEventListener('message', event => {
        // 
        if (event.data.event === 'taskDone') {

          if (event.data.data.envelopeId != undefined) {
            $("#showDocusignShareDocument").modal("hide");
            sendMessageFromEnvelope(event.data.data.envelopeId)
            state.ui.isLoading.docuSignIframe = false;
            state.ui.isLoading.docuSignIframeOpen = false;
          }
          if (event.data.data.sign_id != undefined) {
            $("#showDocusignShareDocument").modal("hide");
            sendMessageSignFromEnvelope(event.data.data.sign_id)
            state.ui.isLoading.docuSignIframeOpen = false;
            state.ui.isLoading.docuSignIframe = false;
          }

        }
      });
      const userAgent = navigator.userAgent;
      state.isFirefox = /Firefox/.test(userAgent);
      setTimeout(() => {
        if (!(socket && socket.connected)) {
          setSocket();
        }
      }, 1000);

      // Example usage:
      const generateObjects = createIncrementalObjects()

      console.log("CHECK THE MSGS >>>>>> ", generateObjects(10))
      // console.log("CHECK THE Again MSGS >>>>>> ", generateObjects(10))
    });
    onUnmounted(() => {

      const bodyClass = 'no-scroll-body';
      // Check if the <body> element already has the class
      if (document.body.classList.contains(bodyClass)) {
        // Add the class to the <body> element
        document.body.classList.remove(bodyClass);
      }

      console.log("Leaving route");
      // Cleanup logic before navigating away
      routeLeaveStatus.value = true;
      // if(routeLeaveStatus.value) return false;

      // next(); // Allow navigation
      // onUnmounted(() => {
      //   if (chatScreenContent.value) {
      //     chatScreenContent.value.removeEventListener('scroll', handleScrollToDetect);
      //   }

      // events for internet connection
    });
    return {
      state,
      itemsToShow,
      openRenameModal,
      saveUnsentMessageReply,
      removeUnsentMessageReply,
      sendPendingMessage,
      saveUnsentMessage,
      removeUnsentMessage,
      checkInternet,
      getPendingMessage,
      resetMessageStatusIncrementally,
      searchByInChatMessage,
      scrollToChatItem,
      mergeWithCommunicationList,
      unReadMessageStatus,
      updateCommunicationList,
      createIncrementalObjects,
      alignArrayWithOrder,
      handleInputChatMessage,
      removeImageTags,
      cleanString,
      scrollToLiById,
      routeLeaveStatus,
      addMessage,
      setPendingList,
      inviteAllUsersChannel,
      showInviteMemberToChannel,
      handleThreadScroll,
      scrollTimeoutThread,
      getIdsByEmailMismatch,
      filterSenderForReplyThread,
      closeChannelChatMembersModal,
      showChannelMembers,
      pageData,
      getLiIdsBasedOnScroll,
      uniqueChatMessages,
      hasCommonNumber,
      myMessageList,
      checkMessageToFillChatScroll,
      injectChatMessages,
      readOnLoad,
      replaceObjectInCommunication,
      filterMessageReceivers,
      getIdsofNotMyMessage,
      setUsersInMessageRow,
      isLatestMessageButtonActive,
      loadLatestMessages,
      findIndexById,
      replaceObjectByIdInArray,
      latestCommunication,
      // scrollToLastMessageUpdate,
      channelMessageReplySeenByMember,
      messageReplyThreadSeenByMember,
      getVisibleDivIdForReplyThread,
      replaceObjectInArray,
      checkInstantMessageStatus,
      checkIdIfNoMatch,
      replaceObjectIfIdMatches,
      checkEmailAndStatus,
      moveSenderToEnd,
      closeMessageReceiverModal,
      allMessageReceivers,
      onScrollStopped,
      convertStringsToNumbers,
      checkMessageStatus,
      messageDeliveryIds,
      getVisibleLiId,
      checkScrollPosition,
      chatBody,
      chatReplyThreadBody,
      visibleItems,
      chatScreenContent,
      messageSeenByMember,
      getUniqueIds,
      messageReceived,
      replaceItem,
      temporaryReplyMessage,
      temporaryMessge,
      modifyHistoryDateForMember,
      selectedDateForMember,
      selectedValueForPending,
      modifyUserConversationHistoryDate,
      hideHistoryMember,
      pageQuery,
      hideUpdateSubscriptionModal,
      // setRouteQueryPage,
      resetActive,
      handleScroll,
      scrollCheckChat,
      scrollCheck,
      loadData,
      setMentionMembers,
      isKeyPressed,
      relySelectedMessage,
      clearEditor,
      replyChatCommunicationEnterSetting,
      editChatCommunicationEnterSetting,
      enableSendMsg,
      enterSetting,
      enableEnterKey,
      chatMessageEnterSetting,
      onEditorReady,
      dragOverHandler,
      someEventHander,
      onThreadReplyInput,
      onEditChatInput,
      // onFocus,
      hideRenameAttachmentModal,
      // onEditorInput,
      // preventDefaults,
      toggleActive,
      toggleTourGuide,
      latestEnabledTags,
      getReadyImageListToUpload,
      readyToUploadImage,
      checkForDisabledTags,
      masterTagListForValidation,
      enableSelectedFileTag,
      addTag,
      masterTags,
      masterTagList,
      hideDigitizedtemplate,
      hideTemplateupload,
      addChannelMember,
      addMember,
      removeMember,
      renameDoc,
      communicationTyping,
      renameSelectedAttachment,
      resendInvitation,
      showResendInvitationConfirmation,
      hideResendInvitationConfirmation,
      deletePendingInvitation,
      hidePendingMemberRemove,
      selectPendingMemberForDeletes,
      downloadFile,
      deleteCommunication,
      hideDeleteCommunicationModal,
      selctedCommunicationForDelete,
      channelCommunicationTyping,
      // setPreviewImage,
      backInvitation,
      openPdf,
      verifyInvitation,
      channelStatus,
      downloadProjectFiles,
      // connect,
      // chatApp,
      // chatCommunication,
      resizeFormTextArea,
      showPreviewLink,
      readImageInThread,
      replyMessage,
      scrollToLastThread,
      scrollToTop,
      onDocumentUploadThread,
      replyCommunication,
      closeReplyCommunication,
      reloadPage,
      accountFormRetrieve,
      resize,
      resetheight,
      showWorkFlowLink,
      clientDocumentRetrieveWorkflow,
      changeExistingMemberInSendOption,
      getUserListByChannel,
      validateSendDigitizedForm,
      sendDigitizedForm,
      // getUserListByProjectChannel,
      sendOptionBack,
      clientDocumentRetrieve,
      selectDigitizeForm,
      showDigitizedTemplate,
      clearSelectedSignature,
      viewFormRetrieveByIdForSend,
      viewSelectForm,
      hideViewTemplateModal,
      detailsModal,
      editCommunicationData,
      communicationList,
      scrollAtButton,
      redirectToDynamicForm,
      formPrivacyMessage,
      emailValidationCustomEdit,
      updateEditFormElementsValidation,
      sendCreateNewForm,
      radioChange,
      createNewFormEmailValiation,
      addElementsCreateNewValiation,
      showSendFormModal,
      deleteSelectedElementOfCreateForm,
      hideDeleteSelectedElementInCreateNew,
      showNewElementEdit,
      showNewElementDelete,
      hideConfirmEditForm,
      hideNewFormConformation,
      showNewFormConformation,
      showCustomFormConformation,
      selectedForm,
      showDeleteElement,
      cancelDeleteSelectedForm,
      confirmDeleteElement,
      getAccountFormRetrieveByIdForSend,
      editNewFormValiation,
      cancelEditSelectedForm,
      editSelectedForm,
      saveCustomSelectedForm,
      addTextFieldInCustomEdit,
      addOptionsinCustomEdit,
      addSelectOptionsinCustomEdit,
      addCheckBoxinCustomEdit,
      removeCheckBoxinCustomEdit,
      removeSelectOptioninCustomEdit,
      removeOptionsinCustomEdit,
      showEditFormName,
      showEditElement,
      showUserModal,
      showSendTemplate,
      sendTemplateFile,
      getAccountFormRetrieveById,
      selectForm,
      getCommunicationList,
      getChannelFormList,
      createCommunicationList,
      uploadAttachmentByChannel,
      uploadReplyAttachmentByChannel,
      onDocumentChange,
      addNewMemberToChannel,
      downloadFiles,
      closeChannel,
      readImage,
      tempImageList,
      removeSelectedImage,
      hideAddMember,
      removeSelectedMember,
      changeExistingMember,
      changeInviteMember,
      removeSelectedUser,
      inviteAllUsers,
      showSuccessModal,
      validateInviteForm,
      scrollToLast,
      isUserStatus,
      templateList,
      hideEditTemplateModal,
      hideTemplateModal,
      selectEditForm,
      channelCommunicationForm,
      changeExistingMemberInForm,
      showFormView,
      hideFormView,
      showTextFieldAdd,
      addTextField,
      addSelectOptions,
      removeCheckBox,
      removeSelectOption,
      removeOptions,
      addOptions,
      addCheckBox,
      createNewForm,
      showEditTemplateModal,
      showMentionUser,
      titleSectionValiation,
      createNewFormValiation,
      selectedElement,
      showCustomEdit,
      showCreateNewForm,
      showNewTitleInCreateFormEdit,
      hideEditCreateNewForm,
      updateEditNewForm,

      // communicaion edit,
      sendEditedCommunication,
      cancelSelectedEdit,
      selectedCommunicationImageToDelete,
      selctedCommunication,
      viewPdf,
      hideDeleteSelectedImage,
      removeSelectedCommunicationImage,
      getAccountFormResponseByEmailAndId,
      sentToUsers,
      userFormClick,
      selectedUserAccountFormResponse,
      generateEdocStatement,
      submitUserInputForm,
      downloadEdocPdf,
      userFormData,
      canvasSet,
      setCanvas,
      showHideCalender,
      hidecalender,
      userformInputValidationCheck,
      nextPage,
      viewSelectSharedDocuments,
      selectedDocumentToDelete,
      deleteSharedDocument,
      selectedDocumentToEdit,
      renameSelectedSharedAttachment,
      renameAttachment,
      removeMemberFromMemberList,
      deleteChannelMember,
      closeInvitationModal,
      removeActiveMemberCount,
      mideHeight,
      getInstanceMessageList,
      goToChatChannel,
      searchAllMessage,
      searchClearMessage,
      replyCounter,
      shareFileCounter,
      showImageModel,
      showImageModelHide,
      previous,
      next,
      validationPublicUser,
      deletePublicMember,
      changeInvitePublicMember,
      inviteAllPublicUser,
      showReplyImageModel,
      showReplyImageModelHide,
      hideForwardMessage,
      forwardMessage,
      forWardCommunication,
      communicationDetails,
      removeForwardFile,
      toggleZoom,
      visibleRef,
      indexRef,
      imgsRef,
      show,
      onHide,
      clickImage,
      handlePrev,
      handleNext,
      handleHide,
      hideDeleteReplyMessage,
      deleteReplyMessage,
      deleteReplyCommunication,
      selctedReplyCommunication,
      sendEditedReplyCommunication,
      isImgUrl,
      imageSliderNext,
      imageSliderPre,
      closeDocusignListModel,
      showDocusignListModel,
      sendMessageFromEnvelope,
      showDocusignShareDocumentModel,
      closeDocusignShareDocumentModel,
      docusignShareDocumentDetails,
      sendMessageSignFromEnvelope,
      docuSingSignData,
      signDocument,
      truncate,
      showFullText,
      allUsers,
      selectedUsers,
      selectToChangeUsers,
      getReadyThreadImageListToUpload,
      removeSelectedThreadImage,
      removeSelectedReplyCommunicationImageAttached,
      deleteReplyImage,
      hideDeleteReplySelectedImage,
      createPrivateChannel,
      hidePrivateChannel,
      createPrivateChannelGroup,
      createPrivateChannelValidation,
      addMemberInPrivateChannel,
      isRecording,
      transcription,
      startRecording,
      stopRecording,
      exportCommunicationPdf,
      closeSuccessCustomModal,
      exportCommunicationPdfList,
      stopReplyRecording,
      startReplyRecording,
      isReplyRecording,
      addFile,
      isExtensionValid,
      setSocket,
      saveFileName,
      clickChatImage,
      visiblePdf
    };
  },
});
